import { authenticatedAxiosInstance as axios } from "./axios";

const list = (sessionId: string, params = { length: 1000, page: 1 }) =>
  axios.get(`/live-inventory/${sessionId}/storage-location/available`, {
    params,
  });
const used = (
  sessionId: string,
  storeId: string,
  params = { length: 1000, page: 1 }
) =>
  axios.get(
    `/live-inventory/${sessionId}/storage-location/${storeId}/mark-used`,
    {
      params,
    }
  );

export const AvailableLocationsAPI = {
  list,
  used,
};
