import React, { useEffect } from 'react'
import { Html5QrcodeScanner } from "html5-qrcode";

// type useBarcodeScannerType = {
//     divId: string;
//     onScanError: () => void;
//     onScanSuccess: () => void;
// }

const useBarcodeScanner = (divId: string, onScanError: () => void , onScanSuccess: (x: any, y: any) => void) => {

    const unmountCallback = ( html5QrcodeScanner: any ) => {
        html5QrcodeScanner.clear()
    };

    useEffect(() => {
        let html5QrcodeScanner = new Html5QrcodeScanner(
            divId , { fps: 10, qrbox: 1000, aspectRatio: 3 }, false);
        html5QrcodeScanner.render(onScanSuccess, onScanError);

        return(() => {
            html5QrcodeScanner.clear()
            unmountCallback(html5QrcodeScanner)
        })
    })
}

export default useBarcodeScanner