import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { StorageLocationApi } from '../../api/storageLocation';
import { StoreApi } from '../../api/store';
import { AccountInfoAtom, LoadingProgressAtom, StoresAtom } from '../../atom';
import Typography from '../../components/Typography';
import useStorageLocationForm from '../../hooks/useStorageLocationForm';
import { addressActionTypeEnum } from '../../types/StoreAddress.types';
import { storeAddressReducer } from '../../utils/stateFunctions';
import { titles } from '../../utils/titles';
import StorageLocationForm from './StorageLocationForm';
import StoreForm from './StoreForm';

const CreateStore = () => {
  const { id } = useParams();
  const isEdit = !!id;
  const stores = useRecoilValue(StoresAtom);
  const [showableCatList, setShowableCatList] = useState<any[]>([]);
  const [storeName, setStoreName] = useState<string>('');
  const [storeNumber, setStoreNumber] = useState<string>('');
  const [address, setAddress] = useReducer(storeAddressReducer, {
    line1: '',
    line2: '',
    line3: '',
    hasValue: false,
  });
  const setLoadingProgress = useSetRecoilState(LoadingProgressAtom);
  const storageLocations = useStorageLocationForm(id);
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === 'ADMIN';
  const isManager = role === 'MANAGER';
  const isEmp = role === 'EMPLOYEE';

  const fetchStore = async () => {
    setLoadingProgress(10);
    const { data: response } = await StoreApi.show(id as string);
    setLoadingProgress(40);
    if (response.success) {
      const store = response.data;
      setStoreName(store.name);
      setStoreNumber(store.store_no);
      setAddress({ type: addressActionTypeEnum.setAll, payload: store });
    }
    setLoadingProgress(70);
    const { data: locationRes } = await StorageLocationApi.list(id as string);
    setLoadingProgress(90);
    const storageLocation = locationRes.data.results;
    storageLocations.setter({ type: 'ADD_ALL', payload: storageLocation });
    setLoadingProgress(100);
  };

  useEffect(() => {
    document.title = titles.manageStoreCreate;
    if (isEdit) {
      fetchStore();
    }
  }, []);

  if (!isAdmin && !isManager) {
    return (
      <div className="w-full h-full pb-[20vh] text-sm text-pot-textgrey2 flex justify-center items-center font-semibold">
        You are not allowed to view this page
      </div>
    );
  }

  return (
    <>
      <Typography
        text={isEdit ? 'Edit Store' : 'Create Store'}
        bold
        xxl
        m={{ t: 'mt-[4vh]', b: 'mb-[1vh]' }}
      />
      <div
        className={`bg-white rounded-md h-full mb-10 flex gap-x-5 divide-x border`}
      >
        <StoreForm
          storageLocations={storageLocations.value}
          editId={id}
          number={storeNumber}
          setNumber={setStoreNumber}
          name={storeName}
          setName={setStoreName}
          dispatch={setAddress}
          address={address}
        />
        <StorageLocationForm
          locations={storageLocations.value}
          loading={storageLocations.loading}
          setError={storageLocations.setError}
          error={storageLocations.error}
          add={(payload: any) => {
            storageLocations.add({
              type: 'ADD',
              payload: payload,
            });
            if (isEdit) {
              fetchStore();
            }
          }}
          remove={(payload: string) => {
            storageLocations.remove({ type: 'REMOVE', payload: payload });
          }}
          change={(index: number, payload: any) => {
            storageLocations.edit({ type: index, payload });
            if (isEdit) {
              fetchStore();
            }
          }}
          changeLocal={(payload: any) => {
            storageLocations.localEdit({ type: 'LOCALEDIT', payload });
          }}
          showableCatList={showableCatList}
          setShowableCatList={setShowableCatList}
        />
      </div>
    </>
  );
};

export default CreateStore;
