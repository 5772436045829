import React from "react";
import { IconProp } from "./ArrowIcons";

const CategoryIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0.333344H0.99998C0.823169 0.333344 0.653599 0.403581 0.528575 0.528606C0.403551 0.65363 0.333313 0.823199 0.333313 1.00001V13C0.333313 13.1768 0.403551 13.3464 0.528575 13.4714C0.653599 13.5964 0.823169 13.6667 0.99998 13.6667H13C13.1768 13.6667 13.3464 13.5964 13.4714 13.4714C13.5964 13.3464 13.6666 13.1768 13.6666 13V1.00001C13.6666 0.823199 13.5964 0.65363 13.4714 0.528606C13.3464 0.403581 13.1768 0.333344 13 0.333344ZM6.33331 12.3333H1.66665V7.66668H6.33331V12.3333ZM6.33331 6.33334H1.66665V1.66668H6.33331V6.33334ZM12.3333 12.3333H7.66665V7.66668H12.3333V12.3333ZM12.3333 6.33334H7.66665V1.66668H12.3333V6.33334Z"
        fill={color || "currentColor"}
        stroke={color || "transparent"}
        strokeWidth="0.25"
      />
    </svg>
  );
};

export default CategoryIcon;
