import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { SessionApi } from "../../api/session";
import {
  AllSessionsAtom,
  NewSessionCreatedAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
  SessionSpecificAtom,
} from "../../atom";

const useFetchSessions = (forSessionId = false) => {
  const [sessionSpecificData, setSessionSpecificData] = useRecoilState(
    SessionSpecificAtom
  ) as any;
  const [progress, setProgress] = useState([]);
  const [adjustment, setAdj] = useState([]);
  const [complete, setComplete] = useState([]);
  const [completedCall, setCompletedCall] = useState(0);
  const [progressLoading, setProgressLoading] = useState(true);
  const [newSessionCreated, setNewSessionCreated] = useRecoilState(
    NewSessionCreatedAtom
  );
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const setSelectedSession = useSetRecoilState(SelectedSessionAtom);
  const [sessions, setSessions] = useRecoilState(AllSessionsAtom) as any;

  const fetchSessions = async (
    type: "IN_PROGRESS" | "ADJUSTMENT" | "COMPLETE",
    setter: Dispatch<SetStateAction<any>>
  ) => {
    const { data: response } = await SessionApi.list(store.id, type);
    if (response.success) {
      setter(response.data.results);
    }
    if (type === "IN_PROGRESS") {
      setProgressLoading(false);
    }
    if (forSessionId) {
      setCompletedCall((prev) => prev + 1);
    }
  };

  const getSessionFromUrl = () => {
    const urlArray = window.location.pathname.split("/");
    const sessionStringIndex = urlArray.findIndex((each) => each === "session");
    if (sessionStringIndex) {
      const sessionId = urlArray[sessionStringIndex + 1];
      return sessionId;
    }
  };

  useEffect(() => {
    if (!forSessionId) {
      fetchSessions("IN_PROGRESS", setProgress);
      fetchSessions("ADJUSTMENT", setAdj);
      fetchSessions("COMPLETE", setComplete);
    }
  }, []);

  useEffect(() => {
    if (!forSessionId) {
      setSessions([...progress, ...adjustment]);
    }
  }, [progress, adjustment]);

  useEffect(() => {
    if (forSessionId && (!sessions.length || newSessionCreated)) {
      fetchSessions("IN_PROGRESS", setProgress);
      fetchSessions("ADJUSTMENT", setAdj);
    }
  }, [newSessionCreated]);

  const getSessionData = async (settableSession: any) => {
    const { data: response } = await SessionApi.editGet(
      store.id,
      settableSession.id
    );
    if (response.success) {
      const data = response.data;
      setSessionSpecificData(data);
    }
  };

  useEffect(() => {
    if (completedCall === 2 && forSessionId) {
      const showableSessionsList = [...progress, ...adjustment];
      setNewSessionCreated(false);
      setSessions(showableSessionsList);
      setCompletedCall(0);
      const sessionId = getSessionFromUrl();
      if (sessionId) {
        const settableSession = showableSessionsList.find(
          (eachSession: any) => eachSession.id === sessionId
        );
        if (settableSession) {
          setSelectedSession(settableSession);
          getSessionData(settableSession);
        } else {
          console.log("Navigation is necessary");
        }
      }
    }
  }, [completedCall]);

  return {
    progressLoading,
    progress,
    adjustment,
    complete,
  };
};

export default useFetchSessions;
