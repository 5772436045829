export const CheckboxoptionList = [
  "wine",
  "Soda",
  "Champagne",
  "French Wine",
  "Italian Wine",
  "German Wine",
  "Champagne",
  "French Wine",
  "Italian Wine",
  "German Wine",
].map((each, index) => ({
  label: each,
  value: index,
}));

export const symbolToQty = {
  "*": "packs",
  "/": "units",
  "-": "cases",
  "#": "units",
  "+": "cases",
};
