import React, { useRef, useState } from "react";
import useClickOutside from "../hooks/useClickOutside";
import { motion, AnimatePresence } from "framer-motion";
import { DownwardArrowIcon } from "../assets/ArrowIcons";
import { SelectableDropdownValueType } from "./Dropdown";
import RadioButton from "./RadioButton";

type SingleSelectDropdownPropType = {
  label?: string;
  height?: string;
  disableDeselect?: boolean;
  boxText: string;
  emptyText?: string;
  hint?: string | string[];
  rightIcon?: React.ReactNode | null;
  onRightIconClick?: () => void;
  optionList?: SelectableDropdownValueType[];
  selectedValue?: SelectableDropdownValueType;
  onSelect?: (value?: SelectableDropdownValueType) => void;
  disabled?: boolean;
  greyOut?: boolean;
  tags?: string[];
};

const SingleSelectDropdown = ({
  label = "",
  boxText = "",
  emptyText = "There are no options to display",
  rightIcon = null,
  onRightIconClick = () => {},
  hint = "",
  tags,
  height,
  disableDeselect = false,
  optionList = [],
  selectedValue = {} as SelectableDropdownValueType,
  onSelect = () => {},
  disabled = false,
  greyOut = false,
}: SingleSelectDropdownPropType) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const containerRef = useRef(null);
  useClickOutside(containerRef, () => setOpenDropdown(false));

  const disabledStyle: string = "border-2 opacity-60 cursor-not-allowed";
  const dropdownHeight: string = height ? height : "";

  // const [data, setData] = useState<any>({})
  // console.log(optionList, "optionsList");

  return (
    <div
      ref={containerRef}
      className={`w-full relative ${greyOut && "opacity-30 "}`}
    >
      <div className="flex justify-between items-center">
        {label && <p className="font-semibold text-sm pb-2 px-1">{label}</p>}
        {rightIcon && (
          <span className="mr-1 cursor-pointer" onClick={onRightIconClick}>
            {rightIcon}
          </span>
        )}
      </div>
      <button
        onClick={() =>
          !disabled && !greyOut && setOpenDropdown((prev) => !prev)
        }
        className={`${
          openDropdown
            ? "shadow-[inset_0_0_0_1px_#772021]"
            : "shadow-[inset_0_0_0_1px_#DEDEDE]"
        } ${
          disabled && !greyOut && "cursor-not-allowed"
        } ease-in-out transition max-w-full w-full pl-2.5 pr-1 py-1.5 rounded-md items-center flex justify-between text-[13px] text-pot-textgrey2 `}
      >
        <p
          className={`w-full flex items-center md:max-w-[36vw] truncate text-left ${dropdownHeight} ${
            selectedValue.label && "text-pot-black"
          }`}
        >
          {selectedValue.label || boxText || ""}
        </p>
        <motion.div
          className="stroke-pot-black px-1.5"
          animate={openDropdown ? { rotate: -180 } : { rotate: 0 }}
        >
          <DownwardArrowIcon />
        </motion.div>
      </button>
      <AnimatePresence>
        {openDropdown && (
          <motion.div
            initial={{ height: 0 }}
            animate={{
              height: "fit-content",
              maxHeight: "30vh",
              transition: { duration: 0.4 },
            }}
            exit={{ height: 0, transition: { duration: 0.1 } }}
            // transition={{ duraton: 0.6 }}
            className="mt-2 h-fit max-h-[30vh] max-w-full z-30 overflow-y-auto shadow-[0_0_0_1px_#DEDEDE] w-full rounded-md absolute bg-white py-2 gap-1"
          >
            {optionList.length > 0 ? (
              optionList.map((each) => (
                <button
                  key={each.value.value}
                  onClick={() => {
                    // setData(each)
                    if (
                      each?.value?.is_active === true ||
                      each?.value?.is_active === undefined
                    ) {
                      setOpenDropdown(false);
                      if (
                        each.value === selectedValue.value &&
                        !disableDeselect
                      ) {
                        onSelect({} as SelectableDropdownValueType);
                        return;
                      }
                      onSelect(each);
                    }
                  }}
                  className={`w-[98%] m-auto flex justify-between items-center max-w-full px-5 py-2 truncate hover:bg-[#00000010] text-left rounded-md ${
                    each?.value?.is_active === false && disabledStyle
                  }`}
                >
                  {each.label}
                  {each.tag && (
                    <span className="px-2 py-0.5 bg-[#cad8ff] rounded-md">
                      {each.tag}
                    </span>
                  )}
                  {/* <RadioButton
                  label={each.label}
                  selected={each.value === selectedValue.value}
                  onClick={() => {
                    setOpenDropdown(false)
                    if (each.value === selectedValue.value) {
                      onSelect({} as SelectableDropdownValueType);
                      return;
                    }
                    onSelect(each);
                  }}
                /> */}
                </button>
              ))
            ) : (
              <span className="pl-2.5 text-[13px]">{emptyText}</span>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {hint &&
        (typeof hint === "string" ? (
          <p className="px-1 h-0 font-light text-xs text-pot-maroon">{hint}</p>
        ) : hint.length === 1 ? (
          <p className="px-1 h-0 font-light text-xs text-pot-maroon">
            {hint[0]}
          </p>
        ) : (
          <ul className="list-disc px-4">
            {hint.map((each) => (
              <li className="h-0 font-light text-xs text-pot-maroon" key={each}>
                {each}
              </li>
            ))}
          </ul>
        ))}
    </div>
  );
};

export default SingleSelectDropdown;
