import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { InventoryApi } from "../../api/inventory";
import InfoIcon from "../../assets/InfoIcon";
import { SelectedSessionAtom } from "../../atom";
import Checkbox from "../../components/Checkbox";
import EditableField from "../../components/EditableField";
import IconButton from "../../components/IconButton";
import SortButton from "../../components/SortButton";
import InfoModal from "../../pages/InventoryRecord/InfoModal";
import { toastIt } from "../../utils/common";
import { capitalize } from "../../utils/helperFunctions";
import useClickOutside from "../useClickOutside";
import NoteIcon from "../../assets/NoteIcon";
import NoteModal from "../../pages/InventoryRecord/NoteModal";

const useInventoryRecordColdef = ({
  callAgain,
  loading,
  sort,
  setSort,
}: // setShowSpecificInfo,
any) => {
  // {
  //     "QOH": 6,
  //     "QUC": 1,
  //     "cat_name": "misc",
  //     "dept_name": "indoensia misc",
  //     "item_code": 51577,
  //     "item_name": "indoensia misc",
  //     "location": "location 1",
  //     "qty_kind": "CASE,PACK",
  //     "sub_dept_name": "indoensia misc",
  //     "upc": 900381,
  //     "upc_shared": 51654
  //   }

  const [tempRecheckValues, setTempRecheckValues] = useState<any>([]);
  const [tempQtyValues, setTempQtyValues] = useState<any>([]);
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const [isHover, setIsHover] = useState<any>(null);
  const [currentModal, setCurrentModal] = useState<string>("");
  const [type, setType] = useState<0 | 1 | 2>(0);
  const [counter, setCounter] = useState<number>(0);
  const [showSpecificInfo, setShowSpecificInfo] = useState<any>(null);
  const containerRef = useRef(null);
  useClickOutside(containerRef, () => {
    setIsHover(null);
    setShowSpecificInfo(null);
    setType(0);
  });

  const handleMouseOver = (id: any): void => {
    setIsHover(id);
  };

  const findQty = (row: any) => {
    return tempQtyValues.find(
      (val: any) => val.li_record_id === row.original.li_record_id
    );
  };

  const changeQty = async (payload: any) => {
    const itemId = payload.li_record_id;
    const { data: response } = await InventoryApi.update(session.id, itemId, {
      qty: payload.qty,
    });
    if (!response.success) {
      toastIt(response.error);
      const newTemp = tempQtyValues.filter(
        (each: any) => each.li_record_id !== payload.li_record_id
      );
      setTempQtyValues(newTemp);
    }
  };

  const doRecheck = async () => {
    const payload = {
      // rechecked: tempRecheckBool === "F" ? false : (tempRecheckBool === "T" && true ),
      ...tempRecheckValues.slice(-1)[0],
    };
    try {
      const { data: response } = await InventoryApi.recheck(
        session.id,
        payload
      );
      if (response.success) {
        callAgain(true);
      } else {
        toastIt(response.error);
        setTempRecheckValues([]);
      }
    } catch (e) {
      setTempRecheckValues([]);
    }
  };

  useEffect(() => {
    if (tempRecheckValues.length) {
      doRecheck();
    }
  }, [tempRecheckValues]);

  useEffect(() => {
    if (!loading) {
      console.log("Hello");
      setTempRecheckValues([]);
    }
  }, [loading]);

  const coldef = useMemo(
    () => [
      {
        header: () => (
          <SortButton keyVal="upc" text="UPC" value={sort} setValue={setSort} />
        ),
        accessorKey: "upc",
        cell: ({ row, getValue }: any) => (
          <div className="w-full h-full relative">
            {getValue()}
            {row.original.is_not_in_pos && (
              <div className="absolute -top-2 h-8 w-1 rounded-r-md bg-pot-orange -left-3"></div>
            )}
            {row.original.is_flag && (
              <div className="absolute -top-2 h-8 w-1 rounded-r-md bg-pot-maroon -left-3"></div>
            )}
          </div>
        ),
      },
      { header: "Item code", accessorKey: "item_code" },
      {
        header: "Item name",
        accessorKey: "item_name",
        cell: ({ row }: { row: any }) =>
          [
            // capitalize(row.original.cat_name),
            // capitalize(row.original.dept_name),
            capitalize(row.original.item_name),
          ].join(" - "),
        size: 500,
      },
      // {
      //   header: () => (
      //     <SortButton
      //       keyVal="shared_upc"
      //       text="Shared UPC"
      //       value={sort}
      //       setValue={setSort}
      //     />
      //   ),
      //   accessorKey: "upc_shared",
      //   size: 80,
      // },
      {
        header: () => (
          <SortButton
            keyVal="size"
            text="Size"
            value={sort}
            setValue={setSort}
          />
        ),
        accessorKey: "size",
        size: 0,
      },
      {
        header: "Category",
        accessorKey: "cat_name",
        size: 80,
        cell: ({ getValue }: any) => capitalize(getValue()),
      },
      {
        header: "Department",
        accessorKey: "dept_name",
        size: 80,
        cell: ({ getValue }: any) => capitalize(getValue()),
      },
      {
        header: "Sub-Department",
        accessorKey: "sub_dept_name",
        size: 80,
        cell: ({ getValue }: any) => capitalize(getValue()),
      },
      {
        header: "Storage location",
        accessorKey: "location",
        size: 80,
        cell: ({ getValue }: any) => (
          <div className="max-w-[30vw] truncate">{getValue()}</div>
        ),
      },
      // { header: "QOH", accessorKey: "QOH", size: 80 },
      {
        header: () => (
          <SortButton keyVal="QC" text="QC" value={sort} setValue={setSort} />
        ),
        accessorKey: "qc",
        size: 80,
        cell: ({ row, getValue }: any) => {
          const changedVal = findQty(row);

          if (row.original.noData) {
            return <div className="min-h-[2vh]"></div>;
          }

          return (
            <div className="w-[80px]">
              <EditableField
                max={"4"}
                minWidth="w-[42px]"
                text={changedVal ? changedVal.qty : getValue()}
                validate={(val) => {
                  console.log(String(parseInt(val)));
                  return String(parseInt(val) || 0);
                }}
                isOpen={(val) => {
                  console.log(val, counter, "changed");
                  if (counter === 0 && val === false) return;
                  if (val) {
                    setCounter((prev) => prev + 1);
                  } else {
                    setCounter((prev) => prev - 1);
                  }
                }}
                setText={(value) => {
                  const settable = {
                    li_record_id: row.original.li_record_id,
                    qty: value,
                  };
                  changeQty(settable);
                  if (changedVal) {
                    changedVal.qty = value;
                    setTempQtyValues([...tempQtyValues]);
                    return;
                  }
                  setTempQtyValues((prev: any) => [...prev, settable]);
                }}
              />
            </div>
          );
        },
      },
      { header: "Type", accessorKey: "type", size: 80 },
      {
        header: "Recheck",
        accessorKey: "rechecked",
        cell: ({ getValue, row }: any) => {
          if (row.original.noData) {
            return <div className="min-h-[2vh]"></div>;
          }
          return (
            <div className="w-full flex justify-center items-center">
              <Checkbox
                disabled={loading}
                checked={
                  tempRecheckValues.some(
                    (each: any) => each.record_id === row.original.li_record_id
                  )
                    ? tempRecheckValues.some(
                        (each: any) =>
                          each.record_id === row.original.li_record_id &&
                          each.rechecked
                      )
                    : getValue()
                }
                onClick={() => {
                  setTempRecheckValues((prev: any) => [
                    ...prev,
                    {
                      // upc: row.original.upc,
                      // storage_location: row.original.location,
                      record_id: row.original.li_record_id,
                      rechecked: !getValue(),
                    },
                  ]);
                }}
              />
            </div>
          );
        },
        size: 80,
      },
      {
        header: "Info",
        accessorKey: "info",
        size: 80,
        cell: ({ row }: any) => {
          if (row.original.noData) {
            return <div className="min-h-[3vh]"></div>;
          }
          const showNoteIcon = row.original?.note_type || row.original?.note;
          return (
            <div
              ref={containerRef}
              // onMouseLeave={() => {
              //   setIsHover(null);
              //   setShowSpecificInfo(null);
              // }}
              // className="relative"
            >
              <div
                onClick={(e) => {
                  const target = e.target as any;
                  const rect = target.getBoundingClientRect();
                  const threshold = window.innerHeight / 2;
                  console.log(threshold, rect.y, "bottom and");
                  if (rect.y < threshold) {
                    setType(1);
                  } else {
                    setType(2);
                  }
                }}
                className="flex gap-2"
              >
                <IconButton
                  onClick={() => {
                    setCurrentModal("info");
                    handleMouseOver(row.original.li_record_id);
                    setShowSpecificInfo({
                      created_at: row.original.created_at,
                      created_by: row.original.created_by,
                      modified_at: row.original.modified_at,
                      modified_by: row.original.modified_by,
                    });
                  }}
                  type="tertiary"
                >
                  <InfoIcon />
                </IconButton>
                {showNoteIcon && (
                  <IconButton
                    type="tertiary"
                    onClick={() => {
                      setCurrentModal("note");
                      handleMouseOver(row.original.li_record_id);
                      setShowSpecificInfo({
                        note_type: row.original?.note_type,
                        note: row.original?.note,
                      });
                    }}
                  >
                    <NoteIcon size="16" />
                  </IconButton>
                )}
              </div>
              {isHover === row.original.li_record_id &&
              currentModal === "info" ? (
                <InfoModal type={type} data={showSpecificInfo} />
              ) : null}
              {isHover === row.original.li_record_id &&
              currentModal === "note" ? (
                <NoteModal type={type} data={showSpecificInfo} />
              ) : null}
            </div>
          );
        },
      },
    ],
    [tempQtyValues, isHover, tempRecheckValues, loading]
  );
  return coldef;
};

export default useInventoryRecordColdef;
