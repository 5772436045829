import React, { ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { AllSessionsAtom, SelectedSessionAtom } from "../atom";
import useFetchSessions from "../hooks/fetch/useFetchSessions";

type sessionValidateType = {
  children: ReactNode;
};

const SessionValidate = ({ children }: sessionValidateType) => {
  const session = useRecoilValue(SelectedSessionAtom) as any;
  useFetchSessions(true)

  if (!session.id) {
    return (
      <div className="w-full h-[90vh] flex justify-center items-center">
        Loading...
      </div>
    );
  }

  return <>{children}</>;
};

export default SessionValidate;
