import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { StorageLocationApi } from "../../api/storageLocation";
import { SelectedStoreAtom } from "../../atom";

const useFetchStorageLocation = () => {
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const [data, setData] = useState([]);

  const fetchStorageLocations = async () => {
    const { data: response } = await StorageLocationApi.list(store.id);
    if (response.success) {
      const r = response.data.results;
      const rName = r.map((each: any) => each.name);
      const settable = rName.map((each: any, ind: number) => ({
        label: each,
        value: r[ind],
      }));
      console.log(settable);
      setData(settable);
    }
  };

  useEffect(() => {
    fetchStorageLocations();
  }, []);

  return data;
};

export default useFetchStorageLocation;
