import React, { Suspense, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { motion } from "framer-motion";
import "../styles/Bleed.css";
import LocationTopbar from "../components/LocationTopbar";
import { Outlet, useLocation, useNavigate } from "react-router";
import { getFromLocalStorage } from "../utils/localStorage";
import useFetchAccountInfo from "../hooks/fetch/useFetchAccountInfo";
// import CameraModal from "../components/Quagga/CameraModal";
import useQuaggaRefresh from "../hooks/useQuaggaRefresh";
import useFetchAppInfo from "../hooks/fetch/useFetchAppInfo";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  SecuredAuthAtom,
  SelectedStoreAtom,
  StoresAtom,
  UnAuthorizedRedirectAtom,
} from "../atom";
import { urls } from "../utils/urls";
import ReAuthenticateModal from "../components/UncountedItems.tsx/ReAuthenticateModal";
const CameraModal = React.lazy(
  () => import("../components/Quagga/CameraModal")
);

const BaseOutlet = ({ setIsLoggedIn }: any) => {
  const setSelectedStore = useSetRecoilState(SelectedStoreAtom);
  const [openSecuredAuthModal, setOpenSecuredAuthModal] =
    useRecoilState(SecuredAuthAtom);
  const stores = useRecoilValue(StoresAtom);
  const location = useLocation();
  const {
    loading: accountLoading,
    errType,
    setErrType,
    setLoading: setAccountLoading,
  } = useFetchAccountInfo();
  const appLoading = useFetchAppInfo();

  const setQuaggaRefresh = useQuaggaRefresh();
  const [unAuthorizedRedirect, setUnAuthorisedRedirect] = useRecoilState(
    UnAuthorizedRedirectAtom
  );
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromLocalStorage("token");
    if (!token) {
      window.location.href = "/login";
    }
  });

  useEffect(() => {
    if (unAuthorizedRedirect) {
      setUnAuthorisedRedirect(false);
      navigate("/");
    }
  }, [unAuthorizedRedirect]);

  if (accountLoading && errType === "no_store") {
    return (
      <div className="w-full h-screen pb-20 bg-pot-base text-pot-textgrey2 font-semibold grid place-content-center">
        No store is assigned to you
      </div>
    );
  }

  if (accountLoading && errType) {
    return (
      <div className="w-full h-screen pb-20 bg-pot-base text-pot-textgrey2 font-semibold grid place-content-center">
        <div className="flex flex-col items-center justify-center">
          The selected store is not assigned to you. <br /> Select from the
          following store:
          <div className="max-w-[50vw] text-xs gap-x-3 mt-2 flex flex-wrap">
            {stores.map((each: any) => (
              <div
                onClick={() => {
                  setSelectedStore(each);
                  setErrType("");
                  setAccountLoading(false);
                  // navigate("/" +  urls.sessions.replace(":sid", each.id))
                }}
                className="px-2 py-1 bg-white border rounded"
              >
                {each.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (accountLoading || appLoading) {
    return (
      <div className="w-full h-screen pb-20 grid place-content-center">
        Loading
      </div>
    );
  }

  return (
    <>
      <div className="h-screen flex bg-pot-base overflow-y-auto">
        <motion.div
          layout
          initial={{ marginLeft: "-200%" }}
          animate={{ marginLeft: 0 }}
          transition={{ duration: 0.4 }}
          className="z-[45] w-full hidden md:block"
        >
          <Sidebar setIsLoggedIn={setIsLoggedIn} />
        </motion.div>
        <motion.main
          key={location.pathname}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.5 }}
          className="w-full md:min-w-[82vw] 2xl:min-w-[86vw] h-screen flex flex-col overflow-y-auto overflow-x-hidden"
        >
          <LocationTopbar />
          <div className="pl-6 pr-4 md:pr-10 h-full flex flex-col overflow-y-auto overflow-x-hidden">
            <Outlet />
          </div>
        </motion.main>
      </div>
      <Suspense fallback={<div></div>}>
        <CameraModal setRefresh={setQuaggaRefresh} />
      </Suspense>

      {openSecuredAuthModal && (
        <ReAuthenticateModal
          handleCancel={() => setOpenSecuredAuthModal(false)}
        />
      )}
    </>
  );
};

export default BaseOutlet;
