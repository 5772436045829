import React from "react";
import { IconProp } from "./ArrowIcons";

const LoadingIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666687 1.66666V5.66666H4.66669"
        stroke={ color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.34002 9.00001C2.77228 10.2269 3.59158 11.2801 4.67446 12.0009C5.75734 12.7217 7.04514 13.0711 8.34383 12.9963C9.64252 12.9216 10.8817 12.4268 11.8748 11.5865C12.8678 10.7462 13.5609 9.60599 13.8495 8.33758C14.1381 7.06917 14.0067 5.74131 13.4751 4.55407C12.9435 3.36684 12.0404 2.38454 10.9019 1.75518C9.76345 1.12583 8.45129 0.883515 7.16314 1.06475C5.87499 1.24599 4.68063 1.84095 3.76002 2.76001L0.666687 5.66667"
        stroke={ color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoadingIcon;
