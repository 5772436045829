import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { InventoryApi } from "../../api/inventory";
import CrossIcon from "../../assets/CrossIcon";
import LockIcon from "../../assets/LockIcon";
import { SelectedSessionAtom } from "../../atom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Typography from "../../components/Typography";

type CompletePopupType = {
  handleCancel: () => void;
};

const CompletePopup = ({ handleCancel }: CompletePopupType) => {
  const session = useRecoilValue(SelectedSessionAtom) as any
  const [stat, setStat] = useState<any>({})

  const fetchStats = async () => {
    const { data: response} = await InventoryApi.stat(session.id)
    if(response.success) {
      setStat(response.data)
    }
  }

  useEffect(() => {
    fetchStats()
  }, [])
  return (
    <Modal handleCancel={handleCancel}>
      <div className="w-full flex justify-center items-center">
        <div className="w-full max-w-[40vw] bg-white p-4 rounded-md">
          <div className="flex justify-between w-full text-[13px]">
            <Typography
              text="Total no. of Adjusted Items"
              className="text-pot-black"
            />
            <Typography text={`${stat.adjusted_items_count || 0}`} bold />
          </div>
          <div className="flex justify-between w-full text-[13px] py-1.5">
            <Typography text="Total Change OH" className="text-pot-black" />
            <Typography text={stat.change_oh_count} bold />
          </div>
          <div className="flex justify-between w-full text-[13px]">
            <Typography text="Total Cost Change" className="text-pot-black" />
            <Typography text={`$${stat.total_cost_change}`} bold />
          </div>
          <div className="mt-10 h-16 w-full flex text-sm justify-center gap-x-5 items-center">
            <div>
              <Button
                text="Confirm"
                onClick={handleCancel}
                leftIcon={<LockIcon />}
              />
            </div>
            <div>
              <Button
                text="Cancel"
                type="primary_outline"
                leftIcon={<CrossIcon />}
                onClick={handleCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompletePopup;
