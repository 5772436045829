import React, {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { StoreApi } from '../../api/store';
import StoreIcon from '../../assets/StoreIcon';
import { AccountInfoAtom, ChangeInStoreListAtom } from '../../atom';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import InputField from '../../components/InputField';
import Typography from '../../components/Typography';
import useValidateStoreNumber from '../../hooks/useValidateStoreNumber';
import {
  addressActionType,
  addressActionTypeEnum,
  addressType,
} from '../../types/StoreAddress.types';
import { toastIt } from '../../utils/common';
import { urls } from '../../utils/urls';
import { motion } from 'framer-motion';
import SearchIcon from '../../assets/SearchIcon';
import LoadingIcon from '../../assets/LoadingIcon';

type StoreFormType = {
  editId?: string;
  name: string;
  number: string;
  setNumber: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  address: ReducerState<Reducer<addressType, addressActionType>>;
  dispatch: Dispatch<ReducerAction<Reducer<addressType, addressActionType>>>;
  storageLocations: any[];
};

const StoreForm = ({
  name,
  setName,
  address,
  number,
  setNumber,
  dispatch,
  editId = '',
  storageLocations,
}: StoreFormType) => {
  const [errors, setErrors] = useState<any>({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [changeStore, setChangeStore] = useRecoilState(ChangeInStoreListAtom);
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === 'ADMIN';
  const isManager = role === 'MANAGER';
  const isEmp = role === 'EMPLOYEE';
  const navigate = useNavigate();
  const {
    validateNumber,
    numberIsSearching,
    validNumber,
    calledNumberValidate,
  } = useValidateStoreNumber(number);

  const create = async () => {
    setSubmitLoading(true);
    if (!name && !number) {
      setErrors({
        name: 'This field is required',
        store_no: 'This field is required',
      });
      setSubmitLoading(false);
      return;
    }
    const payload = {
      name,
      store_no: number,
      // storage_locations: storageLocations.map((loc) => loc.name),
      storage_locations: storageLocations,
    } as any;
    payload.address_1 = address.line1 || null;
    payload.address_2 = address.line2 || null;
    payload.address_3 = address.line3 || null;
    const { data: response } = await StoreApi.create(payload);
    if (response.success) {
      dispatch({ type: addressActionTypeEnum.clear });
      setName('');
      toast.success('Successfully created store');
      setChangeStore(true);
    } else {
      const errorObj = toastIt(response.error, true);
      setErrors(errorObj);
    }
    setSubmitLoading(false);
  };

  const update = async () => {
    if (!isAdmin) {
      navigate('/' + urls.manageStore);
      return;
    }
    setSubmitLoading(true);
    const payload = {
      name,
      store_no: number,
    } as any;
    payload.address_1 = address.line1 || null;
    payload.address_2 = address.line2 || null;
    payload.address_3 = address.line3 || null;
    const { data: response } = await StoreApi.update(editId, payload);
    if (response.success) {
      setChangeStore(true);
      toast.success('Successfully updated store');
    } else {
      const errorObj = toastIt(response.error, true);
      setErrors(errorObj);
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (changeStore) {
      setChangeStore(false);
      navigate('/' + urls.manageStore);
    }
  }, [changeStore]);

  return (
    <div
      className={`w-full pl-5 py-5 h-full flex flex-col justify-between overflow-y-auto text-sm`}
    >
      <div>
        <InputField
          value={name}
          onChange={(val) => {
            setName(val);
            delete errors.name;
            setErrors({ ...errors });
          }}
          label="Store Name"
          placeholder="Type store name"
          margins="mb-5"
          hint={errors.name}
          doNotChangeInput={!isAdmin}
        />
        <div className="flex items-end justify-between mb-5 gap-x-3">
          <InputField
            doNotChangeInput={!isAdmin}
            value={number}
            onChange={(val) => {
              setNumber(val);
              delete errors.store_no;
              setErrors({ ...errors });
            }}
            label="Store Number"
            rightIcon={
              numberIsSearching && (
                <motion.div
                  animate={{ rotate: -360 }}
                  transition={{
                    repeat: Infinity,
                    delay: 0,
                    duration: 1,
                    ease: 'linear',
                  }}
                  className="stroke-pot-maroon"
                >
                  <LoadingIcon />
                </motion.div>
              )
            }
            placeholder="Type store number"
            hint={
              errors.store_no
                ? errors.store_no
                : !validNumber &&
                  calledNumberValidate &&
                  'The store is not valid'
            }
            setOnBlur={(allow) => {
              if (allow && isAdmin) {
                validateNumber();
              }
            }}
          />
          {/* {!calledNumberValidate && (
            <div className="mb-0.5">
              <IconButton disabled={!number} onClick={validateNumber}>
                {numberIsSearching ? (
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ repeat: Infinity, duration: 1 }}
                  >
                    <SearchIcon />
                  </motion.div>
                ) : (
                  <SearchIcon />
                )}
              </IconButton>
            </div>
          )} */}
        </div>
        <Typography
          text="Address"
          bold
          m={{ t: 'mt-4', b: 'mb-4' }}
          size="text-base"
        />
        <InputField
          doNotChangeInput={!isAdmin}
          value={address.line1}
          onChange={(val) => {
            dispatch({ type: addressActionTypeEnum.line1, payload: val });
            delete errors.address_1;
            setErrors({ ...errors });
          }}
          label="Street line 1"
          placeholder=""
          hint={errors.address_1 ? errors.address_1 : ''}
          margins="mb-5"
        />
        <InputField
          doNotChangeInput={!isAdmin}
          value={address.line2}
          onChange={(val) => {
            dispatch({ type: addressActionTypeEnum.line2, payload: val });
            delete errors.address_2;
            setErrors({ ...errors });
          }}
          placeholder=""
          label="Street line 2"
          margins="mb-5"
          hint={errors.address_2 ? errors.address_2 : ''}
        />
        <InputField
          doNotChangeInput={!isAdmin}
          value={address.line3}
          onChange={(val) => {
            dispatch({ type: addressActionTypeEnum.line3, payload: val });
            delete errors.address_3;
            setErrors({ ...errors });
          }}
          placeholder=""
          label="Street line 3"
          margins="mb-5"
          hint={errors.address_3 ? errors.address_3 : ''}
        />
      </div>
      <div className="flex justify-center pb-10">
        <Button
          loading={submitLoading}
          onClick={() => (editId ? update() : create())}
          text={editId ? 'Update Store' : 'Create Store'}
          leftIcon={<StoreIcon />}
          disabled={!name || !number || storageLocations.length < 1}
        />
      </div>
    </div>
  );
};

export default StoreForm;
