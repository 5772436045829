import React, {
  Dispatch,
  Reducer,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from "react";
import PlusIcon from "../../assets/PlusIcon";
import TrashIcon from "../../assets/TrashIcon";
import Button from "../../components/Button";
import CheckboxDropdown from "../../components/CheckboxDropdown";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import Typography from "../../components/Typography";
import { SelectableDropdownValueType } from "../../components/Dropdown";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import { CategoryListAtom } from "../../atom";
import { selCatActionTypeEnum } from "../../types/Category.type";
import { categoryReducer } from "../../utils/stateFunctions";
import {
  eachStringToDropDownObj,
  removeDuplicates,
} from "../../utils/helperFunctions";
import { joiner } from "../../utils/common";
import useCategoryOrder from "../../hooks/useCategoryOrder";
import SessionUserAccess from "./SessionUserAccess";

type RightFormProp = {
  userStorageLoc: any[];
  userAccess: any[];
  setUserStorageLoc: Dispatch<SetStateAction<any>>;
  showableCatList: any[];
  setShowableCatList: Dispatch<SetStateAction<any>>;
  setUserAccess: Dispatch<SetStateAction<any>>;
  disabledOverall: boolean;
  categoryDepartmentData?: any[];
  disable?: boolean;
};

const RightForm = ({
  userStorageLoc,
  userAccess,
  setUserStorageLoc,
  showableCatList,
  setShowableCatList,
  setUserAccess,
  disabledOverall,
  categoryDepartmentData,
  disable = false,
}: RightFormProp) => {
  const [updatedCatData, setUpdatedCatData] = useState<any>([]);

  const formatCategoryData = () => {
    const combinedCatData = categoryDepartmentData?.flatMap((category: any) => {
      return category.dept_name.flatMap((deptName: any) => {
        return deptName?.sub.map((subDept: any) => {
          return [
            `${category.cat_name}${joiner}${deptName?.name}${joiner}${subDept?.sub_name}`,
          ];
        });
      });
    });
    setUpdatedCatData(combinedCatData);
  };

  useEffect(() => {
    formatCategoryData();
  }, [categoryDepartmentData]);

  return (
    <motion.div
      initial={{ marginRight: "-100%" }}
      animate={{ marginRight: 0 }}
      transition={{ duration: 0.6 }}
      className="w-full relative stroke-white flex flex-col h-full max-h-max overflow-y-auto gap-y-[2vh] divide-y-2 no-scrollbar"
    >
      <div className="min-h-fit px-[2vh] py-[2vh]">
        <Typography
          text="Categories"
          className="pb-2 text-sm xl:text-[16px]"
          semibold
        />
        <div className="flex gap-x-4 text-sm mb-[2vh] items-end">
          <SingleSelectDropdown
            onSelect={(value) => {}}
            label="Category"
            boxText="Select a category"
            disabled={disabledOverall}
            // optionList={categoryOptions?.[0]}
            greyOut={disabledOverall}
          />
          <SingleSelectDropdown
            onSelect={(value) => {}}
            label="Department"
            boxText="Select a department"
            // optionList={categoryOptions?.[1]?.[state.cat?.value]}
            greyOut={disabledOverall}
          />
          <CheckboxDropdown
            onSelect={(value) => {}}
            label="Sub-department"
            boxText="Select a department"
            greyOut={disabledOverall}
            selectedBoxTextType="number_only"
          />
          <div className="h-fit">
            {/* (state && ((state.disabled) > 0) && (state.subDepts?.length === 0)) */}
            <Button
              onClick={() => {}}
              disabled
              leftIcon={<PlusIcon />}
              small
              text="Add"
            />
          </div>
        </div>
        {categoryDepartmentData?.map((category: any, index: number) => {
          return (
            <div
              className={`${
                disable && "opacity-40 pointer-events-none"
              } w-full my-2 flex text-sm px-4`}
              key={category.cat_name}
            >
              <div className="w-[50%] font-semibold">{category.cat_name}</div>
              <div className="w-full flex flex-col gap-y-3">
                {category.dept_name.map((department: any, idx: number) => {
                  return (
                    <div
                      className="w-full flex justify-between"
                      key={department}
                    >
                      <p className="w-[80%]">{department?.name}</p>
                      <div className="w-full flex flex-col gap-y-1">
                        {department?.sub.map((subDep: any) => {
                          return (
                            <div
                              className="w-full flex justify-between"
                              key={subDep?.sub_name}
                            >
                              {subDep?.sub_name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <SessionUserAccess
        userAccess={userAccess}
        setUserAccess={setUserAccess}
        userStorageLoc={userStorageLoc}
        setUserStorageLoc={setUserStorageLoc}
        showableCatList={updatedCatData}
      />
    </motion.div>
  );
};

export default RightForm;
