import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import CategoryIcon from "../../assets/CategoryIcon";
import LocationIcon from "../../assets/LocationIcon";
import SearchIcon from "../../assets/SearchIcon";
import Button from "../Button";
import Dropdown from "../Dropdown";
import InputField from "../InputField";
import Typography from "../Typography";
import DownloadIcon from "../../assets/DownloadIcon";
import {
  AccountInfoAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
  SessionSpecificAtom,
  TableLoadingAtom,
} from "../../atom";
import { useRecoilValue } from "recoil";
import Modal from "../Modal";
import ReAuthenticateModal from "../UncountedItems.tsx/ReAuthenticateModal";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { SessionApi } from "../../api/session";

type TableHeaderPropType = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  heading: string;
  isFinalAdjustment?: boolean;
  buttonClick?: () => void;
  slOptions?: any;
  categoriesOptions?: any;
  withDebounce?: boolean;
  setSelectedSL?: Dispatch<SetStateAction<string>>;
  setSelectedCategory?: Dispatch<SetStateAction<string>>;
  selectedCategory?: string;
  selectedSL?: string;
  hideLocationFilter?: boolean;
  setDownload?: Dispatch<SetStateAction<any>>;
  callAgain?: Dispatch<SetStateAction<any>>;
  isRecordList?: boolean;
  showToggleSwitch?: boolean;
  data?: any;
};

const HeaderAndControl = ({
  search,
  setSearch,
  heading,
  isFinalAdjustment = false,
  buttonClick = () => {},
  slOptions = [],
  categoriesOptions = [],
  withDebounce = false,
  setSelectedSL = () => {},
  setSelectedCategory = () => {},
  selectedCategory = "",
  selectedSL = "",
  hideLocationFilter = false,
  setDownload = () => {},
  callAgain = () => {},
  isRecordList = false,
  showToggleSwitch = false,
}: TableHeaderPropType) => {
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const store: any = useRecoilValue(SelectedStoreAtom);
  const session: any = useRecoilValue(SelectedSessionAtom);
  const fetchLoading = useRecoilValue(TableLoadingAtom);
  const sessionSpecificData = useRecoilValue(SessionSpecificAtom) as any;
  const sessionLockedValue = sessionSpecificData?.lock_uncounted_list
    ? sessionSpecificData?.lock_uncounted_list
    : false;
  const [isChecked, setIsChecked] = useState(!sessionLockedValue);

  const handleToggle = async () => {
    const { data: response } = await SessionApi.uncountedGet(
      store?.id,
      session?.id,
      { state: isChecked }
    );
    if (response.success) {
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    if (sessionLockedValue) {
      setIsChecked(!sessionLockedValue);
    }
  }, [sessionLockedValue]);

  return (
    <section className="text-sm">
      <div className="w-full flex justify-between items-end mb-[2vh]">
        <Typography text={heading} bold xxl m={{ t: "mt-[4vh]" }} />
        {isFinalAdjustment && (
          <div className="">
            {" "}
            <Button onClick={buttonClick} text="Complete Final Adjustment" />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-3 md:gap-y-0 md:flex-row justify-between">
        <div className="w-2/3 md:w-1/3">
          <InputField
            placeholder="Search"
            leftIcon={<SearchIcon color="#4F4F4F" />}
            value={search}
            onChange={(text) => setSearch(text)}
            withDebounce={withDebounce}
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap gap-y-2 z-[38] gap-x-3 text-xs">
          {showToggleSwitch && role === "ADMIN" && (
            <ToggleSwitch
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              handleToggle={handleToggle}
              disabled={fetchLoading}
              leftText="Disabled"
              rightText="Enabled"
              secured
            />
          )}
          {isRecordList && (
            <Button
              type="secondary"
              text="CSV"
              leftIcon={<DownloadIcon />}
              margins="mb-[2vh]"
              onClick={() => {
                callAgain(true);
                setDownload("1");
              }}
              // to={urls.sessionsCreate.replace(`:sid`, store.id)}
            />
          )}
          <Dropdown
            optionList={categoriesOptions}
            buttonText={selectedCategory || "Select a category"}
            buttonProps={{ leftIcon: <CategoryIcon /> }}
            beautify
            selectOption={(value) =>
              selectedCategory === value.label
                ? setSelectedCategory("")
                : setSelectedCategory(value.label)
            }
          />
          {!hideLocationFilter && (
            <Dropdown
              optionList={slOptions}
              buttonText={selectedSL || "Select a storage location"}
              buttonProps={{ leftIcon: <LocationIcon /> }}
              beautify
              selectOption={(value) =>
                selectedSL === value.label
                  ? setSelectedSL("")
                  : setSelectedSL(value.label)
              }
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default HeaderAndControl;
