import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { StorageLocationApi } from "../../api/storageLocation";
import { SelectedStoreAtom } from "../../atom";

const useFetchStoreUsers = () => {
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const [data, setData] = useState([]);

  const fetchStoreUsers = async () => {
    const { data: response } = await StorageLocationApi.users(store.id);
    if (response.success) {
      const r = response.data.users_list;
      const settable = r.map((each: any, ind: number) => ({
        label: each?.first_name + " " + each?.last_name,
        value: r[ind],
      }));
      console.log(settable);
      setData(settable);
    }
  };

  useEffect(() => {
    fetchStoreUsers();
  }, []);

  return data;
};

export default useFetchStoreUsers;
