import React, { Dispatch, SetStateAction } from 'react'
import SortIcon from '../assets/SortIcon'

type SortButtonType = {
    keyVal: string;
    value: string;
    text: string;
    setValue: Dispatch<SetStateAction<string>>
}

const SortButton = ({ keyVal, value, text, setValue }: SortButtonType) => {
  

  return (
    <button className="flex gap-x-2 h-full" onClick={() => {
        return
        if(value === keyVal){
            setValue(`-${keyVal}`)
        } else if (!value || value === "") {
            setValue(keyVal)
        } else {
            setValue("")
        }
    }}>
        {text}
        {/* <SortIcon topSelect={value === `-${keyVal}`} bottomSelect={value === keyVal} /> */}
    </button>
  )
}

export default SortButton