import React, { useState } from "react";
import {
  primaryDisabledStyle,
  primaryOutlineDisabledStyle,
  primaryOutlineStyle,
  primaryStyle,
  secondaryStyle,
  tertiaryDisabledStyle,
  tertiaryStyle,
} from "./Button";
import ToolTip from "./ToolTip";

type IconButtonPropsType = {
  element?: React.ReactNode | null;
  children: React.ReactNode;
  type?: "primary" | "primary_outline" | "secondary" | "tertiary";
  disabled?: boolean;
  onClick?: () => void | Promise<void>;
  tooltip?: string;
  center?: boolean;
};

const IconButton = ({
  element = null,
  children,
  type = "primary",
  disabled,
  onClick = () => {},
  tooltip = "",
  center = true,
}: IconButtonPropsType) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const typeStyle =
    type === "primary"
      ? primaryStyle
      : type === "primary_outline"
      ? primaryOutlineStyle
      : type === "secondary"
      ? secondaryStyle
      : type === "tertiary" && tertiaryStyle;
  const disabledStyle =
    type === "primary" || type === "secondary"
      ? primaryDisabledStyle
      : type === "primary_outline"
      ? primaryOutlineDisabledStyle
      : type === "tertiary" && tertiaryDisabledStyle;
  return (
    <div
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
      className={`relative flex ${center ? "justify-center" : "justify-end"}`}
    >
      <button
        onClick={onClick}
        className={`flex px-2 py-2 justify-center items-center rounded-md ${
          disabled ? disabledStyle : typeStyle
        } `}
      >
        {element || children}
      </button>
      {mouseEnter && tooltip && (
        // <div>
        <ToolTip message={tooltip} bottom right variant="secondary" />
        // </div>
      )}
    </div>
  );
};

export default IconButton;
