import React from "react";
import { IconProp } from "./ArrowIcons";

export const OpenEyesIcon = ({ color, width = "20" }: IconProp) => {
  return (
    // <svg
    //   width="16"
    //   height="14"
    //   viewBox="0 0 16 14"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M7.99999 1.66666C3.33332 1.66666 0.666656 6.99999 0.666656 6.99999C0.666656 6.99999 3.33332 12.3333 7.99999 12.3333C12.6667 12.3333 15.3333 6.99999 15.3333 6.99999C15.3333 6.99999 12.6667 1.66666 7.99999 1.66666Z"
    //     stroke={color || "inherit"}
    //     strokeWidth="1.75"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
    <svg
      width={width}
      height={width}
      fill="none"
      stroke={color || "inherit"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
      <path d="M21 12c-1.889 2.991-5.282 6-9 6s-7.111-3.009-9-6c2.299-2.842 4.992-6 9-6s6.701 3.158 9 6Z"></path>
    </svg>
  );
};

export const ClosedEyesIcon = ({ color, width = "20" }: IconProp) => {
  return (
    <svg
      width={width}
      height={width}
      fill="none"
      stroke={color || "inherit"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path d="m3 3 18 18"></path>
      <path d="M10.5 10.68a2 2 0 0 0 2.823 2.823"></path>
      <path d="M7.362 7.563C5.68 8.74 4.279 10.42 3 12c1.889 2.991 5.282 6 9 6 1.55 0 3.043-.522 4.395-1.349"></path>
      <path d="M12 6c4.008 0 6.701 3.158 9 6a15.66 15.66 0 0 1-1.078 1.5"></path>
    </svg>
    // <svg
    //   width="16"
    //   height="16"
    //   viewBox="0 0 16 16"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M9.41332 9.41332C9.23022 9.60982 9.00942 9.76743 8.76409 9.87674C8.51876 9.98605 8.25392 10.0448 7.98538 10.0496C7.71684 10.0543 7.4501 10.0049 7.20106 9.90432C6.95203 9.80373 6.7258 9.65401 6.53589 9.46409C6.34597 9.27418 6.19625 9.04795 6.09566 8.79892C5.99507 8.54988 5.94567 8.28314 5.95041 8.0146C5.95515 7.74606 6.01393 7.48122 6.12324 7.23589C6.23255 6.99056 6.39016 6.76976 6.58666 6.58666M0.666656 0.666656L15.3333 15.3333M6.66666 2.66657C11 2.33439 14 5.324 15.3333 7.98144C14.9677 8.76022 14.6721 9.19747 14 9.97452M11.96 11.96C10.8204 12.8287 9.43273 13.3099 7.99999 13.3333C5.27406 13.3333 3.23054 11.5136 2.00231 9.99999C1.12786 8.92239 0.666656 7.99999 0.666656 7.99999C1.04689 7.2914 1.49458 6.62384 2.00231 6.00606C2.6019 5.2765 3.28521 4.61635 4.03999 4.03999L11.96 11.96Z"
    //     stroke={color || "inherit"}
    //     strokeWidth="1.75"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  );
};
