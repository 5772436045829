import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue, useRecoilState } from "recoil";
import { SessionApi } from "../../api/session";
import {
  CategoryListAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
  SessionSpecificAtom,
} from "../../atom";
import {
  eachStringToDropDownObj,
  toCatList,
  toDeptListObj,
} from "../../utils/helperFunctions";

const useFetchSession = () => {
  const [categoryData, setCategoryData] = useRecoilState(
    CategoryListAtom
  ) as any;
  const [sessionData, setSessionData] = useState<any>([]);
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const [selectedSession, setSelectedSession] = useRecoilState(
    SelectedSessionAtom
  ) as any;
  const getSessionIdFromUrl = async () => {
    const urlArray = window.location.pathname.split("/");
    const sIndex = urlArray.findIndex((each) => each === "session");
    if (sIndex !== -1 && sIndex < urlArray.length - 1) {
      const sessionId = urlArray[sIndex + 1];
      const { data: session } = await SessionApi.list(store.id, "IN_PROGRESS");
      const currentSession = session.data.results.filter(
        (sess: any) => sess.id === sessionId
      );
      setSelectedSession(currentSession[0]);
    }
    return null;
  };

  const fetchSession = async () => {
    const { data: response } = await SessionApi.editGet(
      store.id,
      selectedSession.id
    );
    if (response.success) {
      const data = response.data;
      setSessionData(data);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedSession).length === 0) {
      getSessionIdFromUrl();
    }
    if (selectedSession.id) {
      fetchSession();
    }
  }, [selectedSession, setSelectedSession]);

  return sessionData;
};

export default useFetchSession;
