import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import { SelectedSessionAtom } from '../atom'

const useRedirectWithoutSession = () => {
    const session = useRecoilValue(SelectedSessionAtom) as any
    const navigate = useNavigate()

    useEffect(() => {
        if(session.id) return
        navigate("/")
    }, [])
}

export default useRedirectWithoutSession