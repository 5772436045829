import React, { useState, useEffect } from "react";
import CrossIcon from "../../assets/CrossIcon";
import IconButton from "../../components/IconButton";
import Modal from "../../components/Modal";
import Typography from "../../components/Typography";

type ItemSelectProp = {
  handleCancel: () => void;
  data: any[];
  select: (item: any) => void;
  clear: () => void;
  workflow?: string;
};

const ItemsSelect = ({
  handleCancel,
  data,
  select,
  clear,
  workflow,
}: ItemSelectProp) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleKeyPress = (e: any) => {
    if (e.key === "ArrowLeft" && selectedItem > 0) {
      setSelectedItem(selectedItem - 1);
    } else if (e.key === "ArrowRight" && selectedItem < data.length - 1) {
      setSelectedItem(selectedItem + 1);
    }
    if (e.key === "Enter") {
      select(data[selectedItem]);
      handleCancel();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedItem]);

  return (
    <Modal
      handleCancel={() => {
        clear();
        handleCancel();
      }}
      long
    >
      <div className="w-full flex justify-end mb-5">
        <IconButton
          type="tertiary"
          onClick={() => {
            clear();
            handleCancel();
          }}
        >
          <CrossIcon />
        </IconButton>
      </div>
      {/* <div className="grid gap-4 place-items-center grid-cols-[repeat(auto-fill,19rem)] mb-5 mx-5"> */}
      <div className="flex gap-4 justify-center flex-wrap mb-5 mx-5">
        {data.map((each, index) => (
          <button
            key={index}
            onClick={() => {
              select(each);
              handleCancel();
            }}
            className={`max-w-full w-[19rem] px-4 py-2 bg-white border text-left ${
              selectedItem === index && "border-pot-black"
            } hover:border-pot-black active:border-pot-darkmaroon rounded-md`}
          >
            <Typography
              text={each.item_name}
              semibold
              size="text-sm"
              m={{ b: "mb-2" }}
            />
            <Typography text={`Item Code : ${each.item_code}`} size="text-xs" />
            <Typography text={`UPC : ${each.upc}`} size="text-xs" />
            {workflow === "REGISTER_51" && each?.qty_oh && (
              <Typography text={`QOH : ${each?.qty_oh}`} size="text-xs" />
            )}
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default ItemsSelect;
