import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import CrossIcon from "../../assets/CrossIcon";
import IconButton from "../../components/IconButton";
import Modal from "../../components/Modal";
import Typography from "../../components/Typography";
import { showConvertedDate } from "../../utils/helperFunctions";

type InfoModalType = {
  data: any;
  type: 0 | 1 | 2;
};

const InfoModal = ({ data, type }: InfoModalType) => {
  const containerRef = useRef<any>(null);
  // const shouldBeOnTop =

  // useEffect(() => {
  //     if(type) return
  //     const id = setInterval(() => {
  //         const { bottom, top } = containerRef.current?.getBoundingClientRect()
  //         if(bottom > top) {
  //             setType(1)
  //         } else {
  //             setType(2)
  //         }
  //     }, 200)
  //     return(() => clearInterval(id))
  // }, [])

  return (
    <div
      ref={containerRef}
      className={`absolute z-[100] right-2 ${
        type ? (type === 2 ? "bottom-12" : "") : "hidden"
      } border-2 pt-2 border-pot-grey2 bg-white rounded-lg `}
    >
      <div className="w-full px-3 text-sm">
        <div className="w-full flex justify-between space-x-4 pb-2 items-start ">
          <Typography text="Created At" className="min-w-fit text-start w-[80px]" />
          <Typography
            text={`: ${showConvertedDate(data.created_at) || "-"}`}
            bold
            fullWidth
            className="w-[150px] truncate"
          />
        </div>
        <div className="w-full flex justify-between space-x-4 pb-2 items-start">
          <Typography text="Created By" className="min-w-fit text-start w-[80px]" />
          <Typography
            text={`: ${data.created_by || "-"}`}
            bold
            fullWidth
            className="w-[150px] truncate"
          />
        </div>
        <div className="w-full flex justify-between space-x-4 pb-2 items-start">
          <Typography text="Modified At" className="min-w-fit text-start w-[80px]" />
          <Typography
            text={`: ${showConvertedDate(data.modified_at) || "-"}`}
            bold
            fullWidth
            className="w-[150px] truncate"
          />
        </div>
        <div className="w-full flex pb-2 justify-between space-x-4 items-start">
          <Typography text="Modified By" className="min-w-fit text-start w-[80px]" />
          <Typography
            text={`: ${data.modified_by || "-"}`}
            bold
            fullWidth
            className="w-[150px] truncate"
          />
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
