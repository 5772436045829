import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { RightArrowIcon } from "../../assets/ArrowIcons";
import { ChangeScanPageAtom } from "../../atom";
import Button from "../../components/Button";
import Line from "../../components/Line";
import Typography from "../../components/Typography";
import useRedirectWithoutSession from "../../hooks/useRedirectWithoutSession";
import { titles } from "../../utils/titles";
import HistoryLogPage from "./HistoryLogPage";
import ScanPage from "./ScanPage";
import SectionSelectorBar from "./SectionSelectorBar";

const InventoryScan = () => {
  const [selectedPage, setSelectedPage] = useState<0 | 1>(0);
  const [callHistory, setCallHistory] = useState(true);
  const [editData, setEditData] = useState<any>({});
  const changeToCreate = useRecoilValue(ChangeScanPageAtom);
  useRedirectWithoutSession();

  useEffect(() => {
    if (selectedPage === 1) {
      console.log("Hello");
      setEditData({});
    }
  }, [selectedPage]);

  useEffect(() => {
    document.title = titles.scan;
  }, []);

  useEffect(() => {
    setSelectedPage(0);
    setEditData({});
  }, [changeToCreate]);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="relative h-full w-full flex flex-col">
        <SectionSelectorBar
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          names={["Live Inventory", "History"]}
        />
        <div className="max-h-full">
          <div className="h-full w-full flex md:divide-x">
            <ScanPage
              selected={selectedPage === 0}
              setCallHistory={setCallHistory}
              editData={editData}
              setEditData={setEditData}
            />
            <HistoryLogPage
              editData={editData}
              setSelectedPage={setSelectedPage}
              selected={selectedPage === 1}
              callHistory={callHistory}
              setCallHistory={setCallHistory}
              setEditData={setEditData}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <Line />
      </div>
    </div>
  );
};

export default InventoryScan;
