import React from 'react'
import { motion } from 'framer-motion'

const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className="w-full h-[3px] rounded-full bg-pot-grey  relative z-[46]">
        {progress ? (
          <motion.div
            className="h-[3px] bg-pot-darkmaroon rounded-full"
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
          ></motion.div>
        ) : (
          <></>
        )}
      </div>
  )
}

export default ProgressBar