import React, { useEffect } from "react";
import DownloadIcon from "../../assets/DownloadIcon";
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import { titles } from "../../utils/titles";

const Report = () => {

  useEffect(() => {
    document.title = titles.reports
  }, [])

  return (
    <div>
      <Typography text="Report" bold xxl m={{ t: "mt-[4vh]", b: "mb-[2vh]" }} />
      <div className="w-full max-w-[40vw] bg-white border p-4 rounded-md">
        <div className="flex justify-between w-full text-[13px]">
          <Typography
            text="Total no. of Adjusted Items"
            className="text-pot-black"
          />
          <Typography text="480" bold />
        </div>
        <div className="flex justify-between w-full text-[13px] py-1.5">
          <Typography text="Total Change OH" className="text-pot-black" />
          <Typography text="55" bold />
        </div>
        <div className="flex justify-between w-full text-[13px]">
          <Typography text="Total Cost Change" className="text-pot-black" />
          <Typography text="$480" bold />
        </div>
        <div className="mt-10 h-16 w-full flex text-sm justify-center items-center">
          <div>
            <Button text="Download Report" leftIcon={<DownloadIcon />} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
