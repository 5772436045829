import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { InventoryApi } from "../../api/inventory";
import { SelectedSessionAtom } from "../../atom";
import Log from "../../components/HistoryLog";
import Typography from "../../components/Typography";

type HistoryLogPropType = {
  editData: any;
  selected: boolean;
  callHistory: boolean;
  setCallHistory: Dispatch<SetStateAction<boolean>>;
  setEditData: Dispatch<SetStateAction<any>>;
  setSelectedPage: Dispatch<SetStateAction<0 | 1>>;
};

const HistoryLogPage = ({
  editData = {},
  selected,
  callHistory,
  setCallHistory,
  setEditData,
  setSelectedPage,
}: HistoryLogPropType) => {
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const [historyData, setHistoryData] = useState([]);

  const fetchHistory = async () => {
    const { data: response } = await InventoryApi.history(session.id);
    setHistoryData(response.data.results);
    setCallHistory(false);
  };

  useEffect(() => {
    if (callHistory) {
      fetchHistory();
    }
  }, [callHistory]);

  return (
    <div
      className={`w-full md:w-[85%] h-full py-3 md:py-0 ${
        selected ? "flex flex-col" : "hidden md:flex md:flex-col"
      } text-sm`}
    >
      <Typography
        text="History Log"
        bold
        xxl
        className="hidden md:block"
        m={{ t: "mt-[4vh]", b: "mb-[1vh]", l: "ml-[1vw]" }}
      />
      <div className="w-full h-[85vh] md:h-[76vh] overflow-y-auto px-[1vw] flex flex-col gap-y-4 pb-[2.5vh]">
        {historyData.map((data) => (
          <Log
            data={data}
            onEdit={(value: any) => {
              console.log(
                editData.upc === value.upc,
                editData.item_code === value.item_code,
                editData.li_record_id === value.li_record_id,
                editData,
                value
              );
              if (
                editData.upc === value.upc &&
                editData.item_code === value.item_code &&
                editData.li_record_id === value.li_record_id
              ) {
                setEditData({});
                setSelectedPage(0);
                return;
              }
              setEditData(value);
              setSelectedPage(0);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default HistoryLogPage;
