import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { titles } from "../../utils/titles";
import TableLayout from "../../components/TableComponents/TableLayout";
import useRedirectWithoutSession from "../../hooks/useRedirectWithoutSession";
import { SelectedSessionAtom } from "../../atom";
import useLivesalesRecordTableFetch from "../../hooks/fetch/useLivesalesRecordTableFetch";
import useLivesalesRecordColdef from "../../hooks/coldef/useLivesalesRecordColdef";
import UploadLivesales from "./UploadLivesales";
import HeaderAndControl2 from "../../components/TableComponents/TableHeaderAndControl2";
import { LivesalesApi } from "../../api/livesales";
import { toast } from "react-toastify";
import { toastIt } from "../../utils/common";
import { InventoryApi } from "../../api/inventory";
import {
  eachStringToDropDownObj,
  removeDuplicates,
  toCatList,
} from "../../utils/helperFunctions";

const Livesales = () => {
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("");
  const [catagoriesOptions, setCategoriesOption] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [addToFinalAdjValue, setAddToFinalAdjValue] = useState<any>([]);
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const [display, setDisplay] = useState<boolean>(true);
  const { data, callAgain, loading } = useLivesalesRecordTableFetch({
    sort,
    // category: selectedCategory,
  });
  const [lsRecords, setLsRecords] = useState<any>(data);
  useEffect(() => {
    setLsRecords(data);
  }, [data]);

  const fetchCategories = async () => {
    const { data: response } = await InventoryApi.categories(session.id);
    const data = toCatList(response.data);
    setCategoriesOption(eachStringToDropDownObj(data));
  };

  const [error, setError] = useState<any>({});

  const fetchStatus = async () => {
    const { data: resp } = await LivesalesApi.status(session.id);
    setDisplay(resp.data.is_import_complete);
  };

  const fetchSalesData = async () => {
    setLsRecords([]);
    setDisplay(false);
  };

  const submitSalesData = async () => {
    const { data: response } = await LivesalesApi.update(session.id, {
      records: addToFinalAdjValue,
    });
    console.log(data);
    if (!response.success) {
      const errorObj = toastIt(response.error, true);
      setError({ ...errorObj });
      return;
    }
    toast.success("Successfully updated the live sales adjustment values");
  };

  const coldef = useLivesalesRecordColdef({
    callAgain,
    loading,
    sort,
    setSort,
    addToFinalAdjValue,
    setAddToFinalAdjValue,
  });
  useRedirectWithoutSession();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = titles.livesalesRecords;
    if (!session.id) {
      navigate("/");
      return;
    }
    fetchStatus();
    fetchCategories();
  }, []);
  return (
    <div className="w-full h-full flex flex-col justify-start">
      <HeaderAndControl2
        withDebounce={true}
        heading="Live Sales Records"
        search={searchValue}
        setSearch={setSearchValue}
        buttonClick={fetchSalesData}
        buttonClick2={submitSalesData}
        categoriesOptions={catagoriesOptions}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        dataExists={!display}
        data={data}
        setData={setLsRecords}
      />
      {display ? (
        <TableLayout
          data={lsRecords}
          coldef={coldef}
          withoutTableControl={true}
        />
      ) : (
        <UploadLivesales />
      )}
    </div>
  );
};

export default Livesales;
