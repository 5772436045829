import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { accountInfo } from "../../api/core";
// import { StoreApi } from "../../api/store";
import {
  AccountInfoAtom,
  ChangeInStoreListAtom,
  SelectedStoreAtom,
  StoresAtom,
} from "../../atom";

const useFetchAccountInfo = () => {
  const [loading, setLoading] = useState(true);
  const [errType, setErrType] = useState<string>();
  const [changeInStore, setChangeInStore] = useRecoilState(
    ChangeInStoreListAtom
  );
  const [curUser, setCurUser] = useRecoilState(AccountInfoAtom);
  const setStores = useSetRecoilState(StoresAtom);
  const [selectedStore, setSelectedStore] = useRecoilState(SelectedStoreAtom);
  // const navigate = useNavigate()

  const getStoreIdFromUrl = () => {
    const urlArray = window.location.pathname.split("/");
    const sIndex = urlArray.findIndex((each) => each === "s");
    if (sIndex) {
      const storeId = urlArray[sIndex + 1];
      return storeId;
    }
  };

  const fetch = async () => {
    // const { data: response } = await StoreApi.list();
    const { data: response } = await accountInfo();
    setCurUser(response.data);
    const storeListResult = response.data.stores;
    setStores(storeListResult);
    if (!storeListResult.length) {
      setErrType("no_store");
      return;
    }
    if (!Object.keys(selectedStore).length) {
      const storeId = getStoreIdFromUrl();
      if (storeId) {
        const storeInUrl = storeListResult.find(
          (eachStore: any) => eachStore.id === storeId
        );
        if (storeInUrl) {
          setSelectedStore(storeInUrl);
        } else {
          setErrType(storeId);
          return;
        }
      } else {
        setSelectedStore(storeListResult[0]);
      }
    }
    setLoading(false);
    setChangeInStore(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (changeInStore) {
      fetch();
    }
  }, [changeInStore]);

  // console.log(selectedStore.id, "Heyyyye");
  // useEffect(() => {
  //   if (!newN) {
  //     navigate("/");
  //   }
  // }, [newN]);

  return { loading, errType, setErrType, setLoading };
};

export default useFetchAccountInfo;
