import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from '../Modal'
import { BarcodeScanner } from './react-barcode-qrcode-scanner/dist'
import { ViewFinder } from './ViewFinder';
import "./DynamScanner.css"
import Button from '../Button';
import IconButton from '../IconButton';
import CrossIcon from '../../assets/CrossIcon';

type DynamScannerType = {
    setOpenDynamScanner: Dispatch<SetStateAction<boolean>>;
    openDynamScanner: boolean;
    setBarcode: Dispatch<SetStateAction<string>>;
    isActive: boolean;
    setIsActive: Dispatch<SetStateAction<boolean>>;
}

const DynamScanner = ({ setOpenDynamScanner, openDynamScanner, setBarcode, isActive, setIsActive }: DynamScannerType) => {
    const [refresh2, setRefresh2] = useState(false)
    const [isPause, setIsPause] = React.useState(false); //whether the video is paused
    const [allowCamera, setAllowCam] = useState(true)
    const [initialized,setInitialized] = React.useState(false);
    const viewFinder = React.useRef(null);
    const [currentVideoWidth, setCurrentVideoWidth] = React.useState(1280);
    const [currentVideoHeight, setCurrentVideoHeight] = React.useState(720);
    const [left, setLeft] = React.useState(0);
    const [right, setRight] = React.useState(0);
    const [bottom, setBottom] = React.useState(0);
    const [top, setTop] = React.useState(0);
    const [hasDevices, setHasDevices] = useState(true)
    const [runtimeSettings, setRuntimeSettings] = useState(
        '{"ImageParameter":{"BarcodeFormatIds":["BF_ALL"],"Description":"","Name":"Settings"},"Version":"3.0"}'
      );
    
      const onOpened = (cam: any, camLabel: any) => {
        // You can access the video element in the onOpened event
        console.log("opened");
      };
    
      const onClosed = () => {
        console.log("closed");
      };
    
      const onDeviceListLoaded = (devices: any) => {
        console.log(devices);
        if(!devices.length ) {
          setHasDevices(false)
          setIsActive(false);
        } else {
          setHasDevices(true)
        }
      };
    
      const onScanned = (results: any) => {
        // barcode results
        // alert(results, "scan");
        if (results.length) {
          setBarcode(results[0].barcodeText)
          setIsActive(false);
          setAllowCam(false);
          // setOpenDynamScanner(false);
        }
      };
    
      const onClicked = (result: any) => {
        // when a barcode overlay is clicked
        alert(result.barcodeText);
      };
    
      const onInitialized = () => {
        // // when the Barcode Reader is initialized
        setInitialized(true)
      };

    useEffect(() => {
        if(!allowCamera && refresh2) {
          setAllowCam(true)
          setIsActive(true)
          // setOpenDynamScanner(true)
          setIsPause(false)
          setRefresh2(false);
        } else if(!allowCamera) {
          setOpenDynamScanner(false)
        }
      }, [allowCamera])
    
      useEffect(() => {
        if(refresh2) {
          // setOpenDynamScanner(false)
          setIsActive(false);
          setAllowCam(false)
        }
      }, [refresh2])

  return (
      <Modal handleCancel={() => {
        setIsActive(false);
        setAllowCam(false);
        // setOpenDynamScanner(false);
      }}>
      <div className="bg-white w-full h-[60vh] relative flex flex-col items-center justify-between">
        {/* <button className="start-button" onClick={() => setRefresh2(true)}>Refresh</button> */}
        <div className="w-full flex justify-end">
          <IconButton type="tertiary" onClick={() => {
            setIsActive(false);
            setAllowCam(false);
          }}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className="w-full h-[45vh]">
        {hasDevices ? (<BarcodeScanner
          isActive={isActive}
          isPause={isPause}
          license="DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNjU2NTczLVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNjU2NTczIiwiY2hlY2tDb2RlIjo0NTcwMjM2MDF9"
          // drawOverlay={true}
          desiredCamera="back"
          desiredResolution={{ width: 1280, height: 720 }}
          runtimeSettings={runtimeSettings}
          onScanned={onScanned}
          onClicked={onClicked}
          onOpened={onOpened}
          onClosed={onClosed}
          onInitialized={onInitialized}
          onDeviceListLoaded={onDeviceListLoaded}
        >
          {((initialized) && isActive) &&
            <ViewFinder
              ref={viewFinder}
              width={currentVideoWidth}
              height={currentVideoHeight}
              left={left}
              top={top}
              right={right}
              bottom={bottom}
              preserveAspectRatio="xMidYMid slice"
              style={{"--scan-line-color":"red","--scan-rect-color":"green"}}
            >
            </ViewFinder>
          }
        </BarcodeScanner>) : (
          <div className='w-full h-full flex text-base font-semibold text-pot-black/50 justify-center pt-16'>
            No Camera
          </div>
        )}
        </div>
        {hasDevices && (<Button onClick={() => setRefresh2(true)} text="Refresh" />)}
      </div>
      </Modal>
  )
}

export default DynamScanner