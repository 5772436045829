import React from "react";
import { IconProp } from "./ArrowIcons";

const SignoutIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33331 13H2.66665C2.31302 13 1.97389 12.8595 1.72384 12.6095C1.47379 12.3594 1.33331 12.0203 1.33331 11.6667V2.33333C1.33331 1.97971 1.47379 1.64057 1.72384 1.39052C1.97389 1.14048 2.31302 1 2.66665 1H5.33331"
        stroke={color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.3333L13.3333 6.99999L10 3.66666"
        stroke={color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 7H5.33331"
        stroke={color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignoutIcon;
