import React, { useState } from "react";
import HamburgIcon from "../../assets/HamburgIcon";
import MediaSidebar from "./MediaSidebar";
import NavItem from "../Sidebar/NavItem";
import { mediaNavItems } from "../../utils/mediaNavItems";
import { AnimatePresence, motion } from "framer-motion";
import HOMEICON from "../../assets/png/homewines_logo.png"

const MediaSidebarControl = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <div className={`block md:hidden ml-2`}>
      <button
        onClick={() => setOpenSidebar(true)}
        className="h-full px-2 stroke-pot-black"
      >
        <HamburgIcon />
      </button>
      <AnimatePresence>
        {openSidebar && (
          <MediaSidebar handleCancel={() => setOpenSidebar(false)}>
            <div className="flex gap-x-5 mb-8">
              <motion.button initial={{ rotate: 90 }} onClick={() => setOpenSidebar(false)} className="pl-3">
                <HamburgIcon color="black" />
              </motion.button>
              <div className="w-full">
                <img src={HOMEICON} className="w-fit object-contain" />
              </div>
            </div>
            {mediaNavItems.map((each) => (
              <NavItem item={each} />
            ))}
          </MediaSidebar>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MediaSidebarControl;
