import React from "react";
import { motion } from "framer-motion";
import Header from "./SidebarHeader";
import Footer from "./SidebarFooter";
import NavItemComponent from "./NavItem";
import { useRecoilValue } from "recoil";
import { SelectedSessionAtom } from "../../atom";
import InventoryIcon from "../../assets/InventoryIcon";
import SessionIcon from "../../assets/SessionIcon";
import { urls } from "../../utils/urls";
import UsersIcon from "../../assets/UsersIcon";
import StoreIcon from "../../assets/StoreIcon";

const Sidebar = ({ setIsLoggedIn }: any) => {
  const session: any = useRecoilValue(SelectedSessionAtom);
  const workflow = session.workflow;

  const navItems = [
    {
      icon: (color: string) => <InventoryIcon color={color} />,
      title: "Inventory Sessions",
      to: urls.sessions,
      isActive: (location?: string) =>
        location?.endsWith("/session") || location?.endsWith("/session/create"),
    },
    {
      icon: (color: string) => <SessionIcon color={color} />,
      title: "Selected Session",
      withSession: true,
      canChangeName: true,
      subItems: [
        {
          title:
            workflow === "REGISTER_51" ? "Register 51" : "Physical Inventory",
          to: urls.liveInventoryRecords,
          isActive: (location?: string) => location?.endsWith("/records"),
        },
        {
          title: "Scan",
          to: urls.scan,
          isActive: (location?: string) => location?.includes("/scan"),
        },
        {
          title: "Uncounted Items",
          to: urls.uncountedRecords,
          isActive: (location?: string) =>
            location?.endsWith("/uncounted-records"),
        },
        {
          title: "Sales Data Adjustment",
          to: urls.livesales,
          isActive: (location?: string) => location?.endsWith("/livesales"),
        },
        {
          title: "Final Adjustment",
          to: urls.adjustment,
          isActive: (location?: string) => location?.includes("/adjustment"),
        },
        {
          title: "Reports",
          to: urls.reports,
          isActive: (location?: string) => location?.includes("/report"),
        },
      ].filter((subItem) =>
        workflow === "REGISTER_51"
          ? ["Register 51", "Scan"].includes(subItem.title)
          : true
      ),
    },
    {
      icon: (color: string) => <UsersIcon color={color} />,
      title: "Manage Users",
      to: urls.manageUsers,
      permission: ["ADMIN", "MANAGER"],
      isActive: (location?: string) => location?.includes(urls.manageUsers),
    },
    {
      icon: (color: string) => <StoreIcon color={color} />,
      title: "Manage Store",
      to: urls.manageStore,
      permission: ["ADMIN", "MANAGER"],
      isActive: (location?: string) => location?.includes(urls.manageStore),
    },
  ];

  return (
    <motion.div className="bg-white shadow-lg rounded-md w-full h-full flex flex-col">
      <Header />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="h-full overflow-y-auto px-3 mt-8 flex flex-col gap-y-3"
      >
        {navItems.map((each, index) => (
          <NavItemComponent key={index} item={each} />
        ))}
      </motion.div>
      <Footer setIsLoggedIn={setIsLoggedIn} />
    </motion.div>
  );
};

export default Sidebar;
