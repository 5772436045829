import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SessionApi } from "../../api/session";
import {
  AccountInfoAtom,
  NewSessionCreatedAtom,
  SelectedStoreAtom,
} from "../../atom";
import Typography from "../../components/Typography";
import useFetchCategories from "../../hooks/fetch/useFetchCategories";
import { joiner } from "../../utils/common";
import { titles } from "../../utils/titles";
import { urls } from "../../utils/urls";
import LeftForm from "./SessionLeftForm";
import RightForm from "./SessionRightForm";

const SessionCreate = () => {
  const [uploadId, setUploadId] = useState<string>("");
  const [redirectionLink, setRedirectionLink] = useState("");
  const [showableCatList, setShowableCatList] = useState<any[]>([]);
  const [userStorageLoc, setUserStorageLoc] = useState<any[]>([]);
  const [showWorfLow, setShowWorkflow] = useState<any>("");
  const [userAccess, setUserAccess] = useState<any[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const notAllowAddCat = useFetchCategories(uploadId);
  const setNewSessionCreated = useSetRecoilState(NewSessionCreatedAtom);
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === "ADMIN";
  const isManager = role === "MANAGER";
  const isEmp = role === "EMPLOYEE";
  const navigate = useNavigate();

  const onSubmit = async () => {
    setSubmitLoading(true);
    const catDept = [] as any;
    const cats = showableCatList.map((item) => item.split(joiner));
    cats.forEach((cat) => {
      const catObj = {
        cat_name: cat[0],
        dept_name: cat[1],
        sub_dept_name: cat[2],
      };
      catDept.push({ ...catObj });
    });
    const payload = {
      pos_import_id: uploadId,
      category_department: catDept,
      workflow: showWorfLow,
      user_storage_loc: userStorageLoc,
    };
    const { data: response } = await SessionApi.create(store.id, payload);
    if (response.success) {
      toast.success("Session successfully created");
      setNewSessionCreated(true);
      setRedirectionLink(
        "/" +
          urls.liveInventoryRecords
            .replace(":sid", store.id)
            .replace(":id", response.data.id)
      );
    } else {
      Object.values(response.data.error.messages).forEach((error: any) => {
        toast.error(error.join());
      });
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (redirectionLink) {
      navigate(redirectionLink);
    }
  }, [redirectionLink]);

  useEffect(() => {
    document.title = titles.sessionCreate;
  }, []);

  if (isEmp) {
    return (
      <div className="w-full h-full flex justify-center items-center text-pot-textgrey2 font-semibold text-sm">
        You are not allowed to view this page
      </div>
    );
  }

  return (
    <>
      <Typography
        text="Live Inventory Sessions"
        bold
        xxl
        m={{ t: "mt-[4vh]", b: "mb-[1vh]" }}
      />
      <div className="w-full h-full flex justify-center items-center">
        <div className="h-[80vh] w-full overflow-x-hidden bg-white flex rounded-lg border">
          <LeftForm
            loading={submitLoading}
            showWorfLow={showWorfLow}
            setShowWorkflow={setShowWorkflow}
            setUploadId={setUploadId}
            uploadId={uploadId}
            canStart={showableCatList?.length > 0}
            onSubmit={onSubmit}
          />
          {/* <AnimatePresence>
            {uploadId && ( */}
          <RightForm
            showableCatList={showableCatList}
            userStorageLoc={userStorageLoc}
            setUserStorageLoc={setUserStorageLoc}
            setShowableCatList={setShowableCatList}
            setUserAccess={setUserAccess}
            disabledOverall={notAllowAddCat || !uploadId}
          />
          {/* )}
          </AnimatePresence> */}
        </div>
      </div>
    </>
  );
};

export default SessionCreate;
