import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { InventoryApi } from "../../api/inventory";
import {
  LoadingProgressAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
  TableLoadingAtom,
} from "../../atom";
import { toast } from "react-toastify";

const useInventoryRecordTableFetch = ({
  type = "counted",
  search = "",
  category = "",
  sl = "",
  sort = "",
  page = 1,
  length = 10,
  setPage = () => {},
  download = null,
  setDownload = () => {},
}: {
  type?: string;
  search?: string;
  category?: string;
  sl?: string;
  sort?: string;
  page?: number;
  length?: number;
  setPage?: any;
  download?: any;
  setDownload?: any;
}) => {
  // const data = Array.from({ length: 40 }).map((_, index) => ({
  //   QOH: 6,
  //   "QUC": 1,
  //   "QC": 1,
  //   "cat_name": "misc",
  //   "dept_name": "indoensia misc",
  //   "item_code": 51577,
  //   "item_name": "indoensia misc",
  //   "location": "location 1",
  //   "qty_kind": "CASE,PACK",
  //   "sub_dept_name": "indoensia misc",
  //   "upc": 900381,
  //   "upc_shared": 51654
  // }));
  const noDataArray = Array.from({ length }).map(() => ({ noData: true }));
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const [data, setData] = useState(noDataArray);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [called, callAgain] = useState(false);
  const [initial, setInitial] = useState(true);
  const navigate = useNavigate();
  const setTableLoading = useSetRecoilState(TableLoadingAtom);
  const setFetchLoading = useSetRecoilState(LoadingProgressAtom);

  const fetch = async () => {
    setFetchLoading(10);
    if (!called) {
      setTableLoading(true);
    }
    const params = { length, page, list_kind: type } as any;
    if (search) {
      params.search_by = search;
    }
    if (category) {
      params.category = category;
    }
    if (sl) {
      params.storage_location = sl;
    }
    if (sort) {
      params.sort_by = sort;
    }
    if (download) {
      params.download = download;
    }
    setFetchLoading(40);
    let response;

    if (download) {
      try {
        const response = await InventoryApi.download(session.id, params);
        if (response.status !== 200) {
          const errorString = await response.data.text();
          const errorObj = JSON.parse(errorString);
          toast.error(errorObj?.error?.messages?.error);
          return;
        }
        setFetchLoading(70);
        setFetchLoading(100);
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.target = "_blank";
        const storeNum = store?.name.split(" "); // To get the store number only
        const sessionNum = session?.name.split(" "); // To get the session number only
        const workflow = session?.workflow.split("_"); // To remove the _ in the workflow
        const fileName = `${storeNum[1]}_${sessionNum[1]}_${
          workflow[0] + workflow[1]
        }`;
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (e) {
        toast.error(JSON.stringify(e));
      } finally {
        setDownload(null);
        callAgain(false);
      }
    } else {
      const listResponse = await InventoryApi.list(session.id, params);
      response = listResponse.data;
      setFetchLoading(70);
      setTableLoading(false);
      setFetchLoading(100);
      if (!response.success) return;
      setTotalCount(response.data.total_count);
      setTotalPages(response.data.total_pages);
      let settableData = response.data.results;
      // COMMENTED OUT SINCE WE DON'T NEED TO SHOW THE EMPTY ROWS ANYMORE
      // if (settableData.length < length && settableData.length !== 0) {
      // const diff = length - settableData.length;
      // const emptyArr = Array.from({ length: diff }).map(() => ({
      //   noData: true,
      // }));
      // settableData = [...settableData, ...emptyArr];
      // }
      setData(settableData);
      setLoading(false);
      callAgain(false);
    }
  };

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    if (!session.id) {
      navigate("/");
      return;
    }
    fetch();
  }, [page, sort]);

  useEffect(() => {
    setLoading(true);
    if (page !== 1) {
      setPage(1);
      return;
    }
    fetch();
  }, [search, sl, category, length]);

  useEffect(() => {
    if (called) {
      setLoading(true);
      fetch();
    }
  }, [called]);

  return { data, totalCount, totalPages, callAgain, loading };
};

export default useInventoryRecordTableFetch;
