import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import BaseOutlet from "./NavigationOutlet/Base";
import Login from "./pages/Login";
import { urls } from "./utils/urls";
import Sessions from "./pages/Sessions";
import SessionCreate from "./pages/SessionCreate";
import InventoryRecord from "./pages/InventoryRecord";
import InventoryScan from "./pages/InventoryScan";
import FinalAdjustment from "./pages/FinalAdjustment";
import UncountedItems from "./pages/UncountedItems";
import Report from "./pages/Report";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { registerIntercepts } from "./api/axios";
import {
  SelectedLocationAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
  UnAuthorizedRedirectAtom,
} from "./atom";
import ManageStore from "./pages/ManageStore";
import CreateStore from "./pages/CreateStore";
import ManageUsers from "./pages/ManageUsers";
import CreateUsers from "./pages/CreateUsers";
import SessionValidate from "./components/SessionValidate";
import Livesales from "./pages/Livesales";
import SessionEdit from "./pages/SessionEdit/SessionEdit";
import { getFromLocalStorage } from "./utils/localStorage";
import { TimezoneApi } from "./api/timezone";
import { MarkPresence } from "./api/markpresence";
// import "react-toastify/dist/ReactToastify.min.css";

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const setUnAuthorisedRedirect = useSetRecoilState(UnAuthorizedRedirectAtom);
  const selectedLocation = useRecoilValue(SelectedLocationAtom);
  let token = getFromLocalStorage("token");

  useEffect(() => {
    registerIntercepts(
      setLoading,
      setServerErrorMessage,
      setUnAuthorisedRedirect
    );
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  // Mark Presence function
  const markPresence = async () => {
    const pathName = window.location.pathname;
    const pathNameArray = pathName.split("/");
    const sessionId = pathNameArray[4] !== "create" && pathNameArray[4];
    let params: {
      work_storage_location: any;
      work_session: any;
    } = {
      work_storage_location: null,
      work_session: null,
    };

    if (selectedLocation && pathNameArray[5] === "scan") {
      params.work_storage_location = selectedLocation;
    } else {
      delete params.work_storage_location;
    }
    if (sessionId) {
      params.work_session = sessionId;
    }
    const { data: response } = await MarkPresence.call({ params });
  };

  useEffect(() => {
    if (isLoggedIn) {
      const interval = setInterval(() => {
        markPresence();
      }, 30000); // 30 seconds

      return () => clearInterval(interval);
    }
  }, [isLoggedIn, selectedLocation]);

  // Timezone function
  const setTimezone = async () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data: response } = await TimezoneApi.set({ tz: timezone });
  };

  useEffect(() => {
    // Call setTimezone only if the user is logged in
    if (isLoggedIn) {
      setTimezone();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (serverErrorMessage) {
      toast.error(serverErrorMessage);
      setServerErrorMessage("");
    }
  }, [serverErrorMessage]);

  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "fjdj9lkcsc");`;
    // console.log(s, process.env.REACT_APP_TRACKING_CODE, "Hello")
    document.body.appendChild(s);
  }, []);

  if (loading) {
    return (
      <div className="w-full h-[90vh] flex justify-center items-center">
        Loading...
      </div>
    );
  }

  // console.log(getElapsedTime(), "Elapsed Time");

  return (
    <>
      <ToastContainer
        // style={{ marginTop: 0 }}
        toastClassName={({ type }: any) =>
          `${type === "success" && "bg-pot-darkblue"} ${
            type === "error" && "bg-pot-darkmaroon"
          } border relative flex py-3 px-2 rounded-md mt-[9px] justify-between items-center cursor-pointer`
        }
        position="top-center"
        theme="colored"
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        rtl={false}
      />
      <BrowserRouter>
        <Routes>
          <Route
            path={urls.login}
            element={<Login setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route element={<BaseOutlet setIsLoggedIn={setIsLoggedIn} />}>
            <Route path={urls.sessions} element={<Sessions />} />
            <Route path={urls.sessionsCreate} element={<SessionCreate />} />
            <Route path={urls.editSession} element={<SessionEdit />} />
            <Route
              path={urls.liveInventoryRecords}
              element={
                <SessionValidate>
                  <InventoryRecord />
                </SessionValidate>
              }
            />
            <Route
              path={urls.uncountedRecords}
              element={
                <SessionValidate>
                  <UncountedItems />
                </SessionValidate>
              }
            />
            <Route
              path={urls.livesales}
              element={
                <SessionValidate>
                  <Livesales />
                </SessionValidate>
              }
            />
            <Route
              path={urls.scan}
              element={
                <SessionValidate>
                  <InventoryScan />
                </SessionValidate>
              }
            />
            <Route
              path={urls.adjustment}
              element={
                <SessionValidate>
                  <FinalAdjustment />
                </SessionValidate>
              }
            />
            <Route
              path={urls.reports}
              element={
                <SessionValidate>
                  <Report />
                </SessionValidate>
              }
            />
            <Route path={urls.manageStore} element={<ManageStore />} />
            <Route path={urls.manageStoreCreate} element={<CreateStore />} />
            <Route path={urls.manageStoreEdit} element={<CreateStore />} />
            <Route path={urls.manageUsers} element={<ManageUsers />} />
            <Route path={urls.manageUsersCreate} element={<CreateUsers />} />
            <Route path={urls.manageUsersEdit} element={<CreateUsers />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={urls.sessions.replace(":sid", store.id || ":sid")}
                />
              }
            />
            <Route
              element={
                <Navigate
                  to={urls.sessions.replace(":sid", store.id || ":sid")}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
