import { Dispatch, SetStateAction } from "react";
import SearchIcon from "../../assets/SearchIcon";
import Button from "../Button";
import InputField from "../InputField";
import Typography from "../Typography";
import { RightArrowIcon } from "../../assets/ArrowIcons";
import Dropdown from "../Dropdown";
import CategoryIcon from "../../assets/CategoryIcon";

type TableHeaderPropType = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  heading: string;
  buttonClick?: () => void;
  buttonClick2?: () => void;
  categoriesOptions?: any;
  withDebounce?: boolean;
  dataExists?: boolean;
  data?: any;
  setData?: Dispatch<any>;
  setSelectedCategory?: Dispatch<SetStateAction<string>>;
  selectedCategory?: string;
};

const HeaderAndControl2 = ({
  search,
  setSearch,
  heading,
  buttonClick = () => {},
  buttonClick2 = () => {},
  withDebounce = false,
  categoriesOptions = [],
  dataExists,
  data,
  setData,
  setSelectedCategory = () => {},
  selectedCategory = "",
}: TableHeaderPropType) => {
  return (
    <section className="text-sm mb-[2vh]">
      <div className="w-full flex justify-between items-end mb-[2vh]">
        <Typography text={heading} bold xxl m={{ t: "mt-[4vh]" }} />
      </div>
      <div className="flex flex-col gap-y-3 md:gap-y-0 md:flex-row justify-between">
        <div className="w-2/3 md:w-1/3">
          <InputField
            placeholder="Search"
            leftIcon={<SearchIcon color="#4F4F4F" />}
            value={search}
            onChange={(text) => setSearch(text)}
            withDebounce={withDebounce}
            disabled={dataExists}
            data={data}
            setData={setData}
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap gap-y-2 z-[38] gap-x-3 text-xs">
        <Dropdown
            optionList={categoriesOptions}
            buttonText={selectedCategory || "Select a category"}
            buttonProps={{ leftIcon: <CategoryIcon /> }}
            beautify
            selectOption={(value) =>
              selectedCategory === value.label
                ? setSelectedCategory("")
                : setSelectedCategory(value.label)
            }
            data={data}
            setData={setData}
          />
          <Button
            type="primary_outline"
            text="Fetch Sales Data"
            disabled={dataExists}
            onClick={buttonClick}
            leftIcon={<CategoryIcon />}
          />
          <Button
            text="Submit"
            onClick={buttonClick2}
            disabled={dataExists}
            leftIcon={<RightArrowIcon />}
          />
        </div>
      </div>
    </section>
  );
};

export default HeaderAndControl2;
