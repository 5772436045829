import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { UserApi } from "../../api/user";
import { AccountInfoAtom, LoadingProgressAtom } from "../../atom";
import InputField from "../../components/InputField";
import Typography from "../../components/Typography";
import { titles } from "../../utils/titles";
import UserForm from "./UserForm";

const CreateUsers = () => {
  const { id } = useParams();
  const [editData, setEditData] = useState<any>(null);
  const setLoadingProgress = useSetRecoilState(LoadingProgressAtom)
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === "ADMIN";
  const isManager = role === "MANAGER";
  const isEmp = role === "EMPLOYEE";

  const fetchUser = async () => {
    setLoadingProgress(10)
    const { data: response } = await UserApi.show(id as string);
    if (response.success) {
      setEditData(response.data);
    }
    setLoadingProgress(100)
  };

  useEffect(() => {
    document.title = titles.manageUsersCreate;
  }, []);

  useEffect(() => {
    if (id && !isEmp) {
      fetchUser();
    }
  }, [id]);

  if (isEmp) {
    return (
      <div className="w-full h-full pb-[20vh] text-sm text-pot-textgrey2 flex justify-center items-center font-semibold">
        You are not allowed to view this page
      </div>
    );
  }

  return (
    <>
      <Typography
        text={id ? "Edit User" :"Create Users"}
        bold
        xxl
        m={{ t: "mt-[4vh]", b: "mb-[1vh]" }}
      />
      <UserForm editData={editData} />
    </>
  );
};

export default CreateUsers;
