import React from "react";
import "./Checkbox.css";

type CheckboxPropType = {
  label?: string;
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
  small?: boolean;
  showMinus?: boolean;
};

const Checkbox = ({
  checked = false,
  onClick,
  label,
  disabled = false,
  small = false,
  showMinus = false,
}: CheckboxPropType) => {
  const size = small
    ? "w-4 h-4"
    : "min-w-[20px] min-h-[20px] lg:w-5 lg:h-5 w-4 h-4";

  return (
    <label className="flex gap-x-2.5 items-center relative cursor-pointer w-fit">
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={disabled ? () => {} : onClick}
        className={`appearance-none ${size} rounded-md ${
          checked
            ? `${
                disabled
                  ? "checked relative cursor-not-allowed before:bg-pot-textgrey shadow-[inset_0_0_0_1px_#DEDEDE] bg-pot-grey3"
                  : "checked before:bg-white bg-pot-darkmaroon hover:bg-pot-maroon active:bg-pot-darkermaroon cursor-pointer relative"
              }`
            : `${
                disabled
                  ? "cursor-not-allowed"
                  : showMinus
                  ? "minus before:bg-white bg-pot-darkmaroon hover:bg-pot-maroon active:bg-pot-darkermaroon cursor-pointer relative"
                  : "cursor-pointer hover:shadow-[inset_0_0_0_1px_#932223] hover:bg-pot-lightmaroon active:bg-pot-lightmaroon2"
              } shadow-[inset_0_0_0_1px_#DEDEDE]`
        }`}
      />
      {label && <span>{label}</span>}
    </label>
  );
};

export default Checkbox;
