import { authenticatedAxiosInstance as axios } from "./axios";

const list = (sId: string, params: any) =>
  axios.get(`/live-inventory/${sId}/items`, { params });
const download = (sId: string, params: any) =>
  axios.get(`/live-inventory/${sId}/items`, {
    params,
    responseType: "blob",
  });
const create = (sId: string, payload: any) =>
  axios.post(`/live-inventory/${sId}/scan`, payload);
const history = (session_id: string) =>
  axios.get(`/live-inventory/${session_id}/record`);
const update = (session_id: string, record_id: string, payload: any) =>
  axios.patch(`/live-inventory/${session_id}/record/${record_id}`, payload);
const categories = (session_id: string) =>
  axios.get(`/live-inventory/${session_id}/cat-dept`);
const recheck = (session_id: string, payload: any) =>
  axios.post(`/live-inventory/${session_id}/mark-rechecked`, payload);
const freeze = (session_id: string, payload: any) =>
  axios.post(`/live-inventory/${session_id}/freeze`, payload);
const stat = (session_id: string) =>
  axios.get(`/live-inventory/${session_id}/stats`);
const adjust = (session_id: string, payload: any) =>
  axios.post(`/live-inventory/${session_id}/adjust`, payload);
const findItems = (session_id: string, param: { params: any }) =>
  axios.get(`/live-inventory/${session_id}/find-barcode`, param);

export const InventoryApi = {
  list,
  download,
  create,
  history,
  update,
  categories,
  recheck,
  freeze,
  stat,
  adjust,
  findItems,
};
