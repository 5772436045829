import React from "react";

const ResetPasswordIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2499 9.08337C18.0068 9.08337 17.7736 9.17995 17.6017 9.35186C17.4298 9.52377 17.3333 9.75693 17.3333 10C17.3442 11.717 16.756 13.384 15.6701 14.714C14.5842 16.0439 13.0685 16.9536 11.3841 17.2863C9.69961 17.619 7.95189 17.3538 6.44188 16.5366C4.93188 15.7193 3.75412 14.4011 3.11147 12.8089C2.46881 11.2168 2.40148 9.45033 2.92107 7.81386C3.44066 6.17739 4.51465 4.77333 5.95806 3.8435C7.40148 2.91368 9.12395 2.51629 10.8288 2.71979C12.5337 2.92329 14.1143 3.71493 15.2983 4.95837H13.0983C12.8551 4.95837 12.622 5.05495 12.4501 5.22686C12.2782 5.39877 12.1816 5.63192 12.1816 5.87504C12.1816 6.11816 12.2782 6.35131 12.4501 6.52322C12.622 6.69513 12.8551 6.79171 13.0983 6.79171H17.2508C17.4939 6.79171 17.727 6.69513 17.8989 6.52322C18.0708 6.35131 18.1674 6.11816 18.1674 5.87504V1.75004C18.1674 1.50693 18.0708 1.27377 17.8989 1.10186C17.727 0.929951 17.4939 0.833374 17.2508 0.833374C17.0076 0.833374 16.7745 0.929951 16.6026 1.10186C16.4307 1.27377 16.3341 1.50693 16.3341 1.75004V3.37254C14.8075 1.91326 12.8239 1.02583 10.7186 0.860259C8.61327 0.694687 6.51533 1.26113 4.77943 2.46384C3.04353 3.66655 1.77615 5.43175 1.19151 7.46105C0.606882 9.49035 0.740864 11.6593 1.57081 13.6012C2.40076 15.5431 3.87577 17.1389 5.74649 18.1188C7.61722 19.0987 9.76892 19.4027 11.8379 18.9792C13.9068 18.5558 15.7661 17.431 17.1015 15.7949C18.4368 14.1588 19.1663 12.1119 19.1666 10C19.1666 9.75693 19.07 9.52377 18.8981 9.35186C18.7262 9.17995 18.493 9.08337 18.2499 9.08337Z"
        fill="#132044"
        stroke="white"
        strokeWidth="0.25"
      />
      <path
        d="M12.0834 8.74997H8.75008V7.91663C8.74947 7.66921 8.82231 7.42718 8.95936 7.22118C9.09641 7.01518 9.29151 6.85449 9.51995 6.75945C9.74839 6.66441 9.9999 6.63931 10.2426 6.68732C10.4853 6.73533 10.7084 6.85429 10.8834 7.02913C11.0401 7.18916 11.1521 7.38739 11.2084 7.60413C11.2221 7.65721 11.2461 7.70707 11.279 7.75087C11.312 7.79467 11.3532 7.83156 11.4004 7.85941C11.4477 7.88727 11.4999 7.90556 11.5542 7.91323C11.6084 7.9209 11.6637 7.91781 11.7167 7.90413C11.7698 7.89045 11.8197 7.86645 11.8635 7.83351C11.9073 7.80056 11.9442 7.7593 11.972 7.7121C11.9999 7.6649 12.0182 7.61267 12.0258 7.5584C12.0335 7.50413 12.0304 7.44888 12.0167 7.3958C11.9218 7.0353 11.7336 6.70619 11.4709 6.44163C11.1793 6.15095 10.8081 5.95319 10.4042 5.87332C10.0003 5.79345 9.5818 5.83505 9.20152 5.99286C8.82124 6.15068 8.49625 6.41765 8.26758 6.76004C8.03892 7.10243 7.91684 7.5049 7.91675 7.91663V8.74997C7.58523 8.74997 7.26729 8.88166 7.03286 9.11608C6.79844 9.3505 6.66675 9.66845 6.66675 9.99997V12.9166C6.66675 13.2482 6.79844 13.5661 7.03286 13.8005C7.26729 14.0349 7.58523 14.1666 7.91675 14.1666H12.0834C12.4149 14.1666 12.7329 14.0349 12.9673 13.8005C13.2017 13.5661 13.3334 13.2482 13.3334 12.9166V9.99997C13.3334 9.66845 13.2017 9.3505 12.9673 9.11608C12.7329 8.88166 12.4149 8.74997 12.0834 8.74997ZM12.5001 12.9166C12.5001 13.0271 12.4562 13.1331 12.378 13.2113C12.2999 13.2894 12.1939 13.3333 12.0834 13.3333H7.91675C7.80624 13.3333 7.70026 13.2894 7.62212 13.2113C7.54398 13.1331 7.50008 13.0271 7.50008 12.9166V9.99997C7.50008 9.88946 7.54398 9.78348 7.62212 9.70534C7.70026 9.6272 7.80624 9.5833 7.91675 9.5833H12.0834C12.1939 9.5833 12.2999 9.6272 12.378 9.70534C12.4562 9.78348 12.5001 9.88946 12.5001 9.99997V12.9166Z"
        fill="#132044"
        stroke="#132044"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default ResetPasswordIcon;
