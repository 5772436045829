import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { StoreApi } from "../../api/store";
import { LoadingProgressAtom, TableLoadingAtom } from "../../atom";

type StoreFetchProps = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  length: number;
  setLength: Dispatch<SetStateAction<number>>;
  search?: string;
  permission: boolean;
};

const useStoreFetch = ({
  page,
  setPage,
  length,
  setLength,
  search = "",
  permission,
}: StoreFetchProps) => {
  const noDataArray = Array.from({ length }).map(() => ({ noData: true }));
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState(noDataArray);
  const [initial, setInitial] = useState(true);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);
  const setLoadinProgress = useSetRecoilState(LoadingProgressAtom);

  const callAgain = () => {
    setCalled(true);
  };

  const fetch = async () => {
    setLoadinProgress(10);
    if (!called) {
      setTableLoading(true);
    }
    const params: { length: number; page: number; search_by?: string } = {
      length,
      page,
    };
    if (search) {
      params.search_by = search;
    }
    console.log(params);
    setLoadinProgress(30);
    const { data: response } = await StoreApi.list({ params });
    setLoadinProgress(70);
    if (response.success) {
      let settableData = response.data.results;
      // COMMENTED OUT SINCE WE DON'T NEED TO SHOW THE EMPTY ROWS ANYMORE
      // if (settableData.length < length && settableData.length) {
      // const diff = length - settableData.length;
      // const emptyArr = Array.from({ length: diff }).map(() => ({
      //   noData: true,
      // }));
      // settableData = [...settableData, ...emptyArr];
      // }
      setData(settableData);
      setLoading(false);
      setTotalCount(response.data.total_count);
      setTotalPages(response.data.total_pages);
    }
    setLoadinProgress(100);
    setCalled(false);
    setTableLoading(false);
  };

  useEffect(() => {
    if (!permission) {
      return;
    }
    console.log("works in page");
    fetch();
  }, [page]);

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    if (!permission) {
      return;
    }
    console.log("call");
    if (page === 1) {
      fetch();
    } else {
      setPage(1);
    }
  }, [search, length]);

  useEffect(() => {
    if (called) {
      fetch();
    }
  }, [called]);

  return {
    data,
    totalCount,
    totalPages,
    loading,
    callAgain,
  };
};

export default useStoreFetch;
