import InventoryIcon from "../assets/InventoryIcon";
import SessionIcon from "../assets/SessionIcon";
import { urls } from "./urls";

export const mediaNavItems = [
  {
    icon: (color: string) => <InventoryIcon color={color} />,
    title: "Inventory Sessions",
    to: urls.sessions,
    isActive: (location?: string) =>
      location?.endsWith("/session") || location?.endsWith("/session/create"),
  },
  {
    icon: (color: string) => <SessionIcon color={color} />,
    title: "Selected Session",
    withSession: true,
    canChangeName: true,
    subItems: [
      {
        title: "Scan",
        to: urls.scan,
        isActive: (location?: string) => location?.includes("/scan"),
      },
    ],
  },
];
