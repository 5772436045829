import { SelectableDropdownValueType } from "../components/Dropdown";

export type selectedCategoryType = {
  cat?: SelectableDropdownValueType | null;
  dept?: SelectableDropdownValueType | null;
  subDepts?: SelectableDropdownValueType[];
  disabled?: number;
};

export enum selCatActionTypeEnum {
  setCat = "SET_CAT",
  setDept = "SET_DEPT",
  setSubDepts = "SET_SUBDEPT",
  clear = "CLEAR",
}

export type selCatActionType = {
  type: selCatActionTypeEnum;
  payload?: any;
};
