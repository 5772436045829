const PAGES_MAX = 5;

export const startOfCount = (length: number, page: number) =>
  (page - 1) * length + 1;

export const endOfCount = (length: number, page: number, totalCount: number) =>
  page * length > totalCount ? totalCount : page * length;

export const paginationLength = (totalPages: number, offset: number) => {
  return totalPages - (offset * PAGES_MAX - 1) > PAGES_MAX
    ? PAGES_MAX
    : totalPages - (offset ? offset * PAGES_MAX - 1 : 0);
};
