import React from "react";
import { urls } from "../utils/urls";
import LinkDiv from "./LinkDiv";
import { motion } from "framer-motion";
import RetryIcon from "../assets/RetryIcon";

type MustButtonPropsType = {
  text: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
};

export type ButtonPropsType = {
  loading?: boolean;
  fullWidth?: boolean;
  parentFullWidth?: boolean;
  type?:
    | "primary"
    | "primary_outline"
    | "secondary"
    | "tertiary"
    | "blue"
    | "blank";
  disabled?: boolean;
  leftIcon?: React.ReactNode | null;
  rightIcon?: React.ReactNode | null;
  margins?: string;
  small?: boolean;
  to?: string;
};

export const primaryStyle: string =
  "bg-pot-darkblue text-white stroke-white hover:bg-pot-blue active:bg-pot-darkerblue ease-in-out transition";
export const primaryOutlineStyle: string =
  "ease-in-out transition text-pot-darkblue stroke-pot-darkblue shadow-[inset_0_0_0_1px_rgba(19,32,68,1)] hover:shadow-[inset_0_0_0_1px_rgba(28,61,150,1)] hover:bg-pot-lightblue2 hover:text-pot-blue2 hover:stroke-pot-blue2 active:shadow-[inset_0_0_0_1px_rgba(23,51,128,1)] active:bg-pot-lightblue active:text-pot-blue active:stroke-pot-blue";
export const secondaryStyle: string =
  "bg-pot-darkmaroon text-white stroke-white hover:bg-pot-maroon active:bg-pot-darkermaroon ease-in-out transition";
export const tertiaryStyle: string =
  "text-pot-darkblue stroke-pot-darkblue hover:bg-pot-lightblue2 hover:text-pot-blue2 hover:stroke-pot-blue2 active:bg-pot-lightblue active:text-pot-blue active:stroke-pot-blue";
const blueStyle: string = "bg-pot-blue text-white stroke-white";
const blankStyle: string = "bg-transparent text-black stroke-black";

export const primaryDisabledStyle: string =
  "bg-pot-grey text-pot-textgrey stroke-pot-textgrey cursor-not-allowed";
export const primaryOutlineDisabledStyle: string =
  "shadow-[inset_0_0_0_1px_rgba(203,203,203,1)] text-pot-textgrey stroke-pot-textgrey cursor-not-allowed";
export const tertiaryDisabledStyle: string =
  "text-pot-textgrey stroke-pot-textgrey cursor-not-allowed";

const Button = ({
  fullWidth,
  parentFullWidth = false,
  type = "primary",
  text,
  disabled = false,
  leftIcon = null,
  rightIcon = null,
  onClick = () => {},
  margins = "",
  small = false,
  to = "",
  loading = false,
}: ButtonPropsType & MustButtonPropsType) => {
  const fullWidthStyle = fullWidth
    ? "w-full justify-center mx-5"
    : "w-fit justify-between";
  const typeStyle =
    type === "primary"
      ? primaryStyle
      : type === "primary_outline"
      ? primaryOutlineStyle
      : type === "secondary"
      ? secondaryStyle
      : type === "tertiary"
      ? tertiaryStyle
      : type === "blue"
      ? blueStyle
      : type === "blank" && blankStyle;
  const disabledStyle =
    type === "primary" || type === "secondary"
      ? primaryDisabledStyle
      : type === "primary_outline"
      ? primaryOutlineDisabledStyle
      : type === "tertiary" && tertiaryDisabledStyle;

  return (
    <LinkDiv to={to} className={parentFullWidth ? "w-full" : "w-fit"}>
      <button
        onClick={() => !disabled && !loading && onClick()}
        className={`flex px-4 ${margins}  ${
          type === "blank" ? "py-1" : small ? "py-1.5" : "py-2.5"
        } gap-x-3 items-center rounded-md ${fullWidthStyle} ${
          disabled ? disabledStyle : typeStyle
        } `}
      >
        {loading ? (
          <motion.div
            animate={{ rotate: -360 }}
            transition={{ repeat: Infinity, duration: 0.8 }}
            className="py-1.5"
          >
            <RetryIcon />
          </motion.div>
        ) : (
          <>
            {leftIcon}
            <p className="max-w-[40vw] truncate">{text}</p>
            {rightIcon && (
              <div className="pl-2 flex items-center">{rightIcon}</div>
            )}
          </>
        )}
      </button>
    </LinkDiv>
  );
};

export default Button;
