import React, { useEffect, useState } from "react";
import { DownwardArrowIcon } from "../../assets/ArrowIcons";
import Line from "../../components/Line";
import SessionCard from "../../components/SessionCard";
import Typography from "../../components/Typography";
import Header from "./SessionsHeader";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router";
import useFetchSessions from "../../hooks/fetch/useFetchSessions";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  SelectedSessionAtom,
  SelectedStoreAtom,
  SessionSpecificAtom,
} from "../../atom";
import { urls } from "../../utils/urls";
import SectionSelectorBar from "../InventoryScan/SectionSelectorBar";
import { titles } from "../../utils/titles";
import { SessionApi } from "../../api/session";

const Sessions = () => {
  const [openCompletedSection, setOpen] = useState<boolean>(true);
  const setSelectedSession = useSetRecoilState(SelectedSessionAtom);
  const [sessionSpecificData, setSessionSpecificData] = useRecoilState(
    SessionSpecificAtom
  ) as any;
  const [selectedPage, setSelectedPage] = useState<0 | 1>(0);
  const [showSessionsCompleted, setShowSessionsCompleted] =
    useState<boolean>(true);
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const sessions = useFetchSessions();

  const navigate = useNavigate();

  const getURL = (session: any) => {
    let url;
    const inProgress = session.status.toLowerCase().includes("progress");

    if (window.innerWidth <= 768) {
      url = urls.scan.replace(":sid", store.id).replace(":id", session.id);
    } else if (inProgress) {
      url = urls.liveInventoryRecords
        .replace(":sid", store.id)
        .replace(":id", session.id);
    } else {
      url = urls.liveInventoryRecords
        .replace(":sid", store.id)
        .replace(":id", session.id);
    }
    return url;
  };

  const getSessionData = async (session: any) => {
    const { data: response } = await SessionApi.editGet(store.id, session.id);
    if (response.success) {
      const data = response.data;
      setSessionSpecificData(data);
    }
  };

  const onClick = (value: any) => {
    setSelectedSession(value);
    getSessionData(value);
    const url = getURL(value);
    navigate(`/${url}`);
  };

  useEffect(() => {
    document.title = titles.sessions;
  }, []);

  return (
    <>
      <SectionSelectorBar
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        names={["Session In Progress", "Session Completed"]}
      />
      <Header />

      {/* In Progress */}
      <section
        className={`h-full z-20 ${
          selectedPage === 0 ? "" : "hidden md:block"
        } overflow-y-auto pr-4 mb-[2vh] no-scrollbar`}
      >
        <div className="sticky z-30 hidden md:block top-0 bg-pot-base">
          <Typography text="Sessions in progress" bold fullWidth />
        </div>
        {!sessions.progressLoading &&
          (sessions.progress.length + sessions.adjustment.length ? (
            <div className="grid pt-[2vh] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
              {sessions.progress?.map((session: any, index) => (
                <SessionCard
                  data={session}
                  onClick={() => {
                    onClick(session);
                  }}
                  key={index}
                  allowEdit
                />
              ))}
              {sessions.adjustment?.map((session, index) => (
                <SessionCard
                  data={session}
                  key={index}
                  onClick={() => {
                    onClick(session);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="w-full flex justify-center pt-[6vh] text-pot-textgrey2">
              No Session in progress
            </div>
          ))}
      </section>

      {window.innerWidth > 768 && <Line />}

      {/* Completed */}
      <section
        className={`${openCompletedSection ? "h-[40px]" : "h-full"} ${
          selectedPage === 1 ? "" : "hidden md:block"
        } z-20 overflow-y-auto pr-4 mt-[2vh] mb-[2vh] transition-all no-scrollbar`}
      >
        <button
          onClick={() => setOpen((prev) => !prev)}
          className="sticky z-10 top-0 hidden md:flex w-full items-center gap-x-3 bg-pot-base pb-[2vh]"
        >
          <Typography text="Sessions completed" bold />
          <motion.div
            animate={{ rotate: openCompletedSection ? 180 : 360 }}
            className="stroke-black"
          >
            <DownwardArrowIcon />
          </motion.div>
        </button>
        {sessions?.complete?.length > 0 ? (
          <motion.div
            animate={
              !openCompletedSection
                ? { marginTop: "0", opacity: 1 }
                : { marginTop: "-100%", opacity: 0 }
            }
            transition={{
              ease: [0.87, 0.86, 0.16, 0.86],
              duration: !openCompletedSection ? 0.6 : 0.4,
            }}
            className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5"
          >
            {sessions.complete?.map((session, index) => (
              <SessionCard
                data={session}
                onClick={() => {
                  onClick(session);
                }}
                key={index}
                allowDownload
              />
            ))}
          </motion.div>
        ) : (
          <div
            className={`w-full text-pot-textgrey2 flex justify-center pt-[6svh]`}
          >
            No Completed Sessions
          </div>
        )}
      </section>
    </>
  );
};

export default Sessions;
