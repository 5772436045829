import React, { Dispatch, SetStateAction } from 'react'
import { validatePhone } from '../utils/validations'
import InputField from './InputField'
import Typography from './Typography'

type PhoneFieldType = {
    error: string | string[],
    phone: string,
    setPhone: Dispatch<SetStateAction<string>>
}

const PhoneField = ({ error, phone, setPhone }: PhoneFieldType) => {
  return (
    <div>
        <Typography
          text="Phone number"
          className="py-4 text-sm xl:text-[15px]"
          semibold
        />
        <div className="flex gap-x-5">
          {/* <select
            defaultValue={"+91"}
            className="w-12 border rounded-md px-3 py-1"
          >
            <option value="+91">+91</option>
          </select> */}
          <InputField
            value={phone}
            validate={(val) => (val ? validatePhone(val, true) || phone : "")}
            onChange={(val) => setPhone(val)}
            placeholder="+16234566789"
          />
        </div>
        {error &&
        (typeof error === "string" ? (
          <p className="px-1 pt-1 font-light text-xs text-pot-maroon">
            {error}
          </p>
        ) : error.length ? ( error.length === 1 ? (
          <p className="px-1 pt-1 font-light text-xs text-pot-maroon">
            {error[0]}
          </p>
        ) : (
          <ul className="list-disc px-4">
            {error.map((each: any) => (
              <li className="pt-1 font-light text-xs text-pot-maroon">
                {each}
              </li>
            ))}
          </ul>
        )) : "")}
      </div>
  )
}

export default PhoneField