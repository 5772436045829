// import axios from "axios";
import { authenticatedAxiosInstance as axios } from "../../api/axios";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { BASE_URL } from "../../api/axios";
import { InventoryApi } from "../../api/inventory";
import {
  AccountInfoAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
} from "../../atom";
import Button from "../../components/Button";
import DropZone from "../../components/DropZone";
import Typography from "../../components/Typography";
import useRedirectWithoutSession from "../../hooks/useRedirectWithoutSession";
import { toastIt } from "../../utils/common";
import { getFromLocalStorage } from "../../utils/localStorage";
import { titles } from "../../utils/titles";
import FinalAdjustmentList from "./List";
import { RightArrowIcon } from "../../assets/ArrowIcons";
import FinishBottomBar from "../../components/FinishBottomBar";

const FinalAdjustment = () => {
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const [showList, setShowList] = useState<boolean>(false);
  const [uploadId, setUploadId] = useState("");
  const [error, setError] = useState("");
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const store = useRecoilValue(SelectedStoreAtom) as any;
  useRedirectWithoutSession();
  const workflow = session.workflow;

  const uploadFile = async (
    accepted: any,
    setUploadProgress?: Dispatch<SetStateAction<number>>
  ) => {
    setError("");
    const formData = new FormData();
    formData.append("file", accepted, accepted.name);
    setUploadProgress?.(50);
    const { data: response } = await axios.post(
      `/store/${store.id}/pos-data/upload`,
      formData,
      {
        baseURL: BASE_URL,
        headers: {
          // Authorization: `${getFromLocalStorage("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.success) {
      setUploadProgress?.(100);
      setUploadId(response.data.pos_import_id);
    } else {
      const errors = response.error;
      const errorArr = Object.keys(errors.messages).map((each: any) => {
        if (
          errors.messages[each]
            .join(", ")
            .toLowerCase()
            .includes("invalid token")
        ) {
          return "";
        }
        return errors.messages[each].join(", ");
      });
      setError(errorArr.join("; "));
      setUploadProgress?.(0);
    }
  };

  const freeze = async () => {
    // const payload = {} as any
    // if(uploadId) {
    //   payload.pos_import_id = uploadId
    // }
    // const { data: response } = await InventoryApi.freeze(session.id, payload)
    // if(response.success || response.error.messages.NON_FIELD_ERRORS.includes("Freeze can only be done for in progress session")) {
    //   setShowList(true)
    // } else {
    //   toastIt(response.error)
    // }
  };

  useEffect(() => {
    document.title = titles.adjustment;
    freeze();
  }, []);

  if (showList) {
    return <FinalAdjustmentList />;
  }

  return (
    <div className="w-full">
      <Typography
        text="Final Adjustment"
        bold
        xxl
        m={{ t: "mt-[4vh]", b: "mb-[1vh]" }}
      />
      <div className="w-full bg-white rounded-md text-sm border px-5">
        <Typography text="Initiate upload POS" bold m={{ t: "mt-[4vh]" }} />
        <DropZone onUpload={uploadFile} />
        <div className="w-full flex flex-col justify-center items-center  h-20">
          {error && (
            <Typography
              text={error}
              m={{ b: "mb-1", l: "ml-1" }}
              className="text-pot-maroon"
            />
          )}

          <div className="h-fit w-fit">
            <Button
              // disabled={!uploadId}
              onClick={freeze}
              text="Freeze"
            />
          </div>
        </div>
        <p className="pb-2 text-pot-black text-xs">
          <span className="text-red-800">* </span>
          Freeze the data to adjust the final counted items
        </p>
      </div>
      {workflow === "PHYSICAL_INVENTORY" &&
        session?.status === "IN_PROGRESS" && (
          <FinishBottomBar
            storeId={store?.id}
            sessionId={session?.id}
            role={role}
          />
        )}
    </div>
  );
};

export default FinalAdjustment;
