export const titles = {
  login: "Login",
  sessions: "Sessions",
  sessionCreate: "Sessions Create",
  sessionEdit: "Sessions Edit",
  liveInventoryRecords: "Physical Inventory Records",
  register51Records: "Register 51 Records",
  livesalesRecords: "Live Sales Records",
  uncountedRecords: "Uncounted Records",
  scan: "Scan",
  scan_history: "Scan (History)",
  adjustment: "Adjustment",
  reports: "Reports",
  manageUsers: "Manage Users",
  manageUsersEdit: "Manage Users",
  manageUsersCreate: "Manage Users",
  manageStore: "Manage Store",
  manageStoreEdit: "Manage Store",
  manageStoreCreate: "Manage Store",
};
