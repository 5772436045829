import React from "react";
import { IconProp } from "./ArrowIcons";

const TickIcon = ({ color, width = "14" }: IconProp) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3333 1L4.99999 8.33333L1.66666 5"
        stroke={color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickIcon;
