import { capitalize } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { UserApi } from "../../api/user";
import EditIcon from "../../assets/EditIcon";
import MenuIcon from "../../assets/MenuIcon";
import ResetPasswordIcon from "../../assets/ResetPasswordIcon";
import TrashIcon from "../../assets/TrashIcon";
import { AccountInfoAtom } from "../../atom";
import IconButton from "../../components/IconButton";
import { toastIt } from "../../utils/common";
import { urls } from "../../utils/urls";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";

const useUsersColdef = (
  callAgain = () => {},
  called: boolean,
  openDeleteModal: string,
  setOpenDeleteModal: Dispatch<SetStateAction<string>>
) => {
  const [coldef, setColdef] = useState([]);
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === "ADMIN";
  const isManager = role === "MANAGER";
  const isEmp = role === "EMPLOYEE";
  const navigate = useNavigate();

  const doToggle = async (id: string, is_active: boolean) => {
    const { data: response } = await UserApi.update(id, {
      is_active: !is_active,
    });
    callAgain();
    if (response.success) {
      toast.success(
        "Successfully made the user " + (is_active ? "inactive" : "active")
      );
    }
  };

  const resetPassword = async (id: string) => {
    const { data: response } = await UserApi.resetPassword(id);
    if (response.success) {
      toast.success(
        "Successfully sent reset password request to the user's email"
      );
    } else {
      toastIt(response.errors);
    }
  };

  const destroy = async (id: string) => {
    const { data: response } = await UserApi.destroy(id);
    if (response.success) {
      toast.success("Successfully deleted the user");
      callAgain();
    }
  };

  const initialColdef = [
    {
      header: "Name",
      accessorKey: "first_name",
    },
    {
      header: "Email Id",
      accessorKey: "email",
    },
    {
      header: "Phone number",
      accessorKey: "phone_no",
    },
    ,
    {
      header: "Designation",
      accessorKey: "role",
      cell: ({ getValue }: any) => capitalize(getValue()),
    },
    ,
    {
      header: "stores",
      accessorKey: "stores",
      cell: ({ getValue }: any) => getValue()?.length,
    },
  ] as any;

  if (isAdmin || isManager) {
    initialColdef.push({
      header: "Action",
      cell: ({ row }: any) => {
        if (row.original.noData) {
          return <div className="min-h-[3vh]"></div>;
        }

        return (
          <div className="flex gap-x-3 items-center">
            {/* <Toggle
              tooltip={
                row.original.is_active
                  ? "Click to disable account"
                  : "Click to enable account"
              }
              active={row.original.is_active ? true : false}
              onChange={() => doToggle(row.original.id, row.original.is_active)}
            /> */}
            <ToggleSwitch
              isChecked={row.original.is_active ? true : false}
              tooltip={
                row.original.is_active
                  ? "Click to disable account"
                  : "Click to enable account"
              }
              handleToggle={() =>
                doToggle(row.original.id, row.original.is_active)
              }
              type="tick"
            />
            <IconButton
              tooltip="Edit"
              onClick={() =>
                navigate(
                  "/" + urls.manageUsersEdit.replace(":id", row.original.id)
                )
              }
              type="tertiary"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              tooltip="Send reset password request"
              type="tertiary"
              onClick={() => resetPassword(row.original.id)}
            >
              <ResetPasswordIcon />
            </IconButton>
            <IconButton
              tooltip="Permanently delete account"
              type="tertiary"
              onClick={() => setOpenDeleteModal(row.original.id)}
            >
              <TrashIcon />
            </IconButton>
          </div>
        );
      },
    });
  }

  useEffect(() => {
    if (!called) {
      setColdef(initialColdef);
    }
  }, [called]);

  return { coldef, destroy };
};

export default useUsersColdef;
