import React from "react";
import DownloadIcon from "../assets/DownloadIcon";
import IconButton from "./IconButton";
import Typography from "./Typography";
import { motion } from "framer-motion";
import {
  calculateDurationInHoursMinutes,
  date,
  formatSnakeToNormal,
  time,
} from "../utils/helperFunctions";
import EditIcon from "../assets/EditIcon";
import { useNavigate } from "react-router";
import { urls } from "../utils/urls";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SelectedSessionAtom, SelectedStoreAtom } from "../atom";

type SessionCardPropType = {
  allowDownload?: boolean;
  allowEdit?: boolean;
  data: any;
  onClick?: () => void;
};

const SessionCard = ({
  allowDownload = false,
  allowEdit = false,
  data,
  onClick = () => {},
}: SessionCardPropType) => {
  const setSelectedSession = useSetRecoilState(SelectedSessionAtom);
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const navigate = useNavigate();
  const isAdj = data.status.toLowerCase() === "adjustment";
  const workflow = data?.workflow?.replace(/_/g, " ");
  const startDate = data?.created_at;
  const endDate = data?.status === "COMPLETE" ? data?.completed_at : new Date();
  const duration = calculateDurationInHoursMinutes(startDate, endDate);

  const getURL = (session: any) => {
    let url;
    const inProgress = session.status.toLowerCase().includes("progress");

    if (window.innerWidth <= 768) {
      url = urls.scan.replace(":sid", store.id).replace(":id", session.id);
    } else if (inProgress) {
      url = urls.editSession
        .replace(":sid", store.id)
        .replace(":id", session.id);
    } else {
      url = urls.adjustment
        .replace(":sid", store.id)
        .replace(":id", session.id);
    }
    return url;
  };

  const onEditClick = () => {
    setSelectedSession(data);
    const url = getURL(data);
    navigate(`/${url}`);
  };

  return (
    <motion.div
      whileHover={
        // allowDownload
        //   ? {}:
        {
          scale: 0.99,
          boxShadow: "inset 0 1px 6px 2px rgba(222, 222, 222, 0.4)",
        }
      }
      className={`w-full h-fit bg-white rounded-md border cursor-pointer
      `}
    >
      <div className="px-4 pt-4 pb-2" onClick={onClick}>
        <div className="flex justify-between items-center">
          <Typography
            text={data.name}
            size="text-base"
            semibold
            m={{ b: "mb-1 2xl:mb-1.5" }}
          />
          {workflow && (
            <div className="flex justify-center items-center px-[6px] py-[2px] bg-[#88a8ff] text-white h-fit rounded-md">
              <Typography text={workflow} size="text-[10px]" semibold />
            </div>
          )}
        </div>
        <div className="w-full flex justify-between mb-1 2xl:mb-1.5">
          <Typography text="Category count:" fullWidth size="text-xs" />
          <Typography
            text={data.category_count}
            size="text-xs"
            className="w-fit text-right"
          />
        </div>
        <div className="w-full flex justify-between whitespace-nowrap mb-1 2xl:mb-1.5">
          <Typography text="Date & Time:" fullWidth size="text-xs" />
          <Typography
            text={date(data.created_at) + " " + time(data.created_at)}
            size="text-xs"
            className="w-fit text-right"
          />
        </div>
        <div className="w-full flex justify-between mb-1 2xl:mb-1.5">
          <Typography text="Time Used:" fullWidth size="text-xs" />
          <Typography
            text={duration}
            size="text-xs"
            className="w-52 text-right"
          />
        </div>
      </div>
      <div className="w-full flex justify-between items-center pl-4 border-t border-dashed">
        <div className="w-fit flex">
          <Typography text="Status:" size="text-xs" />
          <Typography
            text={formatSnakeToNormal(data.status.toLowerCase())}
            size="text-xs"
            className={`w-fit font-medium ml-1 ${
              isAdj ? "text-pot-blue" : "text-pot-maroon"
            }`}
          />
        </div>
        {allowDownload && (
          <IconButton type="tertiary">
            <DownloadIcon />
          </IconButton>
        )}
        {allowEdit && (
          <IconButton type="tertiary" onClick={() => onEditClick()}>
            <EditIcon />
          </IconButton>
        )}
      </div>
    </motion.div>
  );
};

export default SessionCard;
