import React from "react";
import { IconProp } from "./ArrowIcons";

const LockIcon = ({ color }: any) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66667 7.33334V4.66668C3.66667 3.78262 4.01786 2.93478 4.64298 2.30965C5.2681 1.68453 6.11595 1.33334 7 1.33334C7.88406 1.33334 8.7319 1.68453 9.35702 2.30965C9.98214 2.93478 10.3333 3.78262 10.3333 4.66668V7.33334M2.33333 7.33334H11.6667C12.403 7.33334 13 7.9303 13 8.66668V13.3333C13 14.0697 12.403 14.6667 11.6667 14.6667H2.33333C1.59695 14.6667 1 14.0697 1 13.3333V8.66668C1 7.9303 1.59695 7.33334 2.33333 7.33334Z"
        stroke={color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
