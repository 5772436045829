import moment from "moment";
import { getFromLocalStorage } from "./localStorage";

type catResponseType = {
  cat_name: string;
  dept_name: string;
  sub_dept_name: string;
};

export const sizeInMB = (value: number) => (value / (1024 * 1024)).toFixed(2);

export const capitalize = (value: string) =>
  value
    ? `${value?.[0].toUpperCase()}${value?.slice(1)?.toLowerCase()}`
    : value;

export const removeDuplicates = (value: string[]) => Array.from(new Set(value));

export const eachStringToDropDownObj = (values: string[]) =>
  values.map((each) => ({ label: each, value: each }));

export const toCatList = (values: catResponseType[]) => {
  const catNames = values.map((each) => each.cat_name);
  return removeDuplicates(catNames);
};

export const toDeptListObj = (data: catResponseType[], cats: string[]) => {
  let allObj = {};
  const all = cats.map((cat) => {
    const sameCatsList = data.filter((each) => each.cat_name === cat);
    const allSameCatDepts = sameCatsList.map((item) => item.dept_name);
    const sameCatDepts = removeDuplicates(allSameCatDepts);
    return {
      [cat]: eachStringToDropDownObj(sameCatDepts),
    };
  });
  all.forEach((each) => (allObj = { ...allObj, ...each }));

  console.log(allObj, "all objects");

  return allObj;
};

export const showConvertedDate = (value: any) => {
  if (!value) return "-";
  const date = new Date(value);
  // var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  return moment(date).format("MM/DD/YYYY  |  HH:mm");
};

export const date = (value: any) =>
  value ? moment(value).format("DD/MM/YYYY") : "-";

export const time = (value: any) =>
  value ? moment(value).format("hh:mm") : "-";

export const formatSnakeToNormal = (value: string) =>
  `${value.slice(0, 1).toUpperCase()}${value.slice(1)}`.replace("_", " ");

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const calculateDurationInHoursMinutes = (
  startDate: Date,
  endDate: Date
) => {
  const start: any = new Date(startDate);
  const end: any = new Date(endDate);

  // Calculate the difference in milliseconds
  const difference = Math.abs(end - start);

  // Convert milliseconds to hours and minutes
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const remainingHours = difference % (1000 * 60 * 60 * 24);
  const hours = Math.floor(remainingHours / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  const displayText =
    (days !== 0 ? days + "d " : "") +
    (hours !== 0 ? hours + "h " : "") +
    minutes +
    (hours !== 0 ? "m" : " minutes");

  return displayText;
};

export const getElapsedTime = () => {
  const lastLoggedIn: any = getFromLocalStorage("lastLoggedIn");
  if (!lastLoggedIn) return null;
  const start: any = new Date(lastLoggedIn);
  const end: any = new Date();

  // Calculate the difference in milliseconds
  const difference = Math.abs(end - start);
  const seconds = Math.floor(difference / 1000);

  return seconds;
};

export const reAuthenticate = () => {
  const elapsedSeconds = getElapsedTime();

  if (elapsedSeconds === null) {
    return true; // Simply return true if the elapsedSeconds is null
  }

  return elapsedSeconds > 300;
};

export const formatText = (text: string) => {
  const upperCasedText = text.slice(0, 1).toUpperCase() + text.slice(1);
  const formattedText = upperCasedText?.replace(/_/g, " ");
  return formattedText;
};
