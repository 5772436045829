import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { InventoryApi } from "../../api/inventory";
import {
  AccountInfoAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
} from "../../atom";
import TableControl from "../../components/TableComponents/TableControl";
import HeaderAndControl from "../../components/TableComponents/TableHeaderAndControl";
import TableLayout from "../../components/TableComponents/TableLayout";
import useFetchStorageLocation from "../../hooks/fetch/useFetchStorageLocation";
import useInventoryRecordColdef from "../../hooks/coldef/useInventoryRecordColdef";
import useInventoryRecordTableFetch from "../../hooks/fetch/useInventoryRecordTableFetch";
import useRedirectWithoutSession from "../../hooks/useRedirectWithoutSession";
import {
  eachStringToDropDownObj,
  removeDuplicates,
  toCatList,
} from "../../utils/helperFunctions";
import { titles } from "../../utils/titles";
import InfoModal from "./InfoModal";
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import { RightArrowIcon } from "../../assets/ArrowIcons";
import FinishBottomBar from "../../components/FinishBottomBar";

const InventoryRecord = () => {
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(100);
  const [sort, setSort] = useState("");
  // const [showSpecificInfo, setShowSpecificInfo] = useState<any>(null);
  const [catagoriesOptions, setCategoriesOption] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedSL, setSelectedSL] = useState<string>("");
  const [download, setDownload] = useState<any>(null);
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const workflow = session.workflow;
  const { data, totalCount, totalPages, callAgain, loading } =
    useInventoryRecordTableFetch({
      search: searchValue,
      category: selectedCategory,
      sl: selectedSL,
      sort,
      length,
      page,
      setPage,
      download: download,
      setDownload: setDownload,
    });
  const coldef = useInventoryRecordColdef({
    callAgain,
    loading,
    sort,
    setSort,
  });
  const slOptions = useFetchStorageLocation();
  useRedirectWithoutSession();
  const showFinishBottomBar =
    workflow === "REGISTER_51" && session?.status === "IN_PROGRESS";

  const fetchCategories = async () => {
    const { data: response } = await InventoryApi.categories(session.id);
    const data = toCatList(response.data);
    setCategoriesOption(eachStringToDropDownObj(data));
  };

  const navigate = useNavigate();
  useEffect(() => {
    const title =
      workflow === "REGISTER_51"
        ? titles.register51Records
        : titles.liveInventoryRecords;
    document.title = title;
    if (!session.id) {
      navigate("/");
      return;
    }
    fetchCategories();
  }, []);

  return (
    <>
      <div className="w-full flex flex-col justify-between">
        <HeaderAndControl
          withDebounce={true}
          heading={
            workflow === "REGISTER_51"
              ? "Register 51 Records"
              : "Physical Inventory Records"
          }
          search={searchValue}
          setSearch={setSearchValue}
          slOptions={slOptions}
          categoriesOptions={catagoriesOptions}
          setSelectedCategory={setSelectedCategory}
          setSelectedSL={setSelectedSL}
          selectedCategory={selectedCategory}
          selectedSL={selectedSL}
          setDownload={setDownload}
          callAgain={callAgain}
          isRecordList
        />
        <TableLayout
          smallLoader
          data={data}
          coldef={coldef}
          height={showFinishBottomBar ? "h-[64vh]" : "h-[70vh]"}
        />
        <TableControl
          length={length}
          setLength={setLength}
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
        />
      </div>
      {/* {showSpecificInfo && (<InfoModal data={showSpecificInfo} handleCancel={() => setShowSpecificInfo(null)} />)} */}
      {showFinishBottomBar && (
        <FinishBottomBar
          storeId={store?.id}
          sessionId={session?.id}
          role={role}
        />
      )}
    </>
  );
};

export default InventoryRecord;
