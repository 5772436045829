import React, { useRef, useState } from "react";
import { DownwardArrowIcon } from "../../assets/ArrowIcons";
import Typography from "../Typography";
import { motion, AnimatePresence } from "framer-motion";
import LinkDiv from "../LinkDiv";
import { useLocation } from "react-router";
import useClickOutside from "../../hooks/useClickOutside";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountInfoAtom,
  AllSessionsAtom,
  ChangeScanPageAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
  SessionSpecificAtom,
} from "../../atom";
import LockIcon from "../../assets/LockIcon";
import { SessionApi } from "../../api/session";

type SidebarNavItemPropType = {
  item: {
    icon?: (color: string) => React.ReactNode;
    title: string;
    subItems?: any[];
    permission?: string[];
    withSession?: boolean;
    // to?: ({sid, id}: {sid?: string, id?: string}) => string;
    to?: string;
    isActive?: (location?: string) => boolean | undefined | void | null;
    canChangeName?: boolean;
  };
};

const NavItem = ({ item }: SidebarNavItemPropType) => {
  const [sessionSpecificData, setSessionSpecificData] = useRecoilState(
    SessionSpecificAtom
  ) as any;
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [hovered, setHovered] = useState<boolean>(false);
  const [openNameChanger, setOpenNameChanger] = useState<boolean>(false);
  const [inputVal, setInputVal] = useState("");
  const accordionRef = useRef<HTMLDivElement | null>(null);
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const setChangeScanPage = useSetRecoilState(ChangeScanPageAtom);
  const [session, setSelectedSession] = useRecoilState(
    SelectedSessionAtom
  ) as any;
  const sessions = useRecoilValue(AllSessionsAtom) as any;
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  useClickOutside(accordionRef, () => {
    // setIsOpen(false);
    setInputVal("");
    setOpenNameChanger(false);
  });
  const location = useLocation();

  if (item.withSession && !session.id) {
    return <></>;
  }

  if (item.permission && !item.permission?.includes(role)) {
    return <></>;
  }

  const getSessionData = async (session: any) => {
    const { data: response } = await SessionApi.editGet(store.id, session.id);
    if (response.success) {
      const data = response.data;
      setSessionSpecificData(data);
    }
  };

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={accordionRef}
      className="relative"
    >
      {/* <LinkDiv to={item.to?.({sid: store.id, id: session.id})}> */}
      <LinkDiv to={item.to?.replace(":sid", store.id)}>
        <button
          className={`w-full z-[50] text-left ${
            item &&
            (item.isActive?.(location.pathname) ||
              item.subItems?.some((each: any) =>
                each?.isActive?.(location.pathname)
              ))
              ? "bg-pot-lightblue2"
              : hovered && "bg-pot-lightblue"
          } flex items-center pl-3 py-1 gap-x-2.5 rounded-md`}
        >
          {item.icon?.("black")}
          {openNameChanger ? (
            <input
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setOpenNameChanger(false);
                  setInputVal("");
                }
              }}
              className="w-full outline-none border py-1 rounded-md px-2 text-sm"
            />
          ) : (
            <button
              onClick={() =>
                item.canChangeName && setOpenNameChanger((prev) => !prev)
              }
              className="w-full text-left py-1"
            >
              <Typography
                text={item.withSession ? session.name : item.title}
                size="text-sm"
                fullWidth
              />
            </button>
          )}
          {item.subItems && (
            <button
              onClick={() => {
                setIsOpen((prev) => !prev);
                setInputVal("");
                setOpenNameChanger(false);
              }}
              className="px-3 self-stretch"
            >
              <DownwardArrowIcon color="black" />
            </button>
          )}
        </button>
      </LinkDiv>
      <AnimatePresence>
        {item.subItems && isOpen && (
          <motion.div
            initial={{ opacity: 0, marginTop: "-6%" }}
            animate={{ opacity: 1, marginTop: 0 }}
            exit={{
              opacity: 0,
              marginLeft: "-40%",
              transition: { duration: 0.23 },
            }}
            className="pl-8 pr-5 flex flex-col gap-y-2 pb-4 pt-2"
          >
            {item.subItems?.map((each: any, index: number) => {
              const condition1 = each.title === "Uncounted Items";
              const condition2 = role !== "ADMIN";
              const condition3 = sessionSpecificData?.lock_uncounted_list;
              const disabledItem = condition1 && condition2 && condition3;
              return (
                <LinkDiv
                  key={index}
                  to={
                    !disabledItem &&
                    each.to.replace(":sid", store.id).replace(":id", session.id)
                  }
                >
                  <button
                    className={`flex gap-2 items-center pl-2 rounded-md w-full ${
                      each?.isActive?.(location.pathname)
                        ? "bg-pot-lightblue2"
                        : disabledItem
                        ? "cursor-not-allowed"
                        : "hover:bg-pot-lightblue"
                    } text-sm text-left py-1`}
                    onClick={() => setChangeScanPage((prev) => prev + 1)}
                  >
                    {each.title} {disabledItem && <LockIcon color="#555" />}
                  </button>
                </LinkDiv>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {openNameChanger && (
          <div className="w-full min-h-[2rem] h-fit max-h-[20vh] absolute top-10 bg-white text-xs z-[52] px-4">
            {/* <input autoFocus className="w-full border outline-none py-1 px-2" /> */}
            <div
              onClick={() => {
                setInputVal("");
                setOpenNameChanger(false);
              }}
              className="mt-1 px-2 rounded-md shadow-md h-[17vh] overflow-auto flex flex-col gap-y-1"
            >
              {sessions
                .filter((eachSession: any) =>
                  eachSession.name.includes(inputVal)
                )
                .map((eachSession: any) => {
                  return (
                    <LinkDiv
                      to={item?.subItems?.[
                        eachSession.status === "ADJUSTMENT" ? 3 : 0
                      ]?.to
                        ?.replace(":sid", store.id)
                        .replace(":id", eachSession.id)}
                    >
                      <button
                        onClick={() => {
                          setSelectedSession(eachSession);
                          // getSessionData(eachSession);
                        }}
                        className="text-left pl-3 mb-3"
                      >
                        {eachSession.name}
                      </button>
                    </LinkDiv>
                  );
                })}
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavItem;
