import LocationIcon from "../../assets/LocationIcon";
import Dropdown from "../Dropdown";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  LoadingProgressAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
  StoresAtom,
} from "../../atom";
import { useNavigate, useParams } from "react-router";
import MediaSidebarControl from "./MediaSidebarControl";
import Typography from "../../components/Typography";
import { urls } from "../../utils/urls";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { delay } from "../../utils/helperFunctions";

const LocationTopbar = () => {
  const [selectedStore, setSelectedStore] = useRecoilState(
    SelectedStoreAtom
  ) as any;
  const store = useRecoilValue(StoresAtom) as any;
  const [session, setSession] = useRecoilState(SelectedSessionAtom) as any;
  const [progress, setProgress] = useRecoilState(LoadingProgressAtom);
  const navigate = useNavigate();
  const { sid } = useParams();

  const storesOption = store.map((each: any) => ({
    label: each.name,
    value: each,
  }));

  const checkProgress = async (progress: number) => {
    if (progress === 100) {
      await delay(300);
      setProgress(0);
    }
  };

  useEffect(() => {
    checkProgress(progress);
  }, [progress]);

  return (
    <>
      <div>
        <div className="sticky top-0 z-[40] flex gap-x-2 items-center bg-pot-grey4 text-xs py-1 px-2">
          <MediaSidebarControl />
          <Dropdown
            buttonText={`Store name: ${selectedStore.name}`}
            optionList={storesOption}
            buttonProps={{ type: "blank", leftIcon: <LocationIcon /> }}
            selectOption={(value) => {
              if (value.value.name !== selectedStore.name) {
                setSelectedStore(value.value);
                if (session.id) {
                  setSession({});
                }
                navigate("/");
              }
            }}
          />
        </div>
        <div className="w-full h-[3px] relative z-50">
          {progress ? (
            <motion.div
              className="h-[3px] bg-pot-darkermaroon rounded-r"
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
            ></motion.div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* See Create Session Only Desktop Veiw*/}

      {window.location.pathname ===
        "/" + urls.sessions.replace(":sid", sid || "") && (
        <div className="text-start text-xs block md:hidden bg-[#99999966] p-2 w-full ">
          <Typography
            text="Sessions can only be created from desktop"
            semibold
            m={{ l: "ml-[4vh]" }}
          />
        </div>
      )}
    </>
  );
};

export default LocationTopbar;
