import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { UserApi } from "../../api/user";
import PeopleIcon from "../../assets/PeopleIcon";
import UsersIcon from "../../assets/UsersIcon";
import { AccountInfoAtom, AppInfoAtom } from "../../atom";
import Button from "../../components/Button";
import CheckboxDropdown from "../../components/CheckboxDropdown";
import { SelectableDropdownValueType } from "../../components/Dropdown";
import InputField from "../../components/InputField";
import PhoneField from "../../components/PhoneField";
import RadioButton from "../../components/RadioButton";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import Typography from "../../components/Typography";
import { toastIt } from "../../utils/common";
import { capitalize } from "../../utils/helperFunctions";
import { CheckboxoptionList } from "../../utils/constants";
import { urls } from "../../utils/urls";
import { validatePhone } from "../../utils/validations";

type UserFormType = {
  editData: any;
};

const UserForm = ({ editData }: UserFormType) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [designation, setDesignation] = useState<
    SelectableDropdownValueType | undefined
  >(undefined);
  const [selectedStores, setSelectedStores] = useState<
    SelectableDropdownValueType[]
  >([]);
  const [errors, setErrors] = useState<any>({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const { roles } = useRecoilValue(AppInfoAtom) as any;
  const { role, stores } = useRecoilValue(AccountInfoAtom) as any;
  const filterFromId = roles?.findIndex((each: any) => each === role);
  const rolesSelectOptions = roles
    .slice(filterFromId)
    .map((each: any) => ({ label: capitalize(each), value: each }));
  const storesSelectOptions = stores?.map((each: any) => ({
    label: each.name,
    value: each.id,
  }));
  const navigate = useNavigate();

  const update = async () => {
    setSubmitLoading(true);
    const first_name = firstName;
    const last_name = lastName;
    const phone_no = phone;
    const role = designation?.value;
    const payload = {
      first_name,
      last_name,
      email,
      gender: gender.toUpperCase(),
      phone_no,
      role,
      is_active: editData.is_active ? true : false,
    } as any;
    if (role !== "ADMIN") {
      payload.stores = selectedStores.map((each) => each.value);
    } else {
      payload.stores = [];
    }
    const { data: response } = await UserApi.update(editData.id, payload);
    if (response.success) {
      toast.success("Successfully updated the user")
      navigate("/" + urls.manageUsers);
    } else {
      const err = toastIt(response.error, true);
      setErrors(err);
      console.log("errx", err);
      setSubmitLoading(false);
    }
  };

  const create = async () => {
    setSubmitLoading(true)
    const first_name = firstName;
    const last_name = lastName;
    const phone_no = phone;
    const role = designation?.value;
    const payload = {
      first_name,
      last_name,
      email,
      gender: gender.toUpperCase(),
      phone_no,
      role,
      // is_active: true
    } as any;
    if (role !== "ADMIN") {
      payload.stores = selectedStores.map((each) => each.value);
    } else {
      payload.stores = [];
    }
    const { data: response } = await UserApi.create(payload);
    if (response.success) {
      toast.success("Successfully created the user")
      navigate("/" + urls.manageUsers);
    } else {
      const err = toastIt(response.error, true);
      setErrors(err);
      setSubmitLoading(false)
    }
  };

  useEffect(() => {
    if (editData) {
      setFirstName(editData.first_name);
      setLastName(editData.last_name);
      setEmail(editData.email);
      setGender(editData.gender.toLowerCase());
      setPhone(editData.phone_no);
      setDesignation({
        label: capitalize(editData.role),
        value: editData.role,
      });
      const settableStores = editData.stores.map((each: any) => ({
        label: each.name,
        value: each.id,
      }));
      console.log(settableStores);
      setSelectedStores(settableStores);
    }
  }, [editData]);

  console.log(selectedStores, "store");

  return (
    <div className="w-[60%] h-full overflow-y-auto text-sm mb-5 flex flex-col gap-y-4 rounded-md bg-white border px-5">
      <div>
        <Typography
          text="Name"
          className="py-4 text-sm xl:text-[15px]"
          semibold
        />
        <div className="flex gap-x-4">
          <InputField
            value={firstName}
            placeholder="First name"
            onChange={(val) => setFirstName(val)}
            hint={errors.first_name}
          />
          <InputField
            value={lastName}
            placeholder="Last name"
            onChange={(val) => setLastName(val)}
            hint={errors.last_name}
          />
        </div>
      </div>
      <SingleSelectDropdown
        label="Designation/Role"
        boxText="Select a field"
        optionList={rolesSelectOptions}
        hint={errors.role}
        selectedValue={designation || { label: null, value: null }}
        onSelect={(value: any) => {
          setDesignation(value);
          // if (value.value === "ADMIN") {
          //   setSelectedStores([]);
          // }
        }}
      />
      {designation?.value !== "ADMIN" && (
        <CheckboxDropdown
          label="Stores assigned"
          boxText="Select a field"
          optionList={storesSelectOptions}
          selectedValues={selectedStores}
          onSelect={(value) => setSelectedStores(value || [])}
          hint={errors.store}
          // stores={stores}
          // setStores={setStores}
        />
      )}
      <div>
        <Typography
          text="Gender"
          className="py-4 text-sm xl:text-[15px]"
          semibold
        />
        <div className="flex gap-x-5">
          <RadioButton
            label="Male"
            selected={gender === "male"}
            onClick={() => setGender("male")}
          />
          <RadioButton
            label="Female"
            selected={gender === "female"}
            onClick={() => setGender("female")}
          />
        </div>
        {errors.gender &&
          (typeof errors.gender === "string" ? (
            <p className="px-1 pt-1 font-light text-xs text-pot-maroon">
              {errors.gender}
            </p>
          ) : errors.gender.length ? (
            errors.gender.length === 1 ? (
              <p className="px-1 pt-1 font-light text-xs text-pot-maroon">
                {errors.gender[0]}
              </p>
            ) : (
              <ul className="list-disc px-4">
                {errors.gender.map((each: any) => (
                  <li className="pt-1 font-light text-xs text-pot-maroon">
                    {each}
                  </li>
                ))}
              </ul>
            )
          ) : (
            ""
          ))}
      </div>
      <InputField
        hint={errors.email}
        value={email}
        onChange={(val) => setEmail(val)}
        label="Email Id"
        placeholder="Type your Email Id"
      />
      <PhoneField phone={phone} setPhone={setPhone} error={errors.phone_no} />
      <div className="h-full flex justify-center items-center py-5 pr-5">
        <Button
          loading={submitLoading}
          onClick={() => (editData ? update() : create())}
          leftIcon={<UsersIcon />}
          text={editData ? "Update User" : "Create User"}
        />
      </div>
    </div>
  );
};

export default UserForm;
