import React from "react";
import { IconProp } from "./ArrowIcons";

const StoreIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.33334C10.3137 5.33334 13 4.43791 13 3.33334C13 2.22877 10.3137 1.33334 7 1.33334C3.68629 1.33334 1 2.22877 1 3.33334C1 4.43791 3.68629 5.33334 7 5.33334Z"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 8C13 9.10667 10.3333 10 7 10C3.66667 10 1 9.10667 1 8"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 3.33334V12.6667C1 13.7733 3.66667 14.6667 7 14.6667C10.3333 14.6667 13 13.7733 13 12.6667V3.33334"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StoreIcon;
