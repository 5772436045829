import React from "react";
import { IconProp } from "./ArrowIcons";

const LocationIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="16"
      height="14"
      fill="none"
      stroke= {color || "inherit"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.3"
      viewBox="0 0 24 24"
    >
      <path d="M20 10c0 4.418-8 12-8 12s-8-7.582-8-12a8 8 0 1 1 16 0Z"></path>
      <path d="M12 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
    </svg>
  );
};

export default LocationIcon;
