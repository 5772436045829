import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AccountInfoAtom, StoresAtom, ChangeInStoreListAtom } from "../../atom";
import TableControl from "../../components/TableComponents/TableControl";
import TableHeaderTwo from "../../components/TableComponents/TableHeaderTwo";
import TableLayout from "../../components/TableComponents/TableLayout";
import useStoreFetch from "../../hooks/fetch/useStoreFetch";
import useStoreColdef from "../../hooks/coldef/useStoreColdef";
import { titles } from "../../utils/titles";
import { urls } from "../../utils/urls";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import TrashIcon from "../../assets/TrashIcon";
import CrossIcon from "../../assets/CrossIcon";
import { StoreApi } from "../../api/store";
import { toast } from "react-toastify";
import { toastIt } from "../../utils/common";

const ManageStore = () => {
  // const stores = useRecoilValue(StoresAtom)
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(10);
  const [search, setSearch] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState("");
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === "ADMIN";
  const isManager = role === "MANAGER";
  const isEmp = role === "EMPLOYEE";
  const setChangeStore = useSetRecoilState(ChangeInStoreListAtom)
  const coldef = useStoreColdef(setOpenDeleteModal);
  const { data, totalCount, totalPages, callAgain } = useStoreFetch({
    page,
    setPage,
    length,
    setLength,
    search,
    permission: isAdmin || isManager,
  });

  const destroy = async (id: string) => {
    const { data: response } = await StoreApi.destory(id)
    callAgain()
    if(response.success) {
      toast.success("Successfully deleted the store");
      setChangeStore(true)
    } else {
      toastIt(response.errors)
    }
  }

  useEffect(() => {
    document.title = titles.manageStore;
  }, []);

  if (!isAdmin && !isManager) {
    return (
      <div className="w-full h-full pb-[20vh] text-sm text-pot-textgrey2 flex justify-center items-center font-semibold">
        You are not allowed to view this page
      </div>
    );
  }

  return (
    <>
      <div className="h-full flex flex-col justify-between">
        <TableHeaderTwo
          buttonText="Create a store"
          buttonTo={urls.manageStoreCreate}
          heading="Manage Stores"
          search={search}
          hideButton={isEmp || isManager}
          setSearch={setSearch}
          withDebounce
        />

        <TableLayout data={data} coldef={coldef} />

        <TableControl
          page={page}
          setPage={setPage}
          length={length}
          setLength={setLength}
          totalCount={totalCount}
          totalPages={totalPages}
        />
      </div>
      {openDeleteModal && (
        <Modal handleCancel={() => setOpenDeleteModal("")}>
          <div className="px-14 py-6 text-sm flex flex-col items-center">
            <p className="font-semibold mb-2">Delete Store</p>
            <p>Are you sure you want to delete this Store?</p>
            <div className="w-full mt-8 flex gap-x-3 justify-center text-xs">
              <Button
                text="Delete"
                leftIcon={<TrashIcon />}
                onClick={() => {
                  destroy(openDeleteModal);
                  setOpenDeleteModal("");
                }}
              />
              <Button
                type="primary_outline"
                text="Cancel"
                leftIcon={<CrossIcon />}
                onClick={() => setOpenDeleteModal("")}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManageStore;
