import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  endOfCount,
  paginationLength,
  startOfCount,
} from "../../utils/tableHelpers";

type TableControlType = {
  page?: number;
  length?: number;
  totalPages?: number;
  totalCount?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  setLength?: Dispatch<SetStateAction<number>>;
  pageSizeOptions?: number[];
};

const TableControl = ({
  page = 1,
  setPage = () => {},
  length = 10,
  setLength = () => {},
  totalPages = 6,
  totalCount = 60,
  pageSizeOptions = [10, 20, 25, 50, 100, 200, 500],
}: TableControlType) => {
  const MAX_PAGINATION_COUNT = 5;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    // If total pages and max pagination count are same, then don't update the offset
    totalPages !== MAX_PAGINATION_COUNT &&
      setOffset(MAX_PAGINATION_COUNT * Math.floor(page / MAX_PAGINATION_COUNT));
  }, [page]);

  return (
    <div
      className={` w-full z-44 px-2 flex justify-between items-center bg-white text-xs pb-[3vh]`}
    >
      <div className="flex items-center gap-x-4 min-h-[2.5rem]">
        {!!totalCount && !!totalPages && (totalPages !== 1 || length > 10) && (
          <>
            <p>Show Entries</p>
            <select
              value={length}
              onChange={(e) => setLength(Number(e.target.value))}
              className="px-2 bg-white border outline-none active:border-pot-darkblue text-pot-darkblue rounded py-1"
            >
              {pageSizeOptions?.map((each) => (
                <option>{each}</option>
              ))}
            </select>
          </>
        )}
        {!!totalCount && (
          <p className="w-56 ml-4">
            Showing {startOfCount(length, page)} to{" "}
            {endOfCount(length, page, totalCount)} of {totalCount} Entries
          </p>
        )}
      </div>
      {!!totalPages && (totalPages !== 1 || length > 10) && (
        <div className="flex border-collapse">
          <motion.button
            whileHover={{ scale: 1.02 }}
            onClick={() => page > 1 && setPage((prev: number) => prev - 1)}
            className="hover:bg-pot-grey px-4 py-2 rounded-l-md border"
          >
            Previous
          </motion.button>
          {Array.from({
            length: paginationLength(totalPages, offset / MAX_PAGINATION_COUNT),
          }).map((_, id) => {
            const settableNumber = id + (offset ? offset : 1);

            return (
              <motion.button
                onClick={() => setPage(settableNumber)}
                whileHover={{ scale: 1.04 }}
                className={`border ${
                  page === settableNumber
                    ? "bg-pot-lightblue text-pot-darkblue"
                    : "hover:bg-pot-grey"
                } px-3 py-2`}
              >
                {settableNumber}
              </motion.button>
            );
          })}
          <motion.button
            onClick={() =>
              page < totalPages && setPage((prev: number) => prev + 1)
            }
            whileHover={{ scale: 1.02 }}
            className="hover:bg-pot-grey px-3 py-1 rounded-r-md border"
          >
            Next
          </motion.button>
        </div>
      )}
    </div>
  );
};

export default TableControl;
