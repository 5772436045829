import React from "react";
import { IconProp } from "./ArrowIcons";

const FlagIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.13317e-10 8.23507V14.6811C3.13317e-10 15.2181 0.45 15.6581 1 15.6581C1.55 15.6581 2 15.2181 2 14.6811V8.90308C3.17 8.56208 4.389 8.85808 5.768 9.19308C6.75 9.43108 7.804 9.68808 8.898 9.68808C9.77931 9.69747 10.6509 9.50344 11.445 9.12108C11.6139 9.03903 11.7562 8.91111 11.8558 8.75195C11.9554 8.59279 12.0081 8.40881 12.008 8.22108V1.46608C12.0086 1.29634 11.966 1.12924 11.884 0.980581C11.8021 0.831925 11.6836 0.706625 11.5397 0.616527C11.3959 0.526429 11.2314 0.474511 11.0619 0.465683C10.8924 0.456855 10.7234 0.491408 10.571 0.566076C9.226 1.22008 7.84 0.883076 6.24 0.495076C4.511 0.0750756 2.553 -0.399924 0.562 0.567076C0.393476 0.649184 0.251425 0.777009 0.152055 0.935967C0.0526841 1.09492 -4.69149e-06 1.27861 3.13317e-10 1.46608V8.23507Z"
        fill={color || "currentColor"}
      />
    </svg>
  );
};

export default FlagIcon;
