import React, { useMemo, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { StorageLocationApi } from '../api/storageLocation';
import { toastIt } from '../utils/common';
import {
  baseActionType,
  storageLocationReducer,
} from '../utils/stateFunctions';

const useStorageLocationForm = (store_id?: string) => {
  const [loading, setLoading] = useState(false);
  const [storageLocations, setStorageLocations] = useReducer(
    storageLocationReducer,
    []
  );
  const [error, setError] = useState<any>({});

  const add = async (loc: baseActionType) => {
    if (store_id) {
      setLoading(true);
      // const payload = { name: loc.payload.trim() };
      const payload = loc.payload;
      const { data: response } = await StorageLocationApi.create(
        store_id,
        payload
      );
      if (!response.success) {
        const errorObj = toastIt(response.error, true);
        setError({ ...errorObj });
        setLoading(false);
        return;
      }
      // loc.payload = { name: loc.payload.trim(), id: response.data.id };
      setLoading(false);
      setError({});
      toast.success('Successfully created the storage location');
    } else {
      // console.log('before - ', loc, '$$$');
      // loc = { ...loc, payload: loc.payload.trim() };
    }
    setLoading(false);
    setStorageLocations(loc);
  };

  const remove = async (loc: baseActionType) => {
    if (store_id) {
      const s_loc = storageLocations.find(
        (location) => location.name === loc.payload
      ) as any;

      const { data: response } = await StorageLocationApi.destroy(
        store_id,
        s_loc.id
      );
      if (!response.success) {
        toastIt(response.error);
        return;
      }
      toast.success('Successfully deleted the storage location');
    }
    setStorageLocations(loc);
  };

  const edit = async (loc: baseActionType) => {
    if (store_id) {
      // const payload = { name: loc.payload.trim() };
      const payload = loc.payload;
      // const s_l_id = storageLocations[loc.type as number].id;
      // console.log(storageLocations[loc.type as number], loc.type);
      const { data: response } = await StorageLocationApi.update(
        store_id,
        loc.type as string,
        payload
      );
      if (!response.success) {
        toastIt(response.error);
        return;
      }
      toast.success('Successfully updated the storage location');
    } else {
      loc = { ...loc, payload: loc.payload.trim() };
    }
    setStorageLocations(loc);
  };

  const localEdit = async (loc: baseActionType) => {
    setStorageLocations(loc);
  };

  const sLocationObj = useMemo(
    () => ({
      loading,
      add,
      remove,
      edit,
      localEdit,
      value: storageLocations,
      error,
      setError,
      setter: setStorageLocations,
    }),
    [storageLocations, error, loading]
  );

  return sLocationObj;
};

export default useStorageLocationForm;
