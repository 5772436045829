import { authenticatedAxiosInstance as axios } from "./axios";
const list = (params = { params: { length: 1000, page: 1 } }) =>
  axios.get("/users", params);
const create = (payload: any) => axios.post("/users", payload);
const show = (id: string) => axios.get(`/users/${id}`);
const update = (id: string, payload: any) =>
  axios.patch(`/users/${id}`, payload);
const destroy = (id: string) => axios.delete(`/userid}`);
const resetPassword = (id: string) =>
  axios.get(`/trigger-password-reset-request/user/${id}`);

export const UserApi = {
  list,
  create,
  show,
  update,
  destroy,
  resetPassword,
};
