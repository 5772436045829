import React from "react";

type RadioPropType = {
  label: string;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const RadioButton = ({
  label,
  selected,
  onClick,
  disabled = false,
}: RadioPropType) => {
  const size = "lg:w-4 lg:h-4 w-3 h-3";

  return (
    <label className="flex gap-x-2.5 items-center relative">
      <input
        checked={false}
        type="checkbox"
        onChange={disabled ? () => {} : onClick}
        className={`appearance-none ${size} rounded-full ${
          selected
            ? disabled
              ? "relative shadow-[inset_0_0_0_3px_#F0F0F0,0_0_0_1px_#DEDEDE] bg-pot-grey2 cursor-not-allowed"
              : `relative cursor-pointer shadow-[inset_0_0_0_3px_white,0_0_0_1px_#DEDEDE] bg-pot-darkmaroon hover:bg-pot-maroon hover:shadow-[inset_0_0_0_3px_#FFF9F9,0_0_0_1px_#932223] active:shadow-[inset_0_0_0_3px_#FFEAEA,0_0_0_1px_#932223] active:bg-pot-darkermaroon`
            : disabled
            ? "cursor-not-allowed shadow-[0_0_0_1px_#DEDEDE]"
            : "shadow-[0_0_0_1px_#DEDEDE] cursor-pointer hover:shadow-[0_0_0_1px_#932223] hover:bg-pot-lightmaroon active:bg-pot-lightmaroon2"
        }`}
      />
      <span>{label}</span>
    </label>
  );
};

export default RadioButton;
