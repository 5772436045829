import React, {
  Dispatch,
  Reducer,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from "react";
import PlusIcon from "../../assets/PlusIcon";
import TrashIcon from "../../assets/TrashIcon";
import Button from "../../components/Button";
import CheckboxDropdown from "../../components/CheckboxDropdown";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import Typography from "../../components/Typography";
import { SelectableDropdownValueType } from "../../components/Dropdown";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import { CategoryListAtom } from "../../atom";
import { selCatActionTypeEnum } from "../../types/Category.type";
import { categoryReducer } from "../../utils/stateFunctions";
import {
  eachStringToDropDownObj,
  removeDuplicates,
} from "../../utils/helperFunctions";
import { joiner } from "../../utils/common";
import useCategoryOrder from "../../hooks/useCategoryOrder";
import SessionUserAccess from "./SessionUserAccess";

type RightFormProp = {
  userStorageLoc: any[];
  setUserStorageLoc: Dispatch<SetStateAction<any>>;
  showableCatList: any[];
  setShowableCatList: Dispatch<SetStateAction<any>>;
  setUserAccess: Dispatch<SetStateAction<any>>;
  disabledOverall: boolean;
  categoryDepartmentData?: any[];
};

const RightForm = ({
  userStorageLoc,
  setUserStorageLoc,
  showableCatList,
  setShowableCatList,
  setUserAccess,
  disabledOverall,
  categoryDepartmentData,
}: RightFormProp) => {
  const [state, dispatch] = useReducer(categoryReducer, {
    disabled: 2,
    cat: { label: "", value: "" },
  });
  const categoryOptions = useRecoilValue(CategoryListAtom);
  const [orderList, setOrderList] = useState<string[]>([]);
  const [subDeptsOptions, setSubDeptsOptions] = useState<
    SelectableDropdownValueType[]
  >([]);
  const showableDeptList = useCategoryOrder({
    order: orderList,
    list: showableCatList,
  });
  const add = () => {
    let { cat, dept, subDepts } = state;
    cat = cat.value;
    dept = dept.value;
    const settable = subDepts.map(
      (each: any) => `${cat}${joiner}${dept}${joiner}${each.value}`
    );
    setShowableCatList(Array.from(new Set([...showableCatList, ...settable])));
    const newOrderList = orderList.filter((each) => each !== cat);
    setOrderList([cat, ...newOrderList]);
    dispatch({ type: selCatActionTypeEnum.clear });
  };

  const destroy = (value: string, cat: string) => {
    const newCatList = showableCatList.filter((item) => item !== value);
    const hasCat = newCatList.find((each) => {
      return each.split(joiner)[0] === cat;
    });
    setShowableCatList(newCatList);
    if (!hasCat) {
      const filteredOrderList = orderList.filter((item) => item !== cat);
      setOrderList(filteredOrderList);
    } else {
      setOrderList([...orderList]);
    }
  };

  useEffect(() => {
    if (state.disabled === 0 && categoryOptions[2]) {
      const data = categoryOptions[2] as any[];
      const subDeptObjs = data.filter(
        (each) =>
          each.cat_name === state.cat?.value &&
          each.dept_name === state.dept?.value
      );
      const subDeptVals = removeDuplicates(
        subDeptObjs.map((each) => each.sub_dept_name)
      );
      setSubDeptsOptions(eachStringToDropDownObj(subDeptVals));
    }
  }, [state]);

  return (
    <motion.div
      initial={{ marginRight: "-100%" }}
      animate={{ marginRight: 0 }}
      transition={{ duration: 0.6 }}
      className="w-full relative stroke-white flex flex-col h-full overflow-y-auto gap-y-[2vh] divide-y-2 no-scrollbar"
    >
      <div className="max-h-max px-[2vh] py-[2vh]">
        <Typography
          text="Categories"
          className="pb-2 text-sm xl:text-[16px]"
          semibold
        />
        <div className="flex gap-x-4 text-sm mb-[2vh] items-end">
          <SingleSelectDropdown
            selectedValue={state.cat}
            onSelect={(value?: SelectableDropdownValueType) =>
              dispatch({
                type: selCatActionTypeEnum.setCat,
                payload: value || { label: "", value: "" },
              })
            }
            label="Category"
            boxText="Select a category"
            disabled={disabledOverall}
            optionList={categoryOptions?.[0]}
            greyOut={disabledOverall}
          />
          <SingleSelectDropdown
            selectedValue={state.dept}
            onSelect={(value?: SelectableDropdownValueType) => {
              dispatch({
                type: selCatActionTypeEnum.setDept,
                payload: value || { label: "", value: "" },
              });
            }}
            label="Department"
            boxText="Select a department"
            optionList={categoryOptions?.[1]?.[state.cat?.value]}
            disabled={state?.disabled === 2}
            greyOut={disabledOverall}
          />
          <CheckboxDropdown
            selectedValues={state.subDepts}
            onSelect={(value = [] as SelectableDropdownValueType[]) => {
              dispatch({
                type: selCatActionTypeEnum.setSubDepts,
                payload: value,
              });
            }}
            label="Sub-department"
            boxText="Select a department"
            optionList={subDeptsOptions}
            disabled={(state.disabled && state?.disabled >= 1) || false}
            greyOut={disabledOverall}
            selectedBoxTextType="number_only"
          />
          <div className="h-fit">
            {/* (state && ((state.disabled) > 0) && (state.subDepts?.length === 0)) */}
            <Button
              onClick={add}
              disabled={
                disabledOverall ||
                (state.disabled ? true : state?.subDepts?.length === 0)
              }
              leftIcon={<PlusIcon />}
              small
              text="Add"
            />
          </div>
        </div>
        {orderList?.length ? (
          orderList.map((cat, catId) => (
            <div className="w-full my-2 flex text-sm px-4" key={catId}>
              <div className="w-[50%] font-semibold">{cat}</div>
              <div className="w-full flex flex-col gap-y-3">
                {showableDeptList[catId] &&
                  Object.keys(showableDeptList[catId]).map((dept, depId) => (
                    <div className="w-full flex justify-between" key={depId}>
                      <p className="w-[80%]">{dept}</p>
                      <div className="w-full flex flex-col gap-y-1">
                        {(Object.values(showableDeptList[catId]) as any[])[
                          depId
                        ].map((subDep: string) => (
                          <div
                            className="w-full flex justify-between"
                            key={subDep}
                          >
                            {subDep}
                            <button
                              onClick={() =>
                                destroy(
                                  `${cat}${joiner}${dept}${joiner}${subDep}`,
                                  cat
                                )
                              }
                              className="text-black hover:text-red-400"
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div
            className={`w-full h-[36vh] font-semibold text-lg text-pot-black/50 flex justify-center items-center`}
          >
            No category selected
          </div>
        )}
      </div>
      <div className="h-[35%]">
        <SessionUserAccess
          setUserAccess={setUserAccess}
          userStorageLoc={userStorageLoc}
          setUserStorageLoc={setUserStorageLoc}
          showableCatList={showableCatList}
        />
      </div>
    </motion.div>
  );
};

export default RightForm;
