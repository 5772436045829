import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import EditIcon from "../../assets/EditIcon";
import MenuIcon from "../../assets/MenuIcon";
import TrashIcon from "../../assets/TrashIcon";
import { AccountInfoAtom } from "../../atom";
import IconButton from "../../components/IconButton";
import { urls } from "../../utils/urls";

const useStoreColdef = (setDeleteId: Dispatch<SetStateAction<string>>) => {
  const navigate = useNavigate();
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === "ADMIN";
  // const isAdmin = true
  const isManager = role === "MANAGER";
  const isEmp = role === "EMPLOYEE";

  const coldef = [
    {
      header: "Store",
      accessorKey: "name",
      size: 300,
    },
    {
      header: "Address",
      accessorKey: "address",
      size: 400,
    },
    {
      header: "Storage Location",
      accessorKey: "no_of_storage_location",
      size: 15,
    },
  ] as any;

  if (isAdmin || isManager) {
    coldef.push({
      header: "Action",
      cell: ({ row }: { row: any }) => {
        if (row.original.noData) {
          return <div className="min-h-[3vh]"></div>;
        }
        return (
          <div className="flex gap-x-3 items-center">
            <IconButton
              tooltip={isManager ? "View" : "Edit"}
              onClick={() =>
                navigate(
                  "/" + urls.manageStoreEdit.replace(":id", row.original.id)
                )
              }
              type="tertiary"
            >
              <EditIcon />
            </IconButton>
            {isAdmin && (
              <IconButton
                tooltip={"Permanently delete store and related data"}
                onClick={() => setDeleteId(row.original.id)}
                type="tertiary"
              >
                <TrashIcon />
              </IconButton>
            )}
          </div>
        );
      },
      size: 150,
    });
  }

  return coldef;
};

export default useStoreColdef;
