import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import CrossIcon from "../../assets/CrossIcon";
import IconButton from "../../components/IconButton";
import Modal from "../../components/Modal";
import Typography from "../../components/Typography";
import { formatText } from "../../utils/helperFunctions";

type NoteModalType = {
  data: any;
  type: 0 | 1 | 2;
};

const NoteModal = ({ data, type }: NoteModalType) => {
  const containerRef = useRef<any>(null);

  return (
    <div
      ref={containerRef}
      className={`absolute z-[100] right-2 ${
        type ? (type === 2 ? "bottom-12" : "") : "hidden"
      } border-2 pt-2 border-pot-grey2 bg-white rounded-lg `}
    >
      <div className="w-[250px] max-w-[250px] text-sm">
        <div className="w-full flex flex-col gap-2 justify-between  pb-2 items-start">
          {data?.note_type && (
            <Typography
              text={`${formatText(data?.note_type)}`}
              bold
              className="px-3"
              fullWidth
            />
          )}
          {data?.note && (
            <Typography
              text={`${formatText(data?.note)}`}
              className="whitespace-normal break-words px-3"
              fullWidth
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NoteModal;
