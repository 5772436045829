import { authenticatedAxiosInstance as axios } from "../../api/axios";
// import axios from "axios";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import CheckboxDropdown from "../../components/CheckboxDropdown";
import DropZone from "../../components/DropZone";
import Typography from "../../components/Typography";
import { getFromLocalStorage } from "../../utils/localStorage";
import { useRecoilValue } from "recoil";
import { AppInfoAtom, SelectedStoreAtom } from "../../atom";
import { BASE_URL } from "../../api/axios";
import { motion } from "framer-motion";
import InlineMessage from "../../components/InlineMessage";
import RetryIcon from "../../assets/RetryIcon";
import { SessionApi } from "../../api/session";
import { toastIt } from "../../utils/common";
import ToolTip from "../../components/ToolTip";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import { SelectableDropdownValueType } from "../../components/Dropdown";

type LeftFormPropType = {
  loading: boolean;
  showWorfLow?: string;
  setShowWorkflow?: React.Dispatch<React.SetStateAction<any>>;
  setUploadId: Dispatch<SetStateAction<string>>;
  uploadId: string;
  canStart: boolean;
  title?: string;
  selectedWorkflow?: any;
  onSubmit: () => Promise<void>;
  disable?: boolean;
};

const SessionLeftForm = ({
  loading,
  showWorfLow,
  setShowWorkflow,
  setUploadId,
  uploadId,
  canStart,
  title = "New Session",
  selectedWorkflow,
  onSubmit,
  disable = false,
}: LeftFormPropType) => {
  const appInfo: any = useRecoilValue(AppInfoAtom);
  // const CheckboxoptionList = [
  //   "wine",
  //   "Soda",
  //   "Champagne",
  //   "French Wine",
  //   "Italian Wine",
  //   "German Wine",
  //   "Champagne",
  //   "French Wine",
  //   "Italian Wine",
  //   "German Wine",
  // ].map((each, index) => ({
  //   label: each,
  //   value: index,
  // }));
  const sessionWorkflows = appInfo?.session_workflows || [];

  // WORK FLOW OPTIONS
  const WorkFlowOptions = sessionWorkflows.map((workflow: any, index: any) => ({
    label: workflow.replace(/_/g, " "),
    value: workflow.replace(/\s+/g, "_"), // Replace spaces with underscores
  }));

  const [fetchLoading, setFetchLoading] = useState(false);
  const [error, setError] = useState("");
  const [buttonHovered, setButtonHovered] = useState(false);
  const [selectedSl, setSelectedSl] = useState<SelectableDropdownValueType>({
    label: "",
    value: "",
  });
  useEffect(() => {
    setSelectedSl({
      label: showWorfLow?.replace(/_/g, " "),
      value: showWorfLow,
    });
  }, [showWorfLow]);

  const store = useRecoilValue(SelectedStoreAtom) as any;

  const uploadFile = async (
    accepted: any,
    setUploadProgress?: Dispatch<SetStateAction<number>>
  ) => {
    setError("");
    const formData = new FormData();
    formData.append("file", accepted, accepted.name);
    setUploadProgress?.(50);
    const { data: response } = await axios.post(
      `/store/${store.id}/pos-data/upload`,
      formData,
      {
        baseURL: BASE_URL,
        headers: {
          // Authorization: `${getFromLocalStorage("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.success) {
      setUploadProgress?.(100);
      setUploadId(response.data.pos_import_id);
    } else {
      const errors = response.error;
      const errorArr = Object.keys(errors.messages).map((each: any) => {
        if (
          errors.messages[each]
            .join(", ")
            .toLowerCase()
            .includes("invalid token")
        ) {
          return "";
        }
        return errors.messages[each].join(", ");
      });
      setError(errorArr.join("; "));
      setUploadProgress?.(0);
    }
  };

  const fetchFromDrive = async () => {
    setError("");
    setFetchLoading(true);
    const { data: response } = await SessionApi.getImportId(store.id);
    if (response.success) {
      setUploadId(response.data.pos_import_id);
      setFetchLoading(false);
      return;
    } else {
      const errors = response.error;
      const errorArr = Object.keys(errors.messages).map((each: any) => {
        let err =
          typeof errors.messages[each] === "string"
            ? errors.messages[each]
            : errors.messages[each].join(", ");
        if (err.toLowerCase().includes("invalid token")) {
          return "";
        }
        if (typeof errors.messages[each] === "string") {
          return errors.messages[each];
        }
        return errors.messages[each].join(", ");
      });
      setError(errorArr.join("; "));
      setFetchLoading(false);
    }
  };

  return (
    <div
      className={`${"w-[50%] border-r"}  h-full flex flex-col justify-between p-[2vh] gap-4`}
    >
      <div className="flex flex-col gap-y-[2vh] text-xs">
        <Typography
          text={title}
          className="pb-2 text-sm xl:text-[16px]"
          semibold
        />
        <div className={`${disable && "opacity-40 pointer-events-none"}`}>
          <SingleSelectDropdown
            selectedValue={selectedSl}
            onSelect={(value) => {
              setSelectedSl(value || { label: "", value: "" });
              if (setShowWorkflow) {
                setShowWorkflow(value?.value);
              }
            }}
            boxText="Select a workflow"
            label="Workflow"
            optionList={WorkFlowOptions}
            height="h-6"
            // hint={errors.workflow}
          />
        </div>
      </div>
      <div
        className={`flex h-full flex-col gap-y-[2vh] text-xs  ${
          (uploadId || disable) && "opacity-40 pointer-events-none"
        }`}
      >
        <DropZone
          onUpload={uploadFile}
          label="Upload POS Data"
          className="text-sm h-[20vh]"
        />

        <p className="ml-2 mt-4">OR</p>

        <Button
          onClick={fetchFromDrive}
          text="Fetch POS data from drive"
          type="primary_outline"
        />
        {fetchLoading && (
          <InlineMessage
            leftIcon={
              <motion.div
                animate={{ rotate: -360 }}
                transition={{ ease: "linear", duration: 1, repeat: Infinity }}
                className="stroke-pot-maroon w-fit flex items-center"
              >
                <RetryIcon />
              </motion.div>
            }
            message="fetching data"
          />
        )}
        {error && (
          <Typography
            text={error}
            m={{ t: "mt-1", l: "ml-1" }}
            className="text-pot-maroon"
          />
        )}
        {/* <CheckboxDropdown label='Select a category' boxText='Select a category' hint="Hint goes here" optionList={CheckboxoptionList}  />
            <CheckboxDropdown label='Select a department' boxText='Select a department' hint="Hint goes here" optionList={CheckboxoptionList}  /> */}
      </div>
      <div
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
        className="flex justify-center text-sm pb-[3vh] relative"
      >
        <Button
          loading={loading}
          disabled={disable ? !disable : !canStart}
          onClick={onSubmit}
          text={title === "Edit Session" ? title : "Create Session"}
        />
        {disable
          ? !disable
          : !canStart &&
            buttonHovered && (
              <ToolTip
                message={"Please select any category to proceed further"}
              />
            )}
      </div>
    </div>
  );
};

export default SessionLeftForm;
