import { useNavigate } from "react-router";
import SignoutIcon from "../../assets/SignoutIcon";
import IconButton from "../IconButton";
import Typography from "../Typography";

const SidebarFooter = ({ setIsLoggedIn }: any) => {
  const navigate = useNavigate();
  return (
    <div className="h-[12vh] flex flex-col justify-center items-center">
      <IconButton
        onClick={() => {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
          localStorage.removeItem("lastLoggedIn");
          navigate("/login");
        }}
      >
        <SignoutIcon />
      </IconButton>
      <Typography size="text-xs" m={{ t: "mt-2" }} text="Sign Out" />
    </div>
  );
};

export default SidebarFooter;
