import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { OpenQuaggaAtom } from '../atom'

const useQuaggaRefresh = () => {
    const [refresh, setRefresh] = useState(false)
    const [openCamera, setOpenCamera] = useRecoilState(OpenQuaggaAtom)

    useEffect(() => {
        if(refresh) {
          setOpenCamera(false)
        }
      }, [refresh])
    
    
      useEffect(() => {
        if(!openCamera && refresh) {
          setOpenCamera(true)
          setRefresh(false)
        }
      }, [openCamera])

  return (setRefresh)
}

export default useQuaggaRefresh