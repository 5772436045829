import React, { useState } from "react";
import "./toggleSwitch.css";
import { ClosedEyesIcon, OpenEyesIcon } from "../../assets/EyesIcons";
import { reAuthenticate } from "../../utils/helperFunctions";
import { SecuredAuthAtom } from "../../atom";
import { useRecoilState } from "recoil";
import TickIcon from "../../assets/TickIcon";
import CrossIcon from "../../assets/CrossIcon";
import ToolTip from "../ToolTip";

const ToggleSwitch = ({
  isChecked,
  setIsChecked,
  leftText,
  rightText,
  handleToggle,
  disabled = false,
  type = "eye",
  tooltip,
  secured,
}: any) => {
  const [openSecuredAuthModal, setOpenSecuredAuthModal] =
    useRecoilState(SecuredAuthAtom);
  const [mouseEnter, setMouseEnter] = useState(false);

  const disabledStyles = "pointer-events-none";
  const enabledTextStyles = isChecked === true ? "opactiy-100" : "opacity-50";
  const disabledTextStyles = isChecked === false ? "opactiy-100" : "opacity-50";

  // To check of the toggle switch is secured or not and then call the handleToggle function
  const toggle = () => {
    if (secured) {
      reAuthenticate() ? setOpenSecuredAuthModal(true) : handleToggle();
    } else {
      handleToggle();
    }
  };

  return (
    <div
      className={`${
        disabled && disabledStyles
      } flex items-center gap-2 relative`}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
    >
      <div className="">
        <div id="toggle-button" className="flex gap-2">
          <p className={`font-bold ${disabledTextStyles}`}>{leftText}</p>
          <label className="switch">
            {isChecked && (
              <div
                className="absolute z-10 top-[2px] left-[3px]  cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  toggle();
                }}
              >
                {type === "eye" ? (
                  <OpenEyesIcon color="white" width="12" />
                ) : (
                  <div className="absolute top-[2px] left-[2px]">
                    <TickIcon color="white" width="8" />
                  </div>
                )}
              </div>
            )}
            <input
              id="toggle-switch"
              type="checkbox"
              onChange={toggle}
              checked={isChecked ? true : false}
            />
            <span className="slider round"></span>
            {!isChecked && (
              <div
                className="absolute z-10 top-[1.5px] left-[16px] cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  toggle();
                }}
              >
                {type === "eye" ? (
                  <ClosedEyesIcon color="white" width="12" />
                ) : (
                  <div className="absolute top-[4px] left-[5px]">
                    <CrossIcon color="white" width="6" />
                  </div>
                )}
              </div>
            )}
          </label>
          <p className={`font-bold ${enabledTextStyles}`}>{rightText}</p>
        </div>
      </div>
      {mouseEnter && tooltip && (
        <ToolTip message={tooltip} bottom right variant="secondary" />
      )}
    </div>
  );
};

export default ToggleSwitch;
