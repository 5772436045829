import React, { useEffect, useState } from "react";
import { joiner } from "../utils/common";

type CatOrderPropType = {
  order: any[];
  list: any[];
};

const splitList = (list: any[]) => {
    return list.map(each => each.split(joiner))
}

const useCategoryOrder = ({ order, list }: CatOrderPropType) => {
  const [orderedDepts, setOrderedDepts] = useState<any[]>([]);

  useEffect(() => {
    const splitted = splitList(list)
    const orderedDeptList = order.map((cat) => {
        const obj: any = {}
        const filteredList = splitted.filter(item => item[0] === cat)
        filteredList.forEach((item: string[]) => {
            if(obj[item[1]]){
                obj[item[1]] = [...obj[item[1]], item[2]]
            } else {
                obj[item[1]] = [item[2]]
            }
        })

        return({...obj})
    })
    setOrderedDepts(orderedDeptList)
  }, [order])

  return orderedDepts;
};

export default useCategoryOrder;
