import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import { TableLoadingAtom } from "../../atom";

type TableLayoutPropType = {
  data: any;
  coldef: any;
  alternateColor?: boolean;
  smallLoader?: boolean;
};

const TablePopup = ({
  data,
  coldef,
  alternateColor = false,
  smallLoader = false,
}: TableLayoutPropType) => {
  const screenWidth = window.innerWidth;
  const fetchLoading = useRecoilValue(TableLoadingAtom);

  const table = useReactTable({
    data: data,
    columns: coldef,
    getCoreRowModel: getCoreRowModel(),
  });

  console.log(table.getRowModel());

  return (
    <section className="relative w-full overflow-auto">
      <div className="whitespace-nowrap w-full rounded-lg overflow-auto ">
        <table
          {...{
            style:
              table.getTotalSize() > screenWidth - 350
                ? {
                    width: table.getTotalSize(),
                  }
                : {},
          }}
          className="w-full rounded-lg overflow-auto"
        >
          <thead className="w-full sticky top-0 z-30">
            {table.getHeaderGroups().map((headerGroup: any) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <th
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                      style: {
                        width: header.getSize(),
                      },
                    }}
                    className={` bg-pot-darkermaroon text-left text-xs font-normal text-white ${
                      header.column.getIsPinned()
                        ? "sticky left-0 z-[40] border-x border-pot-grey-2 bg-pot-back-grey"
                        : "relative"
                    }`}
                  >
                    <p className={`w-full h-full ${"p-3"}`}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {fetchLoading || !data.length ? (
            <tbody>
              {table.getRowModel().rows.map((row: any) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell: any) => (
                    <td
                      {...{
                        key: cell.id,
                        style: {
                          width: cell.column.getSize(),
                        },
                      }}
                      className={`border`}
                    >
                      <div className=" w-full px-5 py-3 flex justify-center">
                        <motion.div
                          animate={{
                            background:
                              "radial-gradient( circle farthest-corner at 0% 0%,  rgba(241, 240, 236, 1) 0%, white 60%, rgba(241, 240, 236, 1) 90% )",
                            backgroundSize: "400%",
                            backgroundPosition: ["0 0", "400% 400%"],
                          }}
                          transition={{
                            duration: 5,
                            delay: 0,
                            repeatDelay: 0,
                            repeat: Infinity,
                          }}
                          className={`  ${
                            smallLoader ? "min-h-[1.5vh]" : "min-h-[3vh]"
                          } h-full w-full`}
                        >
                          {/* This is loading part */}
                        </motion.div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <motion.tbody
              initial={{ opacity: 0 }}
              transition={{ duration: 0.8 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {table.getRowModel().rows.map((row: any) => (
                <tr
                  key={row.id}
                  className={
                    alternateColor ? "odd:bg-pot-base even:bg-pot-base" : ""
                  }
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <td
                      {...{
                        key: cell.id,
                        style: {
                          width: cell.column.getSize(),
                        },
                      }}
                      className={`p-3 bg-inherit w-full whitespace-nowrap space-y-4 text-xs shadow-[inset_0px_-0.5px_0_0.5px_#DEDEDE] ${
                        cell.column.getIsPinned()
                          ? "sticky left-0 z-[20]"
                          : "relative"
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </motion.tbody>
          )}
        </table>
      </div>
      {!data.length && !fetchLoading && (
        <div
          className={`w-full ${"h-[20vh]"} absolute top-0 font-semibold text-lg text-pot-black/50 flex justify-center items-center`}
        >
          There are currently no items to display
        </div>
      )}
    </section>
  );
};

export default TablePopup;
