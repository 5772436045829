import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { debounce } from "lodash";
import ToolTip from "./ToolTip";

type InputFieldType = {
  label?: string;
  value?: string;
  placeholder?: string;
  enterToBlur?: boolean;
  hint?: string | string[];
  inputType?: string;
  onChange: (
    text: string,
    inputSetter: Dispatch<SetStateAction<string>>
  ) => void;
  withDebounce?: boolean;
  leftIcon?: React.ReactNode | null;
  leftIconClickHandler?: (() => void) | null;
  rightIcon?: React.ReactNode | null;
  rightIconClickHandler?: (() => void) | null;
  margins?: string;
  className?: string;
  validate?: (value: string) => string;
  // allowOutsideSet?: boolean
  setOnBlur?: Dispatch<SetStateAction<boolean>>;
  doNotChangeInput?: boolean;
  numericKeypad?: boolean;
  tooltip?: string;
  disabled?: boolean;
  data?: [];
  setData?: Dispatch<any>;
};

const InputField = ({
  numericKeypad = false,
  label = "",
  value = "",
  placeholder = "",
  onChange,
  inputType = "text",
  hint = [],
  enterToBlur = true,
  setOnBlur = (value) => {},
  withDebounce = false,
  leftIcon = null,
  leftIconClickHandler = null,
  rightIcon = null,
  rightIconClickHandler = null,
  margins = "",
  className = "",
  validate = (val) => val,
  // allowOutsideSet = false,
  doNotChangeInput = false,
  tooltip = "",
  disabled = false,
  data = [],
  setData = () => [],
}: InputFieldType) => {
  const [inputVal, setInputVal] = useState<string>("");
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // if(!withDebounce) return
    if (inputVal !== value) {
      setInputVal(value);
    }
  }, [value]);

  const optimisedCall = useCallback(
    debounce((inputVal) => onChange(inputVal, (text) => {}), 400),
    []
  );

  const searchFunctionality = (input: string) => {
    var tokens = input
      .toLowerCase()
      .split(" ")
      .filter(function (token) {
        return token.trim() !== "";
      });

    var regex = new RegExp(tokens.join("|"), "gim");
    var filteredList = data.filter((item: any) => {
      return item.item_name.match(regex);
    });
    setData(filteredList);
  };

  useEffect(() => {
    if (withDebounce) {
      optimisedCall(inputVal);
    }
  }, [inputVal]);

  useEffect(() => {
    if (focusInput) {
      setOnBlur(false);
    }
  }, [focusInput]);

  return (
    <div className={`w-full ${margins} ${className}`}>
      {label && <p className="font-semibold text-[15px] pb-2 px-1">{label}</p>}
      <div
        onClick={() => {
          if (enterToBlur && !focusInput) {
            inputRef.current?.focus();
            window.requestAnimationFrame(() => {
              if (inputRef.current) {
                inputRef.current.selectionStart = inputVal.length;
                inputRef.current.selectionEnd = inputVal.length;
              }
            });
          }
        }}
        className={`w-full flex relative justify-between items-center border rounded-md ${
          focusInput && "border-pot-darkmaroon"
        } stroke-black ease-in-out transition px-2`}
      >
        {leftIcon && (
          <div
            onClick={() => leftIconClickHandler && leftIconClickHandler()}
            className={`px-2 ${
              leftIconClickHandler ? "cursor-pointer" : "cursor-default"
            } py-1.5`}
          >
            {leftIcon}
          </div>
        )}
        <input
          inputMode={numericKeypad ? "tel" : "text"}
          ref={inputRef}
          placeholder={placeholder}
          disabled={disabled}
          type={inputType}
          onFocus={() => setFocusInput(true)}
          onKeyDown={(e) =>
            focusInput && e.key === "Enter" && inputRef.current?.blur()
          }
          onBlur={() => {
            if (value) {
              setOnBlur(true);
            }
            setFocusInput(false);
          }}
          value={inputVal}
          onChange={(e) => {
            // setInputVal(e.target.value);
            if (e.target.value === " ") return;
            if (!withDebounce) {
              if (doNotChangeInput) return;
              if (inputRef.current) {
                inputRef.current.value = validate(e.target.value);
              }
              setInputVal(validate(e.target.value));
              onChange(validate(e.target.value), setInputVal);
            } else {
              setInputVal(validate(e.target.value));
            }
            searchFunctionality(e.target.value);
          }}
          className="w-full rounded-md text-[13px] outline-none py-1.5 px-2"
        />
        {rightIcon && (
          <div
            onClick={() => rightIconClickHandler && rightIconClickHandler()}
            className={`px-2 ${
              rightIconClickHandler ? "cursor-pointer" : "cursor-default"
            } py-1.5`}
          >
            {rightIcon}
          </div>
        )}
        {tooltip && (
          <ToolTip message={tooltip} left variant="secondary" leftMargin />
        )}
      </div>
      {hint &&
        (typeof hint === "string" ? (
          <p className="px-1 pt-1 font-light text-xs text-pot-maroon">{hint}</p>
        ) : hint.length ? (
          hint.length === 1 ? (
            <p className="px-1 pt-1 font-light text-xs text-pot-maroon">
              {hint[0]}
            </p>
          ) : (
            <ul className="list-disc px-4">
              {hint.map((each) => (
                <li className="pt-1 font-light text-xs text-pot-maroon">
                  {each}
                </li>
              ))}
            </ul>
          )
        ) : (
          ""
        ))}
    </div>
  );
};

export default InputField;
