import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom';
import { motion } from "framer-motion";

const MediaSidebar = ({ handleCancel = () => {}, children }: { handleCancel: () => void, children: ReactNode }) => {
    return createPortal(
        <>
          <div onClick={handleCancel}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
              className="w-screen h-screen fixed top-0 left-0 z-[48] bg-pot-darkblue"
            ></motion.div>
            <div className="w-screen h-screen fixed top-0 left-0 z-[49] backdrop-blur-[1px]"></div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="pointer-events-none w-screen z-[50] h-screen fixed top-0 left-0"
          >
            <motion.div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ duration: 0.5 }}
              className="select-none pointer-events-auto w-60 h-screen flex flex-col p-3 bg-white rounded-r-lg shadow-lg"
            >
              {children}
            </motion.div>
          </div>
        </>,
        document.getElementById("modal")!
      );
}

export default MediaSidebar