import React, {
  SetStateAction,
  useEffect,
  useMemo,
  useState,
  Dispatch,
} from "react";
import { useRecoilValue } from "recoil";
import { InventoryApi } from "../../api/inventory";
import SortIcon from "../../assets/SortIcon";
import { SelectedSessionAtom } from "../../atom";
import EditableField from "../../components/EditableField";
import SortButton from "../../components/SortButton";
import { toastIt } from "../../utils/common";

const useFinalAdjustmentColdef = (
  callAgain = (value: boolean) => {},
  sort: string,
  setSort: Dispatch<SetStateAction<string>>
) => {
  const [tempReasons, setTempReasons] = useState<any[]>([]);
  const [initial, setInitial] = useState(true);
  const [counter, setCounter] = useState<number>(0);
  const session = useRecoilValue(SelectedSessionAtom) as any;

  const findReason = (row: any, type: string) => {
    return tempReasons.find(
      (reason) =>
        reason.upc === row.original.upc &&
        reason.storage_location === row.original.location &&
        reason.key === type
    );
  };

  const adjust = async (payload: any) => {
    const { data: response } = await InventoryApi.adjust(session.id, payload);
    if (!response.success) {
      toastIt(response.error);
      const newTemp = tempReasons.filter(
        (each) =>
          each.upc !== payload.upc &&
          each.storage_location !== payload.storage_location &&
          each.key !== payload.key
      );
      setTempReasons(newTemp);
    }
  };

  // useEffect(() => {
  //   if (initial) {
  //     setInitial(false);
  //     return;
  //   }
  //   if (counter === 0) {
  //     callAgain(true);
  //   }
  // }, [counter]);

  const coldef = useMemo(
    () => [
      {
        header: () => (
          <SortButton text="UPC" keyVal="upc" setValue={setSort} value={sort} />
        ),
        accessorKey: "upc",
        size: 10,
      },
      { header: "Item Id", accessorKey: "item_code", size: 80 },
      {
        header: "Item name",
        accessorKey: "name",
        size: 400,
        cell: ({ row }: { row: any }) =>
          [
            row.original.item_name,
            row.original.dept_name,
            row.original.sub_dept_name,
          ].join(" - "),
      },
      {
        header: () => (
          <SortButton
            text="Size"
            keyVal="size"
            setValue={setSort}
            value={sort}
          />
        ),
        accessorKey: "size",
        size: 80,
      },
      { header: "Storage Location", accessorKey: "location", size: 100 },
      { header: "Category", accessorKey: "cat_name", size: 80 },
      {
        header: () => (
          <SortButton text="QOH" keyVal="QOH" setValue={setSort} value={sort} />
        ),
        accessorKey: "QOH",
        size: 80,
      },
      {
        header: () => (
          <SortButton text="QC" keyVal="QC" setValue={setSort} value={sort} />
        ),
        accessorKey: "QC",
        size: 200,
        cell: ({ getValue, row }: { getValue: any; row: any }) => {
          const changedVal = findReason(row, "qty_counted");
          return <div></div>;
        },
      },
      { header: "Change OH", accessorKey: "change_oh", size: 80 },
      {
        header: () => (
          <SortButton
            text="Cost Change"
            keyVal="Change_price"
            setValue={setSort}
            value={sort}
          />
        ),
        accessorKey: "Change_price",
        size: 80,
      },
      {
        header: "Adj Reason",
        accessorKey: "adj_reason",
        size: 400,
        cell: ({ getValue, row }: { getValue: any; row: any }) => {
          const changedVal = findReason(row, "adj_reason");
          return (
            <EditableField
              text={changedVal ? changedVal.value : getValue()}
              isOpen={(val) => {
                console.log(val, counter);
                if (counter === 0 && val === false) return;
                if (val) {
                  setCounter((prev) => prev + 1);
                } else {
                  setCounter((prev) => prev - 1);
                }
              }}
              setText={(value) => {
                const settable = {
                  storage_location: row.original.location,
                  upc: row.original.upc,
                  key: "adj_reason",
                  value,
                };
                adjust(settable);
                if (changedVal) {
                  changedVal.value = value;
                  setTempReasons([...tempReasons]);
                  return;
                }
                setTempReasons((prev) => [...prev, settable]);
              }}
            />
          );
        },
      },
    ],
    [tempReasons]
  );

  return coldef;
};

export default useFinalAdjustmentColdef;
