import React, { useState } from "react";
import FlagIcon from "../assets/FlagIcon";
import { AnimatePresence, motion } from "framer-motion";
import ToolTip from "./ToolTip";

type ItemDetailProp = {
  data: any;
  workflow?: string;
};

const ItemDetail = ({ data, workflow }: ItemDetailProp) => {
  const [showExplanation, setShowExplanation] = useState(false);
  return (
    <div>
      <div className="mt-3 rounded-md flex gap-x-4 text-xs p-2 bg-pot-lightmaroon2">
        {data.flag && (
          <div
            onMouseEnter={() => setShowExplanation(true)}
            onMouseLeave={() => setShowExplanation(false)}
            className="w-fit text-pot-maroon relative"
          >
            <FlagIcon />
            <AnimatePresence>
              {
                showExplanation && (
                  <ToolTip
                    message="This item does not belong to any of the category selected in the current session"
                    variant="secondary"
                    left
                  />
                )
                // (<motion.div initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.3, delay: 0.3 }} exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.3, delay: 0}}}  className="absolute text-pot-black py-1 px-2 -top-8 -left-2 text-xs bg-white border rounded-md z-[60] min-w-[30vw]">
                // This item does not belong to any of the category selected in the
                // current session
                // </motion.div>)
              }
            </AnimatePresence>
          </div>
        )}
        <div className="w-full">
          <p>{data.item_name}</p>
          <p>
            Item Code : {data.item_code} | UPC : {data.upc}
          </p>
          {workflow === "REGISTER_51" && data.qty_oh && (
            <p>QOH : {data.qty_oh}</p>
          )}
        </div>
      </div>
      {/* {data.flag && (
        <p className="mt-3 text-xs text-pot-maroon">
          This item does not belong to any of the category selected in the
          current session
        </p>
      )} */}
    </div>
  );
};

export default ItemDetail;
