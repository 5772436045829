import React from "react";
import { IconProp } from "./ArrowIcons";

const RetryIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666656 1.66666V5.66666H4.66666"
        stroke={color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33999 9.00001C2.77225 10.2269 3.59155 11.2801 4.67443 12.0009C5.75731 12.7217 7.04511 13.0711 8.3438 12.9963C9.64249 12.9216 10.8817 12.4268 11.8747 11.5865C12.8678 10.7462 13.5608 9.60599 13.8495 8.33758C14.1381 7.06917 14.0067 5.74131 13.4751 4.55407C12.9434 3.36684 12.0403 2.38454 10.9019 1.75518C9.76342 1.12583 8.45126 0.883515 7.16311 1.06475C5.87496 1.24599 4.6806 1.84095 3.75999 2.76001L0.666656 5.66667"
        stroke={ color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RetryIcon;
