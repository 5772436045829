export type addressType = {
    line1: string;
    line2: string;
    line3: string;
    hasValue: boolean;
}

export enum addressActionTypeEnum {
    line1= "SET_LINE_1",
    line2= "SET_LINE_2",
    line3= "SET_LINE_3",
    clear= "CLEAR",
    setAll= "SET_ALL",
}

export type addressActionType = {
    type: addressActionTypeEnum;
    payload?: string | any;
}