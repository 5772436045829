import React from "react";
import { Link } from "react-router-dom";

type LinkDivPropType = {
  to?: string;
  className?: string;
  children: React.ReactNode;
};

const LinkDiv = ({ to = "", className = "", children }: LinkDivPropType) => {
  if (to) {
    return (
      <div className={className}>
        <Link to={`/${to}`} relative="path">
        {children}
      </Link>
      </div>
    );
  }
  return <div className={className}>{children}</div>;
};

export default LinkDiv;
