import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LivesalesApi } from "../../api/livesales";
import {
  LoadingProgressAtom,
  SelectedSessionAtom,
  TableLoadingAtom,
} from "../../atom";

const useLivesalesRecordTableFetch = ({
  type = "counted",
  category = "",
  search = "",
  sort = "",
}: {
  type?: string;
  search?: string;
  category?: string;
  sort?: string;
}) => {
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [called, callAgain] = useState(false);
  const [initial, setInitial] = useState(true);
  const navigate = useNavigate();
  const setTableLoading = useSetRecoilState(TableLoadingAtom);
  const setFetchLoading = useSetRecoilState(LoadingProgressAtom);

  const fetch = async () => {
    setFetchLoading(10);
    if (!called) {
      setTableLoading(true);
    }
    const params = { list_kind: type } as any;
    if (search) {
      params.search_by = search;
    }
    if (sort) {
      params.sort_by = sort;
    }
    setFetchLoading(40);
    const { data: response } = await LivesalesApi.list(session.id, params);
    setFetchLoading(70);
    setTableLoading(false);
    setFetchLoading(100);
    if (!response.success) return;
    setData(response.data);
    setLoading(false);
    callAgain(false);
  };

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    if (!session.id) {
      navigate("/");
      return;
    }
    fetch();
  }, [sort]);

  useEffect(() => {
    setLoading(true);
    fetch();
  }, [search, category]);

  useEffect(() => {
    if (called) {
      setLoading(true);
      fetch();
    }
  }, [called]);
  return { data, callAgain, loading };
};

export default useLivesalesRecordTableFetch;
