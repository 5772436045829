import React, { useRef, useState, Dispatch } from "react";
import { DownwardArrowIcon } from "../assets/ArrowIcons";
import useClickOutside from "../hooks/useClickOutside";
import Button, { ButtonPropsType } from "./Button";
import { motion } from "framer-motion";

export type SelectableDropdownValueType = {
  label: any;
  value: any;
  is_active?: boolean;
  tag?: string;
};

type DropdownPropsType = {
  buttonProps?: ButtonPropsType | null;
  buttonText: string;
  buttonClick?: () => void;
  optionList: SelectableDropdownValueType[];
  noRotateOnOpen?: boolean;
  reverseAnimate?: boolean;
  animate?: boolean;
  selectOption?: (value?: any) => void;
  className?: string;
  beautify?: boolean;
  data?: [];
  setData?: Dispatch<any>;
};

type RightIconPropType = {
  icon: React.ReactNode | null;
  open: boolean;
  reverseAnimate?: boolean;
};

const RightIcon = ({
  icon,
  open,
  reverseAnimate = false,
}: RightIconPropType) => {
  return (
    <motion.div
      initial={{ rotate: reverseAnimate ? 180 : 360 }}
      animate={{
        rotate: open ? (reverseAnimate ? 0 : 180) : reverseAnimate ? 180 : 360,
      }}
    >
      {icon}
    </motion.div>
  );
};

const Dropdown = ({
  buttonProps,
  buttonText = "",
  buttonClick = () => {},
  optionList = [],
  selectOption = () => {},
  animate = true,
  className = "",
  reverseAnimate = false,
  beautify = false,
  data = [],
  setData = () => [],
}: DropdownPropsType) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useClickOutside(ref, () => setOpenDropdown(false));

  const selectCategory = (input: string) => {
    console.log("Category is this ", input);
    var filteredList = data.filter((item: any) => {
      return item.category.match(input);
    });
    setData(filteredList);
  };

  return (
    <div ref={ref} className={`relative ${className}`}>
      <Button
        text={buttonText}
        type={openDropdown ? "blue" : "primary_outline"}
        onClick={() => {
          setOpenDropdown((prev) => !prev);
          buttonClick();
        }}
        rightIcon={
          !animate ? (
            buttonProps?.rightIcon || <DownwardArrowIcon />
          ) : buttonProps?.rightIcon ? (
            <RightIcon
              icon={buttonProps?.rightIcon}
              open={openDropdown}
              reverseAnimate={reverseAnimate}
            />
          ) : (
            <RightIcon
              icon={<DownwardArrowIcon />}
              open={openDropdown}
              reverseAnimate={reverseAnimate}
            />
          )
        }
        {...buttonProps}
      />
      {openDropdown && (
        <div
          className={`w-[16rem] absolute mt-1.5 bg-white py-1 border rounded-md max-h-60 overflow-y-auto ${className}`}
        >
          {optionList.map((each) => (
            <button
              key={each.label}
              onClick={() => {
                setOpenDropdown(false);
                selectOption(each);
                selectCategory(each.label);
              }}
              className="py-2 px-5 w-full max-w-full truncate text-left list-none hover:bg-pot-lightblue active:bg-pot-lightblue2"
            >
              {beautify
                ? each.label.slice(0, 1).toUpperCase() +
                  each.label.slice(1).toLowerCase()
                : each.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
