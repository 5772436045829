import { authenticatedAxiosInstance as axios } from "./axios";

const list = (storeId: string, params = { length: 1000, page: 1 }) =>
  axios.get(`/store/${storeId}/storage-location`, { params });
const create = (store_id: string, payload: any) =>
  axios.post(`/store/${store_id}/storage-location`, payload);
const update = (store_id: string, s_location_id: string, payload: any) =>
  axios.patch(`/store/${store_id}/storage-location/${s_location_id}`, payload);
const destroy = (store_id: string, s_location_id: string) =>
  axios.delete(`/store/${store_id}/storage-location/${s_location_id}`);
const users = (storeId: string) => axios.get(`/store/${storeId}/users`);
export const StorageLocationApi = {
  list,
  create,
  update,
  destroy,
  users,
};
