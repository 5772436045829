export const urls = {
  login: "login",
  sessions: "s/:sid/session",
  sessionsCreate: "s/:sid/session/create",
  liveInventoryRecords: "s/:sid/session/:id/records",
  editSession: "s/:sid/session/:id/edit",
  uncountedRecords: "s/:sid/session/:id/uncounted-records",
  livesales: "s/:sid/session/:id/livesales",
  scan: "s/:sid/session/:id/scan",
  scan_history: "s/:sid/session/:id/scan#history",
  adjustment: "s/:sid/session/:id/adjustment",
  reports: "s/:sid/session/:id/report",
  manageUsers: "user",
  manageUsersEdit: "user/:id",
  manageUsersCreate: "user/create",
  manageStore: "store",
  manageStoreEdit: "store/:id",
  manageStoreCreate: "store/create",
};

// export const urls = {
//   login: ({ }) => `login`,
//   sessions: ({sid = `:sid`}) => `s/${sid}/session`,
//   sessionsCreate: ({sid = `:sid`}) => `s/${sid}/session/create`,
//   liveInventoryRecords: ({sid = `:sid`, id = ":id"}) =>
//     `s/${sid}/session/${id}/records`,
//   uncountedRecords: ({sid = `:sid`, id = ":id"}) =>
//     `s/${sid}/session/${id}/uncounted-records`,
//   scan: ({sid = `:sid`, id = ":id"}) => `sid}/session/${id}/scan`,
//   scan_history: ({sid = `:sid`, id = ":id"}) =>
//     `s/${sid}/session/${id}/scan#history`,
//   adjustment: ({sid = `:sid`, id = ":id"}) => `s/${sid}/session/${id}/adjustment`,
//   reports: ({sid = `:sid`, id = ":id"}) => `s/${sid}/session/${id}/report`,
//   manageUsers: ({}) => `user`,
//   manageUsersEdit: ({id = ":id"}) => `user/${id}`,
//   manageUsersShow: ({}) => `user/create`,
//   manageStore: ({}) => `store`,
//   manageStoreEdit: ({id = ":id"}) => `store/${id}`,
//   manageStoreShow: ({}) => `store/create`,
// };
