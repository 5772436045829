import React from 'react'
import { motion } from 'framer-motion'

type ToolTipProps = {
    variant?: "primary" | "secondary";
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
    leftIcon?: React.ReactNode;
    message: string;
    leftMargin?: boolean
}

const ToolTip = ({ leftIcon, message, variant="primary", bottom=false, left=false, right=false, leftMargin=false, }: ToolTipProps) => {
  return (
    <>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1 }} className={`absolute ${bottom ? "top-10" : "-top-12"} flex ${left ? `justify-start ${leftMargin ? "left-0" : "-left-5"}` : right ? "justify-end -right-3" : "justify-center"} items-center gap-x-2 ${variant === "secondary" ? "bg-pot-bg-tooltip text-white" : "bg-white"} z-[46] px-5 py-2 border rounded-md w-fit whitespace-nowrap`}>
            {leftIcon}
            {message}
            <div className={`absolute ${bottom ? "-top-1.5 border-t border-l" : "-bottom-1.5 border-b border-r"} ${variant === "secondary" ? "bg-pot-bg-tooltip" : "bg-white"} w-3 h-3 rotate-45`}>

            </div>
        </motion.div>
    </>
  )
}

export default ToolTip