import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SessionApi } from "../../api/session";
import {
  AccountInfoAtom,
  NewSessionCreatedAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
} from "../../atom";
import Typography from "../../components/Typography";
import useFetchCategories from "../../hooks/fetch/useFetchCategories";
import { joiner } from "../../utils/common";
import { titles } from "../../utils/titles";
import { urls } from "../../utils/urls";
import LeftForm from "../SessionCreate/SessionLeftForm";
import RightForm from "./SessionRightForm";
import useFetchSession from "../../hooks/fetch/useFetchSession";

const SessionEdit = () => {
  const [uploadId, setUploadId] = useState<string>("");
  const [showWorfLow, setShowWorkflow] = useState<any>("");
  const [redirectionLink, setRedirectionLink] = useState("");
  const [showableCatList, setShowableCatList] = useState<any[]>([]);
  const [userStorageLoc, setUserStorageLoc] = useState<any[]>([]);
  const [userAccess, setUserAccess] = useState<any[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const notAllowAddCat = useFetchCategories(uploadId);
  const setNewSessionCreated = useSetRecoilState(NewSessionCreatedAtom);
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === "ADMIN";
  const isManager = role === "MANAGER";
  const isEmp = role === "EMPLOYEE";
  const navigate = useNavigate();
  const sessionData = useFetchSession();

  useEffect(() => {
    setShowWorkflow(sessionData.workflow);
    setUserAccess(sessionData.user_storage_loc);
  }, [sessionData]);

  const categoryData = sessionData.category_department?.reduce(
    (acc: any, item: any) => {
      const { cat_name, dept_name, sub_dept_name } = item;

      // Find the existing category by cat_name
      const existingCategory = acc.find(
        (category: any) => category.cat_name === cat_name
      );

      if (existingCategory) {
        // Check if dept_name is not already included
        if (
          !existingCategory.dept_name.some(
            (dept: any) => dept.name === dept_name
          )
        ) {
          existingCategory.dept_name.push({ name: dept_name, sub: [] });
        }
        // Find the existing department by name and add the sub-department
        const existingDept = existingCategory.dept_name.find(
          (dept: any) => dept.name === dept_name
        );
        if (
          !existingDept.sub.some(
            (subDept: any) => subDept.sub_name === sub_dept_name
          )
        ) {
          existingDept.sub.push({ sub_name: sub_dept_name });
        }
      } else {
        const structuredData = {
          cat_name,
          dept_name: [
            {
              name: dept_name,
              sub: [{ sub_name: sub_dept_name }],
            },
          ],
        };
        acc.push(structuredData);
      }

      return acc;
    },
    []
  );

  const onSubmit = async () => {
    setSubmitLoading(true);
    const payload = {
      user_storage_loc: userStorageLoc,
    };

    const { data: response } = await SessionApi.editPut(
      store.id,
      session.id,
      payload
    );
    if (response.success) {
      toast.success("Session successfully edit");
      setNewSessionCreated(true);
      setRedirectionLink(
        "/" +
          urls.sessions
            .replace(":sid", store.id)
            .replace(":id", response.data.id)
      );
    } else {
      Object.values(response.data.error.messages).forEach((error: any) => {
        toast.error(error.join());
      });
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (redirectionLink) {
      navigate(redirectionLink);
    }
  }, [redirectionLink]);

  useEffect(() => {
    document.title = titles.sessionEdit;
  }, []);

  if (isEmp) {
    return (
      <div className="w-full h-full flex justify-center items-center text-pot-textgrey2 font-semibold text-sm">
        You are not allowed to view this page
      </div>
    );
  }

  return (
    <>
      <Typography
        text="Live Inventory Sessions"
        bold
        xxl
        m={{ t: "mt-[4vh]", b: "mb-[1vh]" }}
      />
      <div className="w-full h-full flex justify-center items-center">
        <div className="h-[80vh] w-full overflow-x-hidden bg-white flex rounded-lg border">
          <LeftForm
            loading={submitLoading}
            setUploadId={setUploadId}
            showWorfLow={showWorfLow}
            setShowWorkflow={setShowWorkflow}
            uploadId={sessionData.pos_import_id}
            canStart={showableCatList?.length > 0}
            title="Edit Session"
            onSubmit={onSubmit}
            disable
          />
          {/* <AnimatePresence>
            {uploadId && ( */}
          <RightForm
            setShowableCatList={setShowableCatList}
            userStorageLoc={userStorageLoc}
            setUserStorageLoc={setUserStorageLoc}
            showableCatList={showableCatList}
            userAccess={userAccess}
            setUserAccess={setUserAccess}
            disabledOverall={notAllowAddCat || !uploadId}
            categoryDepartmentData={categoryData}
            disable
          />
          {/* )}
          </AnimatePresence> */}
        </div>
      </div>
    </>
  );
};

export default SessionEdit;
