import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Typography from "../../components/Typography";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import { useRecoilValue } from "recoil";
import { SelectedStoreAtom } from "../../atom";
import useFetchStorageLocation from "../../hooks/fetch/useFetchStorageLocation";
import TrashIcon from "../../assets/TrashIcon";
import useFetchStoreUsers from "../../hooks/fetch/useFetchStoreUsers";
import { joiner } from "../../utils/common";

type UserAccessProp = {
  userAccess: any[];
  setUserAccess: Dispatch<SetStateAction<any>>;
  userStorageLoc: any[];
  setUserStorageLoc: Dispatch<SetStateAction<any>>;
  showableCatList: any[];
};

type StorageLocations = {
  label: string;
  value: string;
};

function SessionUserAccess({
  userAccess,
  setUserAccess,
  userStorageLoc,
  setUserStorageLoc,
  showableCatList,
}: UserAccessProp) {
  const UsersOptions: any[] = useFetchStoreUsers();
  const [usersOptions, setUsersOptions] = useState<any[]>(UsersOptions);
  const initialStorageLocations = useFetchStorageLocation();
  const [storageLocations, setStorageLocations] = useState<any[]>([]);
  const [validStorageLocations, setValidStorageLocations] = useState<any>([]);
  const [usersAccesses, setUsersAccesses] = useState<
    {
      selectedUser: any;
      storageLocations: StorageLocations[];
      uncheckedLocations: any[];
    }[]
  >([]);

  useEffect(() => {
    setUsersOptions(UsersOptions);
  }, [UsersOptions]);

  useEffect(() => {
    const selectedUsers = usersAccesses.map((user: any) => user.selectedUser);

    let updatedOptions = [...usersOptions];
    updatedOptions.forEach((user: any) => {
      if (selectedUsers.includes(user?.value?.id)) {
        user.value.is_active = false;
      } else {
        delete user.value.is_active;
      }
    });
    setUsersOptions(updatedOptions);
  }, [usersAccesses]);

  useEffect(() => {
    handleValidLocations();
  }, [showableCatList]);

  useEffect(() => {
    filterStorageLocations(initialStorageLocations, validStorageLocations);
  }, [initialStorageLocations, validStorageLocations]);

  // useEffect(() => {
  //   setStorageLocations(initialStorageLocations);
  // }, [initialStorageLocations]);

  useEffect(() => {
    if (userAccess) updateUsersAccesses(userAccess);
  }, [userAccess]);

  useEffect(() => {
    updateState();
  }, [usersAccesses]);

  const updateUsersAccesses = (data: any) => {
    const newState = [...usersAccesses];
    data?.forEach((item: any) => {
      const { is_active, storage_location_id, user_id } = item;
      const userIndex = newState.findIndex(
        (user) => user.selectedUser === user_id
      );
      if (userIndex !== -1) {
        if (is_active) {
          newState[userIndex].storageLocations.push(storage_location_id);
        } else {
          newState[userIndex].uncheckedLocations.push(storage_location_id);
        }
      } else {
        const newUserAccess = {
          selectedUser: user_id,
          storageLocations: is_active ? [storage_location_id] : [],
          uncheckedLocations: is_active ? [] : [storage_location_id],
        };

        newState.push(newUserAccess);
      }
    });
    setUsersAccesses(newState);
  };

  const handleValidLocations = () => {
    setValidStorageLocations((prev: any) =>
      showableCatList?.map((elementArray: any) => {
        const element = elementArray[0];
        const categoryData = element.split(joiner);
        return {
          cat_name: categoryData[0],
          dept_name: categoryData[1],
          sub_dept_name: categoryData[2],
        };
      })
    );
  };

  // Function to update the storage locations to the filtered locations
  function filterStorageLocations(storageLocations: any[], categories: any) {
    let filteredLocations: any[] = [];
    storageLocations?.forEach((storageLocation: any) => {
      let found = false;
      categories?.forEach((category: any) => {
        const cat_name = category.cat_name;
        const dept_name = category.dept_name;
        const sub_dept_name = category.sub_dept_name;
        storageLocation.value.categories?.forEach((cat: any) => {
          if (
            cat.cat_name === cat_name &&
            cat.dept_name === dept_name &&
            cat.sub_dept_name === sub_dept_name
          ) {
            found = true;
          }
        });
      });
      if (found) {
        filteredLocations.push(storageLocation);
      }
    });

    setStorageLocations(filteredLocations);
  }

  const addMoreComponents = () => {
    setUsersAccesses([
      ...usersAccesses,
      { selectedUser: "", storageLocations: [], uncheckedLocations: [] },
    ]);
  };

  const onDeleteUserAccess = (indexToDelete: number) => {
    const updatedComponents = [...usersAccesses];
    updatedComponents.splice(indexToDelete, 1);
    setUsersAccesses(updatedComponents);
  };

  const updateState = () => {
    const userStorageLoc = usersAccesses.flatMap((userStore) => {
      const activeLocations = userStore.storageLocations.map((element) => {
        return {
          is_active: true,
          user_id: userStore.selectedUser,
          storage_location_id: element,
        };
      });
      const inactiveLocations = userStore.uncheckedLocations.map((element) => {
        return {
          is_active: false,
          user_id: userStore.selectedUser,
          // NEED  TO BE CHECKED
          storage_location_id: element.value ? element.value.id : element,
        };
      });
      return [...activeLocations, ...inactiveLocations];
    });
    const uniqueUserStorageLoc = removeDuplicates(userStorageLoc, [
      "user_id",
      "storage_location_id",
    ]);
    setUserStorageLoc(uniqueUserStorageLoc);
  };

  // Function to remove duplicates in user_storage_loc
  const removeDuplicates = (array: any, keys: any) => {
    const seen = new Set();
    return array.filter((item: any) => {
      const uniqueKey = keys.map((key: any) => item[key]).join("|");
      if (!seen.has(uniqueKey)) {
        seen.add(uniqueKey);
        return true;
      }
      return false;
    });
  };

  // Function to update selected users in the state
  const handleUserChange = (index: number, value: any) => {
    const updatedComponents = [...usersAccesses];
    updatedComponents[index].selectedUser = value.value.id;
    updateState();
    setUsersAccesses(updatedComponents);
  };

  // Function to update checked storages in the state
  const handleStorageLocationChange = (index: number, storage: any) => {
    const updatedComponents = [...usersAccesses];
    const storageId = storage.value.id;

    if (updatedComponents[index].storageLocations.includes(storageId)) {
      updatedComponents[index].storageLocations = updatedComponents[
        index
      ].storageLocations.filter((id) => id !== storageId);
    } else {
      updatedComponents[index].storageLocations.push(storageId);
    }
    // const remainingLocations = updatedComponents?.filter(
    //   (storage: any) =>
    //     !updatedComponents[index].storageLocations.includes(storage.value.id)
    // );
    // updatedComponents[index].uncheckedLocations = remainingLocations;
    updateState();
    setUsersAccesses(updatedComponents);
  };

  // Function to return the object with label and value of the selected user
  const getuserLabel = (index: number, value: any) => {
    const user = usersOptions.find((user) => user.value.id === value);
    return user
      ? { label: user.label, value: value }
      : { label: "", value: "" };
  };

  // Function to get the checked/ unchecked value of storages
  const getStorageCheckedValue = (index: number, storageValue: any) => {
    return usersAccesses[index].storageLocations.includes(
      storageValue.value.id
    );
  };

  const getSelectAllValue = (index: number) => {
    return (
      usersAccesses[index].storageLocations.length === storageLocations.length
    );
  };

  const handleSelectAll = (index: number) => {
    const updatedComponents = [...usersAccesses];
    const allStorageIds = storageLocations.map((storage) => storage.value.id);
    const userStorageIds = updatedComponents[index].storageLocations;

    if (userStorageIds.length === allStorageIds.length) {
      // If all are selected, deselect all
      updatedComponents[index].storageLocations = [];
    } else {
      // If not all are selected, select all
      updatedComponents[index].storageLocations = allStorageIds;
    }

    const remainingLocations = storageLocations.filter(
      (storage) =>
        !updatedComponents[index].storageLocations.includes(storage.value.id)
    );
    updatedComponents[index].uncheckedLocations = remainingLocations;

    setUsersAccesses(updatedComponents);
    updateState();
  };

  return (
    <div className="h-max px-[2vh] py-[2vh] flex flex-col gap-8">
      <Typography
        text="User Access"
        className="pb-2 text-sm xl:text-[16px]"
        semibold
      />
      {usersAccesses.map((component, index) => (
        <div key={index} className="flex gap-2">
          <div className="w-[30%]">
            <SingleSelectDropdown
              selectedValue={getuserLabel(index, component.selectedUser)}
              onSelect={(value: any) => handleUserChange(index, value)}
              label="Users"
              boxText="Select users"
              optionList={usersOptions}
              rightIcon={<TrashIcon />}
              height="h-[30px]"
              onRightIconClick={() => {
                onDeleteUserAccess(index);
              }}
            />
          </div>
          <div className="w-[70%] flex flex-col">
            <div className="flex justify-between">
              <p className="font-semibold text-sm pb-2 px-1">
                Storage Locations
              </p>
              <div onClick={() => handleSelectAll(index)}>
                <Checkbox
                  checked={getSelectAllValue(index)}
                  label={"Select All"}
                  small
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              {showableCatList?.length > 0 ? (
                storageLocations?.map((storage: any) => {
                  return (
                    <div
                      key={storage.value.id}
                      className="p-2 border-2 rounded-md"
                      onClick={() =>
                        handleStorageLocationChange(index, storage)
                      }
                    >
                      <Checkbox
                        checked={getStorageCheckedValue(index, storage)}
                        label={storage.label}
                      />
                    </div>
                  );
                })
              ) : (
                <div
                  className={`w-full font-semibold text-sm text-pot-black/50 flex items-center`}
                >
                  <span>
                    No storage locations found for the selected categories
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <Button
        type="primary_outline"
        text="Add more users"
        onClick={addMoreComponents}
        margins="mx-0"
        fullWidth
        parentFullWidth
      />
    </div>
  );
}

export default SessionUserAccess;
