import React from "react";
import { IconProp } from "./ArrowIcons";

const PlusIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33334 6.00001H6.00001M10.6667 6.00001H6.00001M6.00001 6.00001V10.6667M6.00001 6.00001V1.33334"
        stroke={color || "inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
