import React from 'react'

type InlineMessageType = {
    leftIcon: React.ReactNode;
    message: string;
}

const InlineMessage = ({ leftIcon, message }: InlineMessageType) => {
  return (
    <div className="flex gap-x-2">
        {leftIcon}
        {message}
    </div>
  )
}

export default InlineMessage