import React from 'react'

type TypographyPropType = {
    text: string;
    bold?: boolean;
    semibold?: boolean;
    xxl?: boolean;
    size?: string;
    center?: boolean;
    fullWidth?: boolean;
    m?: { t?: string, b?: string, y?: string, x?: string, l?: string, r?: string};
    className?: string;
}

const Typography = ({ text="", semibold=false, fullWidth=false, size="", bold=false, xxl=false, m, center=false, className="" }: TypographyPropType) => {
  return (
    <p className={`${bold && "font-bold"} ${semibold && "font-semibold"} ${fullWidth && "w-full"} ${size} ${className} ${center && "text-center"} ${xxl && "text-xl"} ${m?.t} ${m?.b} ${m?.y}  ${m?.l} ${m?.r} ${m?.x} `}>{text}</p>
  )
}

export default Typography