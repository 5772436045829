import Typography from "../../components/Typography";
import Button from "../../components/Button";
import DropZone from "../../components/DropZone";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { titles } from "../../utils/titles";
import { BASE_URL } from "../../api/axios";
import { authenticatedAxiosInstance as axios } from "../../api/axios";
import { SelectedSessionAtom, SelectedStoreAtom } from "../../atom";
import { useRecoilValue } from "recoil";
import { SessionApi } from "../../api/session";
import { toast } from "react-toastify";

const UploadLivesales = () => {
  const freeze = async () => {};
  const [fetchLoading, setFetchLoading] = useState(false);

  const [error, setError] = useState("");
  const [uploadId, setUploadId] = useState("");
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const session = useRecoilValue(SelectedSessionAtom) as any;

  const uploadFile = async (
    accepted: any,
    setUploadProgress?: Dispatch<SetStateAction<number>>
  ) => {
    setError("");
    const formData = new FormData();
    formData.append("file", accepted, accepted.name);
    setUploadProgress?.(50);
    const { data: response } = await axios.post(
      `/store/${store.id}/livesales-data/upload`,
      formData,
      {
        baseURL: BASE_URL,
        headers: {
          // Authorization: `${getFromLocalStorage("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.success) {
      setUploadProgress?.(100);
      const { data: response2 } = await axios.post(
        `/live-inventory/${session.id}/livesales/initiate`,
        { ls_import_id: response.data.ls_import_id }
      );
      if(response2.success) {
        window.location.reload()
      } else {
        toast.error(response2.data)
      }
    } else {
      const errors = response.error;
      const errorArr = Object.keys(errors.messages).map((each: any) => {
        if (
          errors.messages[each]
            .join(", ")
            .toLowerCase()
            .includes("invalid token")
        ) {
          return "";
        }
        return errors.messages[each].join(", ");
      });
      setError(errorArr.join("; "));
      setUploadProgress?.(0);
    }
  };

  const fetchFromDrive = async () => {
    setError("");
    setFetchLoading(true);
    const { data: response } = await SessionApi.getImportId(store.id);
    if (response.success) {
      setUploadId(response.data.ls_import_id);
      setFetchLoading(false);
      return;
    } else {
      const errors = response.error;
      const errorArr = Object.keys(errors.messages).map((each: any) => {
        let err =
          typeof errors.messages[each] === "string"
            ? errors.messages[each]
            : errors.messages[each].join(", ");
        if (err.toLowerCase().includes("invalid token")) {
          return "";
        }
        if (typeof errors.messages[each] === "string") {
          return errors.messages[each];
        }
        return errors.messages[each].join(", ");
      });
      setError(errorArr.join("; "));
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    document.title = titles.livesalesRecords;
    freeze();
  }, []);

  return (
    <>
      <Typography
        text="Upload Live Sales Data"
        bold
        size="text-2xl"
        className="py-4"
      />
      <div className="w-3/5">
        <DropZone onUpload={uploadFile} />
        <div className="w-full flex flex-col justify-center items-center h-20 py-[5vh]">
          {error && (
            <Typography
              text={error}
              m={{ b: "mb-1", l: "ml-1" }}
              className="text-pot-maroon"
            />
          )}
          <Typography text="OR" bold xxl className="py-4" />
          <Button
            onClick={fetchFromDrive}
            text="Fetch LS data from drive"
            type="primary_outline"
          />
        </div>
      </div>
    </>
  );
};

export default UploadLivesales;
