import React from "react";
import Logo from "../../assets/png/homewines_logo.png"

const SidebarHeader = () => {
  return (
    <div className="h-[10vh] px-5 2xl:px-12 py-2">
      <img src={Logo} className="w-full h-full object-contain" />
    </div>
  );
};

export default SidebarHeader;
