import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { InventoryApi } from "../../api/inventory";
import { SelectedSessionAtom } from "../../atom";
import TableControl from "../../components/TableComponents/TableControl";
import HeaderAndControl from "../../components/TableComponents/TableHeaderAndControl";
import TableLayout from "../../components/TableComponents/TableLayout";
import useFetchStorageLocation from "../../hooks/fetch/useFetchStorageLocation";
import useFinalAdjustmentColdef from "../../hooks/coldef/useFinalAdjustmentColdef";
import useFinalAdjustmentTableFetch from "../../hooks/fetch/useFinalAdjustmentTableFetch";
import useInventoryRecordTableFetch from "../../hooks/fetch/useInventoryRecordTableFetch";
import {
  eachStringToDropDownObj,
  toCatList,
} from "../../utils/helperFunctions";
import CompletePopup from "./CompletePopup";

const FinalAdjustmentList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(10);
  const [catagoriesOptions, setCategoriesOption] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedSL, setSelectedSL] = useState<string>("");
  const [sort, setSort] = useState("")
  const [openModal, setOpenModal] = useState(false);
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const slOptions = useFetchStorageLocation();
  // const { data, totalCount, totalPages, callAgain } = useInventoryRecordTableFetch({
  //   type: "final_adj",
  //   search: searchValue,
  //   category: selectedCategory,
  //   sl: selectedSL,
  //   sort,
  //   setPage,
  //   length,
  //   page,
  // });
  const data: any[] = []
  const totalCount = 0;
  const totalPages = 0;
  const callAgain = () => {}
  const coldef = useFinalAdjustmentColdef(callAgain, sort, setSort);

  const fetchCategories = async () => {
    const { data: response } = await InventoryApi.categories(session.id);
    const data = toCatList(response.data);
    setCategoriesOption(eachStringToDropDownObj(data));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col justify-between">
        <HeaderAndControl
          withDebounce={true}
          slOptions={slOptions}
          categoriesOptions={catagoriesOptions}
          buttonClick={() => setOpenModal(true)}
          heading="Final Adjustment"
          isFinalAdjustment
          search={searchValue}
          setSearch={setSearchValue}
          setSelectedCategory={setSelectedCategory}
          setSelectedSL={setSelectedSL}
          selectedCategory={selectedCategory}
          selectedSL={selectedSL}
        />
        <TableLayout data={data} coldef={coldef} />
        <TableControl
          totalCount={totalCount}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          setLength={setLength}
          length={length}
        />
      </div>
      {openModal && <CompletePopup handleCancel={() => setOpenModal(false)} />}
    </>
  );
};

export default FinalAdjustmentList;
