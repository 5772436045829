import React, { useEffect, useState } from "react";
import { ClosedEyesIcon, OpenEyesIcon } from "../assets/EyesIcons";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import InputField from "../components/InputField";
import Typography from "../components/Typography";
import Logo from "../assets/png/homewines_logo.png";
import { loginAPI } from "../api/login";
import { setToLocalStorage } from "../utils/localStorage";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import useForceUpdate from "../hooks/useForceUpdate";
import { toastIt } from "../utils/common";
import { titles } from "../utils/titles";
import useEnterSubmit from "../hooks/useEnterSubmit";

const Login = ({ setIsLoggedIn }: any) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async () => {
    setLoading(true);
    const payload = {
      email,
      password,
    };
    const { data: response } = await loginAPI(payload);
    if (response.success) {
      const currentDateTime = new Date();
      const dateTimeString = currentDateTime.toISOString();
      setIsLoggedIn(true);
      setToLocalStorage("token", response.data.token);
      setToLocalStorage("lastLoggedIn", dateTimeString);
      navigate("/");
    } else {
      const errorObj = toastIt(response.error, true);
      setErrors(errorObj);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = titles.login;
  }, []);

  const hasFilledAllFields = () => {
    if (email && password) return true;
    return false;
  };

  useEnterSubmit(hasFilledAllFields, onSubmit, [email, password]);

  return (
    <div className="w-screen h-screen bg-pot-base flex justify-center items-center">
      <section className="bg-white border w-[85vw] sm:[80vw] rounded-xl md:w-[50vw] xl:w-[40vw] 2xl:w-[36vw] relative flex flex-col items-center border-pot-grey2 px-10 lg:px-24">
        <img
          src={Logo}
          className="absolute -top-6 md:-top-11 w-[50%] max-w-[14rem]"
        />
        <Typography
          text="Sign in to your account"
          bold
          xxl
          center
          m={{ t: "mt-[8vh]", b: "mb-3" }}
        />
        <Typography text="Live inventory system" center />
        <InputField
          label="Email"
          value={email}
          onChange={(text) => {
            setEmail(text);
            delete errors.email;
            setErrors({ ...errors });
          }}
          placeholder="johndoe@example.com"
          margins="mt-[4vh] mb-3"
          hint={errors.email}
        />
        <InputField
          label="Password"
          inputType={showPassword ? "text" : "password"}
          value={password}
          onChange={(text) => {
            setPassword(text);
            delete errors.password;
            setErrors({ ...errors });
          }}
          placeholder={showPassword ? "password" : "⋅⋅⋅⋅⋅⋅⋅⋅"}
          margins="mt-3 mb-3"
          rightIcon={showPassword ? <ClosedEyesIcon /> : <OpenEyesIcon />}
          rightIconClickHandler={() => setShowPassword((prev) => !prev)}
          hint={errors.password}
        />
        <div className="w-full flex flex-col lg:flex-row items-center gap-y-2 justify-between text-xs">
          <Checkbox
            small
            checked={rememberMe}
            onClick={() => setRememberMe((prev) => !prev)}
            label="Remember me"
          />
          <a href="#" className="text-pot-darkmaroon underline text-center">
            Forgot your password?
          </a>
        </div>
        <Button
          loading={loading}
          onClick={onSubmit}
          fullWidth
          text="Sign in"
          margins="my-[8vh]"
        />
      </section>
    </div>
  );
};

export default Login;
