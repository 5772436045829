import { authenticatedAxiosInstance as axios } from "./axios";

const list = (params = { params: { length: 1000, page: 1 }}) => axios.get("/store", params)
const create = (payload: any) => axios.post("/store", payload)
const show = (id: string) => axios.get(`/store/${id}`)
const update = (id: string, payload: any ) => axios.patch(`/store/${id}`, payload)
const is_available = (params: any) => axios.get(`/store/pos-data/is-available`, params)
const destory = (store_id: string) => axios.delete(`/store/${store_id}`)

export const StoreApi = {
    list,
    create,
    show,
    update,
    is_available,
    destory,
}