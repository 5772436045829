import React, { useState } from "react";
import Typography from "./Typography";
import Button from "./Button";
import { RightArrowIcon } from "../assets/ArrowIcons";
import { SessionApi } from "../api/session";
import { useNavigate } from "react-router";

const FinishBottomBar = ({ storeId, sessionId, role }: any) => {
  const navigate = useNavigate();
  const [showComponent, setShowComponent] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleFinish = async () => {
    setLoading(true);
    const { data: response } = await SessionApi.finish(storeId, sessionId);
    if (response.success) {
      navigate(`/s/${storeId}/session`);
      setShowComponent(false);
    }
    setLoading(false);
  };
  return (
    <div
      className={`${
        showComponent ? "hidden md:flex" : "hidden"
      } absolute bottom-0 w-[82%] h-fit  justify-between items-center py-3`}
    >
      <Typography text="Click here to finish this current session" semibold />
      <Button
        text="Finish"
        rightIcon={<RightArrowIcon />}
        disabled={role !== "ADMIN"}
        onClick={handleFinish}
        loading={loading}
      />
    </div>
  );
};

export default FinishBottomBar;
