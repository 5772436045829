import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileIcon from "../assets/FileIcon";
import TrashIcon from "../assets/TrashIcon";
import UploadIcon from "../assets/UploadIcon";
import { sizeInMB } from "../utils/helperFunctions";
import ProgressBar from "./ProgressBar";
import Typography from "./Typography";

type DropzonePropType = {
  label?: string;
  className?: string;
  onUpload: (
    acceptedFiles?: any,
    setUploadProgress?: Dispatch<SetStateAction<number>>
  ) => Promise<void>;
};

const DropZone = ({
  label = "",
  className = "",
  onUpload,
}: DropzonePropType) => {
  const emptyFileData = { name: "", size: "" };
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileData, setFileData] = useState(emptyFileData);

  const onDrop = useCallback(async (acceptedFiles: any) => {
    setFileData({
      name: acceptedFiles[0].name,
      size: sizeInMB(acceptedFiles[0].size),
    });
    setUploadProgress(30);
    await onUpload(acceptedFiles[0], setUploadProgress)
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className={`flex flex-col items-center ${className}`}>
      {label && (
        <Typography
          text={label}
          className="text-xs xl:text-sm"
          semibold
          m={{ l: "ml-1" }}
        />
      )}
      {!!!uploadProgress ? (
        <div className="w-full mt-3 h-full" {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className="w-full cursor-pointer font-extralight rounded-lg border-2 border-dashed flex flex-col gap-y-1 items-center justify-center h-full min-h-[18vh]">
              <p>Drop the files here ...</p>
            </div>
          ) : (
            <div className="w-full cursor-pointer font-extralight rounded-lg border-2 border-dashed flex flex-col gap-y-1 items-center justify-center h-full min-h-[18vh]">
              <UploadIcon color="#932223" />
              <p className="text-[13px] 2xl:text-sm">
                Drag and drop a File, or{" "}
                <span className="text-pot-maroon">Browse</span>
              </p>
              <p className="text-[10px] 2xl:text-xs">
                The System Supports Only CSV / EXCEL File
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full mt-3 whitespace-nowrap border rounded-md px-5 py-3">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-3">
              <FileIcon color="#772021" />
              {fileData.name}
            </div>
            <button
              onClick={() => {
                setFileData(emptyFileData);
                setUploadProgress(0);
              }}
              className="text-pot-black hover:text-pot-darkmaroon"
            >
              <TrashIcon />
            </button>
          </div>
          <div className="flex gap-x-10 mt-3 items-center ">
            <ProgressBar progress={uploadProgress} />
            <p className="pb-1">{fileData.size} MB</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropZone;
