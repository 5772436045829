import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { appInfo } from "../../api/core";
import { AppInfoAtom } from "../../atom";

const useFetchAppInfo = () => {
  const [loading, setLoading] = useState(true);
  const setAppInfo = useSetRecoilState(AppInfoAtom);

  const fetch = async () => {
    const { data: response } = await appInfo();
    setAppInfo(response.data);
    setLoading(false)
  };

  useEffect(() => {
    fetch();
  }, []);

  return loading;
};

export default useFetchAppInfo;
