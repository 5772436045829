import {
  selCatActionType,
  selCatActionTypeEnum,
  selectedCategoryType,
} from "../types/Category.type";
import {
  addressActionType,
  addressActionTypeEnum,
  addressType,
} from "../types/StoreAddress.types";

const dummy = { label: "", value: "" };

export type baseActionType = {
  type: string | number;
  payload: any;
};

// reducer for managing category, department and subdepartment
export const categoryReducer = (
  state: selectedCategoryType,
  action: selCatActionType
) => {
  const { type, payload } = action;
  switch (type) {
    case selCatActionTypeEnum.setCat:
      if (!payload.value) {
        return { cat: dummy, dept: dummy, subDepts: [], disabled: 2 };
      }
      return { ...state, cat: payload, dept: dummy, subDepts: [], disabled: 1 };
    case selCatActionTypeEnum.setDept:
      if (!payload.value) {
        return { ...state, dept: dummy, subDepts: [], disabled: 1 };
      }
      return { ...state, dept: payload, subDepts: [], disabled: 0 };
    case selCatActionTypeEnum.setSubDepts:
      if (!payload.length) {
        return { ...state, subDepts: [] };
      }
      return { ...state, subDepts: payload };
    case selCatActionTypeEnum.clear:
      return { cat: state.cat, disabled: 1 };
    default:
      return state;
  }
};

// reducer for managing address lines of a store
export const storeAddressReducer = (
  state: addressType,
  action: addressActionType
): addressType => {
  const { type, payload } = action;

  const checkHasValue = (key: number) => {
    if (key === 1) {
      return payload || state.line2 || state.line3;
    }
    if (key === 2) {
      return state.line1 || payload || state.line3;
    }
    if (key === 3) {
      return state.line1 || state.line2 || payload;
    }
    if (key === 4) {
      return payload.address_3 || payload.address_2 || payload.address_1;
    }
  };

  switch (type) {
    case addressActionTypeEnum.line1:
      return {
        ...state,
        line1: payload || "",
        hasValue: checkHasValue(1) ? true : false,
      };
    case addressActionTypeEnum.line2:
      return {
        ...state,
        line2: payload || "",
        hasValue: checkHasValue(2) ? true : false,
      };
    case addressActionTypeEnum.line3:
      return {
        ...state,
        line3: payload || "",
        hasValue: checkHasValue(3) ? true : false,
      };
    case addressActionTypeEnum.clear:
      return { line1: "", line2: "", line3: "", hasValue: false };
    case addressActionTypeEnum.setAll:
      return {
        line1: payload.address_1,
        line2: payload.address_2,
        line3: payload.address_3,
        hasValue: checkHasValue(4) ? true : false,
      };
  }
};

// a reducer to manage storage locations
export const storageLocationReducer = (
  state: { name: string; [char: string]: string }[],
  action: baseActionType
): { name: string; [char: string]: string }[] => {
  const { type, payload } = action;

  const checkDupeName = () => {
    return !payload || state.find((each) => each.name === payload.name);
  };

  if (typeof type === "string") {
    switch (type) {
      case "ADD":
        if (checkDupeName()) {
          return [...state];
        }
        if (typeof payload === "string") {
          return [...state, { name: payload }];
        } else {
          return [...state, payload];
        }
      case "REMOVE":
        return state.filter((item) => item.name !== payload);
      case "LOCALEDIT":
        if (
          typeof payload === "object" &&
          typeof payload.index === "number" &&
          payload.index >= 0 &&
          payload.index < state.length
        ) {
          const newState = [...state];
          newState[payload.index] = {
            ...newState[payload.index],
            ...payload.newData,
          };
          return newState;
        }
        return state;

      case "ADD_ALL":
        return [...payload];
      default:
        return state;
    }
  } else if (typeof type === "number" && state[type]) {
    if (checkDupeName()) {
      return structuredClone(state);
    }
    state[type].name = payload;
  }
  console.log("Hereee $$$");
  return structuredClone(state);
};
