import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CrossIcon from "../assets/CrossIcon";
import TickIcon from "../assets/TickIcon";
import IconButton from "./IconButton";

type EditableFieldPropsType = {
  text: string;
  setText: (value: string) => void | Dispatch<SetStateAction<string>>;
  isOpen?: (value: boolean) => void;
  wait?: boolean;
  validate?: (val: any) => any;
  minWidth?: string;
  max?: string;
};

const EditableField = ({
  text,
  setText = () => {},
  isOpen = (value: boolean) => {},
  validate = (val: any) => val,
  wait = false,
  minWidth = "min-w-[5vw]",
  max,
}: EditableFieldPropsType) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [inputValue, setValue] = useState<string>(text);

  return (
    <>
      {edit ? (
        <div className={`min-w-[5vw] my-1 flex justify-between gap-x-1`}>
          <input
            value={inputValue}
            autoFocus
            type="text"
            max={max || "255"}
            onChange={(e) => setValue(validate(e.target.value))}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                isOpen(false);
                setText(inputValue);
                setEdit(false);
              }
            }}
            className={`${
              minWidth ? minWidth : "w-full"
            } px-2 py-1 shadow-[inset_0_0_0_1px_#1C3D96] ease-in-out transition outline-none rounded-md`}
          />
          <IconButton
            onClick={() => {
              isOpen(false);
              setText(inputValue);
              setEdit(false);
            }}
          >
            <TickIcon width="6" />
          </IconButton>
          <IconButton
            onClick={() => {
              isOpen(false);
              setValue(text);
              setEdit(false);
            }}
            type="secondary"
          >
            <CrossIcon width="6" />
          </IconButton>
        </div>
      ) : (
        <div
          onClick={() => {
            setValue(text);
            setEdit(true);
            isOpen(true);
            console.log("YOoooooo");
          }}
          className={`min-w-[5vw] my-1 px-2 py-1 rounded-md ${
            wait ? "cursor-wait" : "cursor-default"
          } hover:bg-pot-lightblue active:bg-pot-lightblue2 truncate`}
        >
          {text || "-"}
        </div>
      )}
    </>
  );
};

export default EditableField;
