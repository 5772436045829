import React, { useEffect, useState } from 'react'
import { StoreApi } from '../api/store'

const useValidateStoreNumber = ( store_no: string ) => {
    const [numberIsSearching, setNumberSearching] = useState(false)
    const [validNumber, setValidNumber] = useState("")
    const [calledNumberValidate,  setCalled ] = useState(false)

    const validateNumber = async () => {
        if(!store_no) {
          return
        }
        setNumberSearching(true)
        setCalled(false)
        const params = { store_no }
        const { data: response } = await StoreApi.is_available({ params })
        setCalled(true)
        setValidNumber(response.data.found)
        setNumberSearching(false)
    }

    useEffect(() => {
      setCalled(false)
      setValidNumber("")
    }, [store_no])

  return ({ validateNumber, numberIsSearching, validNumber, calledNumberValidate })
}

export default useValidateStoreNumber