import React from "react";
import { IconProp } from "./ArrowIcons";

const HamburgIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H13M1 6H13M1 11H13"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HamburgIcon;
