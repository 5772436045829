import React from "react";
import { IconProp } from "./ArrowIcons";

const EditIcon = ({ color}: IconProp) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33334 2.66668H2.66668C2.31305 2.66668 1.97392 2.80715 1.72387 3.0572C1.47382 3.30725 1.33334 3.64639 1.33334 4.00001V13.3333C1.33334 13.687 1.47382 14.0261 1.72387 14.2762C1.97392 14.5262 2.31305 14.6667 2.66668 14.6667H12C12.3536 14.6667 12.6928 14.5262 12.9428 14.2762C13.1929 14.0261 13.3333 13.687 13.3333 13.3333V8.66668M12.3333 1.66669C12.5986 1.40147 12.9583 1.25247 13.3333 1.25247C13.7084 1.25247 14.0681 1.40147 14.3333 1.66669C14.5986 1.9319 14.7476 2.29161 14.7476 2.66669C14.7476 3.04176 14.5986 3.40147 14.3333 3.66669L8.00001 10L5.33334 10.6667L6.00001 8.00002L12.3333 1.66669Z"
        stroke= {color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
