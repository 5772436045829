import React, { Dispatch, SetStateAction, useState } from "react";
import CrossIcon from "../../assets/CrossIcon";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import Button from "../Button";
import IconButton from "../IconButton";
import Modal from "../Modal";
import "./Scan.css";

type ScanComponentPropType = {
  setBarcode: Dispatch<SetStateAction<string>>;
  handleClose: () => void;
};

const ScanComponent = ({ setBarcode, handleClose }: ScanComponentPropType) => {
  // const [stopStream, setStopStream] = useState(false);
  const barcodeDivId = "qrRegion";

  useBarcodeScanner(
    barcodeDivId,
    () => {},
    (x, y) => {
      setBarcode(x);
      handleClose();
    }
  );

  return (
    <>
      <Modal handleCancel={handleClose}>
        <div className="px-2 pb-2 flex flex-col justify-between items-center">
          <div className="w-full flex justify-end pb-2">
            <IconButton type="tertiary" onClick={handleClose}>
                <CrossIcon />
            </IconButton>
          </div>
          <div
            className="w-full xl:w-[40vw] min-h-[40vh] max-h-[60vh] flex flex-col justify-center items-center"
            id={barcodeDivId}
          ></div>
        </div>
      </Modal>
    </>
  );
};

export default ScanComponent;
