import { atom } from "recoil";

export const StoresAtom = atom({
  key: "stores",
  default: [],
});

export const AllSessionsAtom = atom({
  key: "allSessions",
  default: [],
});

export const SelectedStoreAtom = atom({
  key: "selectedStore",
  default: {},
});

export const CategoryListAtom = atom({
  key: "categoryList",
  default: [],
});

export const SelectedSessionAtom = atom({
  key: "selectedSession",
  default: {},
});

export const ChangeInStoreListAtom = atom({
  key: "changeInStore",
  default: false,
});

export const OpenQuaggaAtom = atom({
  key: "openQuagga",
  default: false,
});

export const QuaggaBarcodeAtom = atom({
  key: "quaggaBarcode",
  default: "",
});

export const TableLoadingAtom = atom({
  key: "tableLoading",
  default: false,
});

export const ChangeScanPageAtom = atom({
  key: "changeScan",
  default: 1,
});

export const AccountInfoAtom = atom({
  key: "accountInfo",
  default: null,
});

export const AppInfoAtom = atom({
  key: "appInfo",
  default: null,
});

export const LoadingProgressAtom = atom({
  key: "loadingProgress",
  default: 0,
});

export const NewSessionCreatedAtom = atom({
  key: "newSessionCreated",
  default: false,
});

export const UnAuthorizedRedirectAtom = atom({
  key: "unAuthorisedRedirect",
  default: false,
});

export const SecuredAuthAtom = atom({
  key: "securedAuthState",
  default: false,
});

export const SessionSpecificAtom = atom({
  key: "sessionSpecificState",
  default: {},
});

export const SelectedLocationAtom = atom({
  key: "SelectedLocationState",
  default: null,
});
