import { authenticatedAxiosInstance as axios } from "./axios";

const list = (sId: string, params: any) => axios.get(`/live-inventory/${sId}/livesales`, { params })
const update = (sId: string, payload: any) => axios.patch(`/live-inventory/${sId}/livesales`, payload)
const status = (sId: string) => axios.get(`/live-inventory/${sId}/livesales/status`)

export const LivesalesApi = {
    list,
    update,
    status
}