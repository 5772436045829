import React from "react";
import { IconProp } from "./ArrowIcons";

const TrashIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      stroke="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.44444 12.8C5.65072 12.8 5.84855 12.7157 5.99442 12.5657C6.14028 12.4157 6.22222 12.2122 6.22222 12V7.2C6.22222 6.98783 6.14028 6.78434 5.99442 6.63432C5.84855 6.48429 5.65072 6.4 5.44444 6.4C5.23816 6.4 5.04033 6.48429 4.89447 6.63432C4.74861 6.78434 4.66667 6.98783 4.66667 7.2V12C4.66667 12.2122 4.74861 12.4157 4.89447 12.5657C5.04033 12.7157 5.23816 12.8 5.44444 12.8ZM13.2222 3.2H10.1111V2.4C10.1111 1.76348 9.86528 1.15303 9.42769 0.702944C8.99011 0.252856 8.39662 0 7.77778 0H6.22222C5.60338 0 5.00989 0.252856 4.57231 0.702944C4.13472 1.15303 3.88889 1.76348 3.88889 2.4V3.2H0.777778C0.571498 3.2 0.373667 3.28429 0.227806 3.43431C0.0819442 3.58434 0 3.78783 0 4C0 4.21217 0.0819442 4.41566 0.227806 4.56569C0.373667 4.71571 0.571498 4.8 0.777778 4.8H1.55556V13.6C1.55556 14.2365 1.80139 14.847 2.23897 15.2971C2.67656 15.7471 3.27005 16 3.88889 16H10.1111C10.7299 16 11.3234 15.7471 11.761 15.2971C12.1986 14.847 12.4444 14.2365 12.4444 13.6V4.8H13.2222C13.4285 4.8 13.6263 4.71571 13.7722 4.56569C13.9181 4.41566 14 4.21217 14 4C14 3.78783 13.9181 3.58434 13.7722 3.43431C13.6263 3.28429 13.4285 3.2 13.2222 3.2ZM5.44444 2.4C5.44444 2.18783 5.52639 1.98434 5.67225 1.83431C5.81811 1.68429 6.01594 1.6 6.22222 1.6H7.77778C7.98406 1.6 8.18189 1.68429 8.32775 1.83431C8.47361 1.98434 8.55556 2.18783 8.55556 2.4V3.2H5.44444V2.4ZM10.8889 13.6C10.8889 13.8122 10.8069 14.0157 10.6611 14.1657C10.5152 14.3157 10.3174 14.4 10.1111 14.4H3.88889C3.68261 14.4 3.48478 14.3157 3.33892 14.1657C3.19306 14.0157 3.11111 13.8122 3.11111 13.6V4.8H10.8889V13.6ZM8.55556 12.8C8.76184 12.8 8.95967 12.7157 9.10553 12.5657C9.25139 12.4157 9.33333 12.2122 9.33333 12V7.2C9.33333 6.98783 9.25139 6.78434 9.10553 6.63432C8.95967 6.48429 8.76184 6.4 8.55556 6.4C8.34928 6.4 8.15145 6.48429 8.00558 6.63432C7.85972 6.78434 7.77778 6.98783 7.77778 7.2V12C7.77778 12.2122 7.85972 12.4157 8.00558 12.5657C8.15145 12.7157 8.34928 12.8 8.55556 12.8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TrashIcon;
