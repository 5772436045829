import { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import InfoIcon from "../../assets/InfoIcon";
import { SelectedSessionAtom } from "../../atom";
import Checkbox from "../../components/Checkbox";
import IconButton from "../../components/IconButton";
import SortButton from "../../components/SortButton";
import InfoModal from "../../pages/Livesales/InfoModal";
import useClickOutside from "../useClickOutside";

const useLivesalesRecordColdef = ({
  callAgain,
  loading,
  sort,
  setSort,
  addToFinalAdjValue,
  setAddToFinalAdjValue,
}: // setShowSpecificInfo,
any) => {
  // const [addToFinalAdjValue, setAddToFinalAdjValue] = useState<any>([]);
  const [code, setCode] = useState<any>(null);
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const [isHover, setIsHover] = useState<any>(null);
  const [type, setType] = useState<0 | 1 | 2>(0);
  const [showSpecificInfo, setShowSpecificInfo] = useState<any>(null);
  const containerRef = useRef(null);
  useClickOutside(containerRef, () => {
    setIsHover(null);
    setShowSpecificInfo(null);
    setType(0);
  });

  const handleMouseOver = (id: any): void => {
    setIsHover(id);
  };

  useEffect(() => {
    if (!loading) {
      setAddToFinalAdjValue([]);
    }
  }, [loading]);

  const coldef = useMemo(
    () => [
      {
        header: () => (
          <SortButton
            keyVal="item_code"
            text="Item ID"
            value={sort}
            setValue={setSort}
          />
        ),
        accessorKey: "item_code",
        cell: ({ row, getValue }: any) => (
          <div className="w-full h-full relative">
            {getValue()}
            {row.original.flag && (
              <div className="absolute -top-4 h-12 w-1 rounded-r-md bg-pot-maroon -left-3"></div>
            )}
          </div>
        ),
      },
      {
        header: () => (
          <SortButton
            keyVal="size"
            text="Size"
            value={sort}
            setValue={setSort}
          />
        ),
        accessorKey: "size",
        size: 0,
      },
      {
        header: "Description",
        accessorKey: "item_name",
        size: 80,
        cell: ({ getValue }: any) => getValue(),
      },
      {
        header: "Time Sold",
        accessorKey: "sale_time",
        size: 80,
        cell: ({ getValue }: any) => getValue(),
      },
      {
        header: "Quantity Sold",
        accessorKey: "qty",
        size: 80,
        cell: ({ getValue }: any) => getValue(),
      },
      {
        header: "Category",
        accessorKey: "category",
        size: 80,
        cell: ({ getValue }: any) => getValue(),
      },
      {
        header: "Info",
        accessorKey: "counted_records",
        size: 10,
        cell: ({ row }: any) => {
          if (row.original.noData) {
            return <div className="min-h-[3vh]"></div>;
          }
          return (
            <div ref={containerRef}>
              <div
                onClick={(e) => {
                  const target = e.target as any;
                  const rect = target.getBoundingClientRect();
                  const threshold = window.innerHeight / 2;
                  if (rect.y < threshold) {
                    setType(1);
                  } else {
                    setType(2);
                  }
                }}
              >
                <IconButton
                  disabled={!row.original.counted_records.length}
                  onClick={() => {
                    console.log(row.original.counted_records);
                    handleMouseOver(row.original.counted_records);
                    setShowSpecificInfo(row.original.counted_records);
                    setCode(row.original.item_code);
                  }}
                  type="tertiary"
                >
                  <InfoIcon />
                </IconButton>
              </div>
              {isHover === row.original.counted_records ? (
                <InfoModal type={type} title={code} data={showSpecificInfo} />
              ) : null}
            </div>
          );
        },
      },
      {
        header: "Add to Final Adjustment",
        accessorKey: "add_to_final_adj",
        cell: ({ getValue, row }: any) => {
          if (row.original.noData) {
            return <div className="min-h-[2vh]"></div>;
          }
          return (
            <div className="w-full flex justify-center items-center">
              <Checkbox
                disabled={loading}
                checked={
                  addToFinalAdjValue.some(
                    (each: any) => each.id === row.original.id
                  )
                    ? addToFinalAdjValue.some(
                        (each: any) =>
                          each.id === row.original.id && each.add_to_final_adj
                      )
                    : getValue()
                }
                onClick={() => {
                  setAddToFinalAdjValue((prev: any) => {
                    // Update existing item if it exists in prev array
                    const updatedArray = prev.map((item: any) => {
                      if (item.id === row.original.id) {
                        return {
                          id: item.id,
                          add_to_final_adj: !item.add_to_final_adj,
                        };
                      }
                      return item;
                    });
                    // If the item doesn't already exist, append it to the updated array
                    if (
                      !updatedArray.some(
                        (item: any) => item.id === row.original.id
                      )
                    ) {
                      updatedArray.push({
                        id: row.original.id,
                        add_to_final_adj: !getValue(),
                      });
                    }

                    return updatedArray;
                  });
                }}
              />
              <div> {getValue()} </div>
            </div>
          );
        },
        size: 80,
      },
    ],
    [isHover, addToFinalAdjValue, loading]
  );
  return coldef;
};

export default useLivesalesRecordColdef;
