import { Dispatch, SetStateAction } from "react";
import Checkbox from "../../components/Checkbox";
import SortButton from "../../components/SortButton";
import { capitalize } from "../../utils/helperFunctions";

type uncountedColDefType = {
  sort: string;
  setSort: Dispatch<SetStateAction<string>>;
};

const useUncountedRecordColdef = ({ sort, setSort }: uncountedColDefType) => {
  // {
  //     "QOH": 6,
  //     "QUC": 1,
  //     "cat_name": "misc",
  //     "dept_name": "indoensia misc",
  //     "item_code": 51577,
  //     "item_name": "indoensia misc",
  //     "location": "location 1",
  //     "qty_kind": "CASE,PACK",
  //     "sub_dept_name": "indoensia misc",
  //     "upc": 900381,
  //     "upc_shared": 51654
  //   }
  const coldef = [
    {
      header: () => (
        <SortButton
          keyVal="upc"
          text="Shared UPC"
          value={sort}
          setValue={setSort}
        />
      ),
      accessorKey: "shared_upc",
    },
    { header: "Item code", accessorKey: "item_code", size: 120 },
    {
      header: "Item name",
      accessorKey: "item_name",
      cell: ({ row }: { row: any }) =>
        [
          // row.original.cat_name,
          // row.original.dept_name,
          row.original.item_name,
        ].join(" - "),
      size: 500,
    },
    {
      header: () => (
        <SortButton keyVal="size" text="Size" value={sort} setValue={setSort} />
      ),
      accessorKey: "size",
      size: 80,
    },
    // { header: "Storage location", accessorKey: "location", size: 80 },
    {
      header: "Category",
      accessorKey: "cat_name",
      size: 80,
      cell: ({ getValue, row }: any) =>
        row.original.noData ? (
          <div className="min-h-[2vh] w-full"></div>
        ) : (
          <div className="p-2">{capitalize(getValue())}</div>
        ),
    },
    {
      header: "Department",
      accessorKey: "dept_name",
      size: 80,
      cell: ({ getValue }: any) => capitalize(getValue()),
    },
    {
      header: "Sub-department",
      accessorKey: "sub_dept_name",
      size: 80,
      cell: ({ getValue }: any) => capitalize(getValue()),
    },
    // {
    //   header: () => (
    //     <SortButton keyVal="QOH" text="QOH" value={sort} setValue={setSort} />
    //   ),
    //   accessorKey: "QOH",
    //   size: 80,
    // },
    // {
    //   header: () => (
    //     <SortButton keyVal="QUC" text="QUC" value={sort} setValue={setSort} />
    //   ),
    //   accessorKey: "QUC",
    //   size: 80,
    // },
    // { header: "Recheck", id: "recheck", cell: () => <Checkbox checked={false} />}
  ];
  return coldef;
};

export default useUncountedRecordColdef;
