import React from "react";
import { IconProp } from "./ArrowIcons";

const CreateIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 6.33333V10.3333M6.00001 8.33333H10M14.6667 11.6667C14.6667 12.0203 14.5262 12.3594 14.2762 12.6095C14.0261 12.8595 13.687 13 13.3333 13H2.66668C2.31305 13 1.97392 12.8595 1.72387 12.6095C1.47382 12.3594 1.33334 12.0203 1.33334 11.6667V2.33333C1.33334 1.97971 1.47382 1.64057 1.72387 1.39052C1.97392 1.14048 2.31305 1 2.66668 1H6.00001L7.33334 3H13.3333C13.687 3 14.0261 3.14048 14.2762 3.39052C14.5262 3.64057 14.6667 3.97971 14.6667 4.33333V11.6667Z"
        stroke={ color || "inherit" }
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreateIcon;
