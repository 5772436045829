import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import TablePopup from "../../components/TableComponents/TablePopup";

type InfoModalType = {
  data: any;
  title: any;
  type: 0 | 1 | 2;
};

const InfoModal = ({ data, title, type }: InfoModalType) => {
  const containerRef = useRef<any>(null);
  console.log(data)
  if (!data) type = 0;

  const coldef = useMemo(
    () => [
      {
        header: "Time counted",
        accessorKey: "created_at",
        size: 80,
        cell: ({ getValue }: any) => getValue(),
      },
      {
        header: "Storage Location",
        accessorKey: "storage_location",
        size: 80,
        cell: ({ getValue }: any) => getValue(),
      },
      {
        header: "User",
        accessorKey: "created_by",
        size: 80,
        cell: ({ getValue }: any) => getValue(),
      },
      {
        header: "Quantity",
        accessorKey: "qty",
        size: 80,
        cell: ({ getValue }: any) => getValue(),
      },
    ],
    []
  );

  return (
    <div
      ref={containerRef}
      className={`absolute z-[100] right-5 ${
        type ? (type === 2 ? "bottom-12" : "") : "hidden"
      } border-2 p-3 border-pot-grey2 bg-white rounded-lg z-50`}
    >
      <div className="font-bold px-2 text-base inline-block align-middle"> Item ID: {title} </div>
      <TablePopup data={data} coldef={coldef} />
    </div>
  );
};

export default InfoModal;
