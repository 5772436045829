import React from "react";
import { useRecoilValue } from "recoil";
import CreateIcon from "../../assets/CreateIcon";
import { AccountInfoAtom, SelectedStoreAtom } from "../../atom";
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import { urls } from "../../utils/urls";

const Header = () => {
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const isAdmin = role === "ADMIN";
  const isManager = role === "MANAGER";
  const isEmp = role === "EMPLOYEE";

  return (
    <section className="text-sm hidden md:block">
      <Typography
        text="Live Inventory Sessions"
        bold
        xxl
        m={{ t: "mt-[4vh]", b: "mb-[1vh]" }}
      />
      {(isAdmin || isManager) &&(<Button
        type="secondary"
        text="Create a new session"
        leftIcon={<CreateIcon />}
        margins="mb-[2vh]"
        to={urls.sessionsCreate.replace(`:sid`, store.id)}
      />)}
    </section>
  );
};

export default Header;
