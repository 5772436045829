import React, { useEffect } from "react";

const useEnterSubmit = (
  validator: () => boolean,
  onSubmit: () => void | Promise<void>,
  deps: any[]
) => {
  const handleSubmit = (e: KeyboardEvent) => {
    if (e.key !== "Enter") return;
    if (validator()) {
      onSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleSubmit);
    return () => document.removeEventListener("keydown", handleSubmit);
  }, deps);
};

export default useEnterSubmit;
