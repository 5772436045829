import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue, useRecoilState } from "recoil";
import { SessionApi } from "../../api/session";
import { CategoryListAtom, SelectedStoreAtom } from "../../atom";
import {
  eachStringToDropDownObj,
  toCatList,
  toDeptListObj,
} from "../../utils/helperFunctions";

const useFetchCategories = (uploadId: string) => {
  const [categoryData, setCategoryData] = useRecoilState(CategoryListAtom) as any;
  const store = useRecoilValue(SelectedStoreAtom) as any;

  const fetchCategories = async () => {
    const { data: response } = await SessionApi.getCategories(
      store.id,
      uploadId
    );
    if (response.success) {
      const data = response.data;
      const catList = toCatList(data);
      const catDropdownList = eachStringToDropDownObj(catList);
      const deptListObj = toDeptListObj(data, catList);
      setCategoryData([catDropdownList, deptListObj, data]);
      toast.success("Successfully imported the categories, departments and sub-departments")
    }
  };

  useEffect(() => {
    if (uploadId) fetchCategories();
  }, [uploadId]);

  return(categoryData.length === 0)
};

export default useFetchCategories;
