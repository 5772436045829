import React from "react";
import { IconProp } from "./ArrowIcons";

const FileIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66669 1.33334H3.00002C2.6464 1.33334 2.30726 1.47382 2.05721 1.72387C1.80716 1.97392 1.66669 2.31305 1.66669 2.66668V13.3333C1.66669 13.687 1.80716 14.0261 2.05721 14.2762C2.30726 14.5262 2.6464 14.6667 3.00002 14.6667H11C11.3536 14.6667 11.6928 14.5262 11.9428 14.2762C12.1929 14.0261 12.3334 13.687 12.3334 13.3333V6.00001L7.66669 1.33334Z"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66669 1.33334V6.00001H12.3334"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileIcon;
