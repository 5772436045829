import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { InventoryApi } from "../../api/inventory";
import {
  AccountInfoAtom,
  SelectedSessionAtom,
  SessionSpecificAtom,
} from "../../atom";
import TableControl from "../../components/TableComponents/TableControl";
import HeaderAndControl from "../../components/TableComponents/TableHeaderAndControl";
import TableLayout from "../../components/TableComponents/TableLayout";
import useFetchStorageLocation from "../../hooks/fetch/useFetchStorageLocation";
import useInventoryRecordTableFetch from "../../hooks/fetch/useInventoryRecordTableFetch";
import useRedirectWithoutSession from "../../hooks/useRedirectWithoutSession";
import useUncountedRecordColdef from "../../hooks/coldef/useUncountedRecordColdef";
import {
  eachStringToDropDownObj,
  toCatList,
} from "../../utils/helperFunctions";
import { titles } from "../../utils/titles";

const UncountedItems = () => {
  const { role } = useRecoilValue(AccountInfoAtom) as any;
  const { lock_uncounted_list } = useRecoilValue(SessionSpecificAtom) as any;
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [length, setLength] = useState(100);
  const [catagoriesOptions, setCategoriesOption] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedSL, setSelectedSL] = useState<string>("");
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const slOptions = useFetchStorageLocation();
  const coldef = useUncountedRecordColdef({ sort, setSort });
  const { data, totalCount, totalPages } = useInventoryRecordTableFetch({
    type: "uncounted",
    search: searchValue,
    category: selectedCategory,
    sl: selectedSL,
    sort,
    setPage,
    length,
    page,
  });
  // const data: any[] = []
  // const totalCount = 0;
  // const totalPages = 0;
  useRedirectWithoutSession();
  const canViewTable = role === "ADMIN" || !lock_uncounted_list;

  const fetchCategories = async () => {
    const { data: response } = await InventoryApi.categories(session.id);
    const data = toCatList(response.data);
    setCategoriesOption(eachStringToDropDownObj(data));
  };

  useEffect(() => {
    document.title = titles.uncountedRecords;
    fetchCategories();
  }, []);

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <HeaderAndControl
        withDebounce={true}
        slOptions={slOptions}
        categoriesOptions={catagoriesOptions}
        heading="Uncounted Items"
        search={searchValue}
        setSearch={setSearchValue}
        setSelectedCategory={setSelectedCategory}
        setSelectedSL={setSelectedSL}
        selectedCategory={selectedCategory}
        selectedSL={selectedSL}
        hideLocationFilter
        showToggleSwitch
      />
      {canViewTable ? (
        <>
          <TableLayout smallLoader data={data} coldef={coldef} />
          <TableControl
            totalCount={totalCount}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            setLength={setLength}
            length={length}
          />
        </>
      ) : (
        <div className="flex justify-center items-center text-xl font-bold h-[80vh] text-pot-darkerblue">
          You don't have the access to view the page
        </div>
      )}
    </div>
  );
};

export default UncountedItems;
