import React, { Dispatch, SetStateAction } from "react";
import RadioButton from "../../components/RadioButton";
import Typography from "../../components/Typography";
import { formDataType } from "./ScanPage";

type BaseQuantitySelectType = {
  setFormData: Dispatch<SetStateAction<formDataType>>;
  formData: formDataType;
  keyValue: string;
  hint?: string | string[];
};

const BaseQuantitySelect = ({
  setFormData,
  formData,
  keyValue,
  hint,
}: BaseQuantitySelectType) => {
  const units = ["Units", "Packs", "Cases"];
  return (
    <div className="mt-[3vh] mb-[3vh]">
      <Typography text="Select base quantity" size="text-sm" semibold />
      <div className="flex gap-x-3 my-2">
        {units.map((each) => (
          <RadioButton
            label={each}
            selected={formData[keyValue] === each.toLowerCase()}
            onClick={() => {
              setFormData({...formData, [keyValue]: each.toLowerCase()})
            }}
          />
        ))}
      </div>
      {/* <Typography text="Hint goes here" size="text-[12px]" /> */}
      {hint &&
        (typeof hint === "string" ? (
          <p className="px-1 pt-1 font-light text-xs text-pot-maroon">
            {hint}
          </p>
        ) : hint.length === 1 ? (
          <p className="px-1 pt-1 font-light text-xs text-pot-maroon">
            {hint[0]}
          </p>
        ) : (
          <ul className="list-disc px-4">
            {hint.map((each) => (
              <li className="pt-1 font-light text-xs text-pot-maroon">
                {each}
              </li>
            ))}
          </ul>
        ))}
    </div>
  );
};

export default BaseQuantitySelect;
