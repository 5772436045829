import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { InventoryApi } from "../../api/inventory";
import ScanIcon from "../../assets/ScanIcon";
import {
  OpenQuaggaAtom,
  QuaggaBarcodeAtom,
  SelectedLocationAtom,
  SelectedSessionAtom,
  SelectedStoreAtom,
} from "../../atom";
import Button from "../../components/Button";
import { SelectableDropdownValueType } from "../../components/Dropdown";
import InputField from "../../components/InputField";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import ScanComponent from "../../components/Scan";
import Typography from "../../components/Typography";
import useFetchStorageLocation from "../../hooks/fetch/useFetchStorageLocation";
import { toastIt } from "../../utils/common";
import BaseQuantitySelect from "./BaseQuantitySelect";
import ToolTip from "../../components/ToolTip";
import SuccessIcon from "../../assets/SuccessIcon";
import ItemDetail from "../../components/ItemDetail";
import { motion } from "framer-motion";
import RetryIcon from "../../assets/RetryIcon";
import ItemsSelect from "./ItemsSelect";
import useFetchItemFromBarcode from "../../hooks/fetch/useFetchItemFromBarcode";
import CrossIcon from "../../assets/CrossIcon";
import DynamScanner from "../../components/DynamScanner";
import { toast } from "react-toastify";
import { symbolToQty } from "../../utils/constants";
import Modal from "../../components/Modal";
import useFetchAvailableLocations from "../../hooks/fetch/useFetchAvailableLocations";
import { AvailableLocationsAPI } from "../../api/availableLocations";
import { authenticatedAxiosInstance as axios } from "../../api/axios";
import Checkbox from "../../components/Checkbox";
import TextArea from "../../components/TextArea";

export type formDataType = {
  [char: string]: any;
};

type ScanPagePropType = {
  selected: boolean;
  setCallHistory: Dispatch<SetStateAction<boolean>>;
  editData: any;
  setEditData: Dispatch<SetStateAction<any>>;
};

const ScanPage = ({
  selected,
  setCallHistory,
  editData,
  setEditData,
}: ScanPagePropType) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [barcode, setBarcode] = useState<string>("");
  const [formData, setFormData] = useState<formDataType>({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [barcodeBlurred, setBarcodeBlurred] = useState(false);
  const [openItemSelect, setOpenItemSelect] = useState(false);
  const [barcodeSubmit, setBarcodeSubmit] = useState(false);
  const [itemsList, setItemsList] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [openDynamSanner, setOpenDynamScanner] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [barcodeTooltip, setBarcodeTooltip] = useState("");
  const [currentLocation, setCurrentLocation] = useState<any>();
  const [slOptions, setSlOptions] = useState<any>();
  const [selectedSl, setSelectedSl] = useState<SelectableDropdownValueType>({
    label: "",
    value: "",
  });
  const [openQuagga, setOpenQuagga] = useRecoilState(OpenQuaggaAtom);
  const [quaggaBarcode, setQuaggaBarcode] = useRecoilState(QuaggaBarcodeAtom);
  const setSelectedLocation = useSetRecoilState(SelectedLocationAtom);
  const session = useRecoilValue(SelectedSessionAtom) as any;
  const store = useRecoilValue(SelectedStoreAtom) as any;
  const [addNote, setAddNote] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<any>();
  const workflow = session.workflow;
  const { fetchLoading } = useFetchItemFromBarcode({
    isEdit: !!editData.upc,
    barcode,
    setErrors,
    setBarcode,
    barcodeBlurred,
    itemsList,
    setItemsList,
    selectedItem,
    setSelectedItem,
    setBarcodeBlurred,
    openItemSelect,
    setOpenItemSelect,
  });

  const notesOptions = [
    { label: "ADD BARCODE", value: "add_barcode" },
    { label: "RESTOCKS", value: "restocks" },
    { label: "REORDER", value: "reorder" },
    { label: "OTHER", value: "other" },
  ];

  const clearData = () => {
    setFormData({ qty_kind: formData?.qty_kind });
    setSelectedNote("");
    setBarcode("");
    setAddNote(false);
    setCallHistory(true);
  };

  const onCreate = async () => {
    if (addNote && (!selectedNote || !formData?.note)) {
      if (!selectedNote) {
        setErrors((prev: any) => ({
          ...prev,
          note_type: "This field is required",
        }));
      }
      if (!formData?.note) {
        setErrors((prev: any) => ({
          ...prev,
          note: "This field is required",
        }));
      }
      return;
    }
    setSubmitLoading(true);
    if (workflow === "REGISTER_51") {
      formData.qty = 0; // Set Quantity as 0 if workflow is REGISTER_51
    } else if (formData.qty === "") {
      setFormData((prev: any) => ({ prev, qty: 1 })); // Set Quantity as 1 by default if quantity field is empty
    }
    const kind = formData.qty_kind;
    if (!selectedItem && !barcode && !selectedSl.value && !kind) {
      toast.error("Please fill out the form to mark a scan");
      setSubmitLoading(false);
      return;
    }
    const payload: any = {
      ...formData,
      qty: formData.qty || 1,
      qty_kind: kind?.slice(0, kind.length - 1).toUpperCase(),
      barcode,
      item_id: selectedItem?.item_id,
      storage_location: selectedSl.label,
    };
    if (addNote && selectedNote) payload.note_type = selectedNote.value;
    if (!addNote) delete payload.note;

    const { data: response } = await InventoryApi.create(session.id, payload);
    if (response.success) {
      clearData();
      setShowSuccess(true);
    } else {
      const errorObj = toastIt(response.error, true);
      setErrors(errorObj);
    }
    setSubmitLoading(false);
    setBarcodeSubmit(false);
  };

  const onEdit = async () => {
    if (workflow !== "REGISTER_51" && formData.qty === "") {
      setFormData((prev: any) => ({ prev, qty: 1 })); // Set Quantity as 1 by default if quantity field is empty
    }
    setSubmitLoading(true);
    const kind = formData.qty_kind;
    const payload: any = {
      ...formData,
      qty: formData.qty || 1,
      qty_kind: kind?.slice(0, kind.length - 1).toUpperCase(),
      item_id: selectedItem?.item_id,
      storage_location: selectedSl.label,
    };
    if (addNote && selectedNote) payload.note_type = selectedNote?.value;
    const { data: response } = await InventoryApi.update(
      session.id,
      editData.li_record_id,
      payload
    );
    if (response.success) {
      clearData();
      setShowSuccess(true);
      setEditData({});
    } else {
      const errorObj = toastIt(response.error, true);
      setErrors(errorObj);
    }
    setSubmitLoading(false);
    setBarcodeSubmit(false);
  };

  const handleSelection = (value: any) => {
    const condition =
      currentLocation !== undefined &&
      value?.label !== undefined &&
      currentLocation.label !== value?.label;
    setSelectedSl(value || { label: "", value: "" });
    value ? setSelectedLocation(value?.value?.id) : setSelectedLocation(null);
    if (condition) {
      setOpenLocationModal(true);
    } else {
      addSelection(value);
    }
  };

  const addSelection = (value: any) => {
    value ? setSelectedLocation(value?.value?.id) : setSelectedLocation(null);
    const valueIsEmpty = Object.keys(value).length === 0;
    valueIsEmpty ? setCurrentLocation(undefined) : setCurrentLocation(value);
    delete errors.storage_location;
    setErrors({ ...errors });
  };

  const markUsed = async () => {
    try {
      await axios.get(
        `/live-inventory/${session.id}/storage-location/${currentLocation.value.id}/mark-used`
      );
    } catch (error) {
      console.error("Error while marking location as used:", error);
    }
  };

  const fetchAvailableLocations = async () => {
    const { data: response } = await AvailableLocationsAPI.list(session.id);
    if (response.success) {
      const r = response.data;
      const settable = r.map((each: any, ind: number) => ({
        label: each.name,
        value: r[ind],
        tag: each?.blocked_by, // New Tag Added. Key is yet to be added
      }));
      setSlOptions(settable);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAvailableLocations();
    }, 30000); //  30 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchAvailableLocations();
  }, [openLocationModal]);

  useEffect(() => {
    setSelectedLocation(null);
  }, []);

  useEffect(() => {
    const editableLocation = slOptions?.filter(
      (option: any) => option.label === editData?.storage_location
    );
    editableLocation && setCurrentLocation(editableLocation[0]);
    if (editData.barcode) {
      if (editData?.note || editData?.note_type) {
        setAddNote(true);
      } else {
        setAddNote(false);
      }
      setFormData({
        qty: editData.qty,
        qty_kind: editData.qty_kind.toLowerCase() + "s",
        note: editData?.note,
      });

      const editDataNote = notesOptions.find(
        (note: any) => note.value === editData?.note_type
      );
      setSelectedNote(editDataNote);
      setSelectedItem({
        item_name: editData.li_item_name,
        upc: editData.upc,
        item_code: editData.item_code,
      });
      setBarcode(editData.barcode);
      const sl =
        slOptions.find(
          (each: any) => each.label === editData.storage_location
        ) || ({ label: "", string: "" } as any);
      setSelectedSl(sl);
      sl && setSelectedLocation(sl?.value?.id);
    } else {
      setFormData((prev: any) => ({}));
      setBarcode("");
      setSelectedSl({ label: "", value: "" });
      setSelectedLocation(null);
    }
  }, [editData]);

  useEffect(() => {
    if (!showSuccess) return;
    const id = setTimeout(() => setShowSuccess(false), 3000);
    return () => clearTimeout(id);
  }, [showSuccess]);

  useEffect(() => {
    if (quaggaBarcode) {
      delete errors.barcode;
      delete errors.item_id;
      setErrors({ ...errors });
      setOpenQuagga(false);
      setBarcode(quaggaBarcode);
      setBarcodeBlurred(true);
      setQuaggaBarcode("");
    }
  }, [quaggaBarcode]);

  useLayoutEffect(() => {
    if (barcodeSubmit && selectedItem) {
      editData.barcode ? onEdit() : onCreate();
    }
  }, [barcodeSubmit, selectedItem]);

  useLayoutEffect(() => {
    if (barcode === "#$%^&") {
      setBarcode("");
    }
  }, [barcode]);

  useEffect(() => {
    if (barcodeTooltip) {
      const id = setTimeout(() => setBarcodeTooltip(""), 2000);
      return () => clearTimeout(id);
    }
  }, [barcodeTooltip]);

  return (
    <div
      className={`w-full max-w-full pr-[1vw] py-3 md:py-0 ${
        selected ? "flex flex-col" : "hidden md:flex md:flex-col"
      } text-sm`}
    >
      <Typography
        text="Scan"
        bold
        xxl
        className="hidden md:block"
        m={{ t: "mt-[4vh]", b: "mb-[1vh]" }}
      />
      <div className="w-full max-w-full h-[83vh] md:h-[86vh] overflow-y-auto text-sm flex flex-col bg-white rounded-md mb-[2.5vh] border p-3">
        <Typography
          text={`Store name: ${
            store.name.length > 40
              ? store.name.slice(0, 40) + "..."
              : store.name
          }`}
          bold
          m={{ b: "mb-[2.5vh]" }}
          size="text-[15px]"
        />
        <SingleSelectDropdown
          selectedValue={selectedSl}
          onSelect={(value) => {
            handleSelection(value);
          }}
          boxText="Select a storage location"
          label="Storage Location"
          optionList={slOptions}
          hint={errors.storage_location}
          emptyText="No storage locations assigned to you"
          disableDeselect
        />
        {/* CHANGE LOCATION POPUP */}
        {openLocationModal && (
          <Modal
            handleCancel={() => {
              setSelectedSl(currentLocation);
              currentLocation &&
                setSelectedLocation(currentLocation?.value?.id);
              setOpenLocationModal(false);
            }}
            width={"w-[90%] sm:w-[400px]"}
          >
            <div className="flex flex-col p-4 gap-4">
              <h3 className="text-lg font-bold text-center">
                Do you really want to Change the Location?
              </h3>
              <p className="text-xs text-center">
                Location once changed cannot be changed again
              </p>
              <div className="flex gap-2 w-full">
                <Button
                  type="primary_outline"
                  text="Discard"
                  fullWidth
                  margins="!mx-0"
                  parentFullWidth
                  onClick={() => {
                    setSelectedSl(currentLocation);
                    currentLocation &&
                      setSelectedLocation(currentLocation?.value?.id);
                    setOpenLocationModal(false);
                  }}
                />
                <Button
                  type="primary"
                  text="Yes, Continue"
                  fullWidth
                  margins="!mx-0"
                  parentFullWidth
                  onClick={() => {
                    addSelection(selectedSl);
                    markUsed();
                    setOpenLocationModal(false);
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // FOR BARCODE SCANNER 37581
              if (!barcodeBlurred) {
                setBarcodeBlurred(true);
                setBarcodeSubmit(true);
              }
            }
          }}
        >
          <InputField
            tooltip={barcodeTooltip}
            numericKeypad
            value={barcode}
            onChange={(text, inputSetter) => {
              if (editData.barcode) {
                return;
              }
              setBarcode("");
              const lastChar = text.slice(-1)[0];
              if (Object.keys(symbolToQty).includes(lastChar)) {
                setBarcode("#$%^&");
                const settableQty = parseInt(text);
                formData.qty_kind = symbolToQty[lastChar as "*" | "/" | "-"];
                if (settableQty) {
                  formData.qty = settableQty;
                }
                setFormData({ ...formData });
                let messageProtagonist = [];
                if (settableQty)
                  messageProtagonist.push(`${settableQty} quantity`);
                if (lastChar)
                  messageProtagonist.push(
                    symbolToQty[lastChar as "*" | "/" | "-"]
                  );
                setBarcodeTooltip(
                  `${messageProtagonist.join(" and ")} ${
                    messageProtagonist.length - 1 ? "are" : "is"
                  } selected`
                );
              } else {
                if (
                  Object.keys(symbolToQty).some((each) => text.includes(each))
                ) {
                  let index = 0;
                  let settableKind = "";
                  text.split("").forEach((each, idx) => {
                    if (index) return;
                    if (Object.keys(symbolToQty).includes(each)) {
                      index = idx;
                      settableKind = symbolToQty[each as "*" | "/" | "-"];
                    }
                  });
                  if (index) {
                    const qtyFromInput = text.slice(0, index);
                    formData.qty = qtyFromInput || "";
                    formData.qty_kind = settableKind;
                    setBarcode(text.slice(index + 1));
                    inputSetter(text.slice(index + 1));
                    setFormData({ ...formData });
                    setBarcodeBlurred(true);
                    let messageProtagonist = [];
                    if (qtyFromInput)
                      messageProtagonist.push(`${qtyFromInput} quantity`);
                    if (settableKind) messageProtagonist.push(settableKind);
                    setBarcodeTooltip(
                      `${messageProtagonist.join(" and ")} ${
                        messageProtagonist.length - 1 ? "are" : "is"
                      } selected`
                    );
                  }
                } else {
                  setBarcode(text);
                }
              }
              delete errors.barcode;
              delete errors.item_id;
              setErrors({ ...errors });
            }}
            doNotChangeInput={!!editData.barcode}
            label="Barcode scanner"
            className="max-w-xl mt-[2.5vh] mb-[2.5vh] "
            placeholder="Enter UPC barcode or item barcode. "
            enterToBlur
            setOnBlur={setBarcodeBlurred}
            hint={[errors.barcode, errors.item_id].join(" ")}
            rightIcon={
              barcode &&
              (fetchLoading ? (
                <motion.div
                  animate={{ rotate: -360 }}
                  transition={{
                    ease: "linear",
                    duration: 1,
                    repeat: Infinity,
                  }}
                  className="flex justify-center items-center stroke-pot-maroon"
                >
                  <RetryIcon />
                </motion.div>
              ) : (
                selectedItem &&
                !!!editData.upc && (
                  <button
                    onClick={() => {
                      setBarcode("");
                      setSelectedItem(null);
                    }}
                    className="flex items-center justify-center hover:stroke-pot-maroon"
                  >
                    <CrossIcon />
                  </button>
                )
              ))
            }
            // allowOutsideSet
          />
        </div>
        <div className="flex flex-wrap gap-2">
          {/* <Button
          text="Scan Barcode 1"
          disabled={!!editData.upc}
          type="secondary"
          leftIcon={<ScanIcon color="white" />}
          onClick={() => setOpenModal(true)}
        /> */}
          <Button
            text="Scan Barcode 1"
            disabled={!!editData.upc}
            type="secondary"
            leftIcon={<ScanIcon color="white" />}
            onClick={() => {
              setIsActive(true);
              setOpenDynamScanner(true);
            }}
          />
          <Button
            text="Scan Barcode 2"
            disabled={!!editData.upc}
            type="secondary"
            leftIcon={<ScanIcon color="white" />}
            onClick={() => {
              setOpenQuagga(true);
            }}
          />
        </div>
        {selectedItem && <ItemDetail data={selectedItem} workflow={workflow} />}
        <BaseQuantitySelect
          setFormData={(value) => {
            setFormData(value);
            delete errors.qty_kind;
            setErrors({ ...errors });
          }}
          formData={formData}
          keyValue={"qty_kind"}
          hint={errors.qty_kind}
        />
        {workflow === "REGISTER_51" && (
          <div className="mt-6">
            <Checkbox
              checked={addNote}
              label="Do you want to add a note?"
              onClick={() => setAddNote((prev: boolean) => !prev)}
              small
            />
            {addNote && (
              <div className="flex flex-col gap-6">
                <Typography text="Notes" bold className="mt-4" />
                <SingleSelectDropdown
                  boxText="What is this note about?"
                  selectedValue={selectedNote}
                  optionList={notesOptions}
                  onSelect={(value: any) => {
                    setSelectedNote(value);
                    delete errors?.note_type;
                  }}
                  hint={errors?.note_type}
                />
                <TextArea
                  onChange={(e) => {
                    setFormData((prev: any) => ({
                      ...prev,
                      note: e,
                    }));
                    delete errors?.note;
                  }}
                  placeholder="Additional Information"
                  value={formData?.note}
                  hint={errors?.note}
                />
              </div>
            )}
          </div>
        )}
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              editData.barcode ? onEdit() : onCreate();
            }
          }}
        >
          <InputField
            numericKeypad
            value={formData.qty}
            label="Quantity"
            placeholder="1"
            validate={(value) =>
              parseInt(value) ? String(parseInt(value)) : ""
            }
            onChange={(value) => {
              setFormData({
                ...formData,
                qty: parseInt(value) ? parseInt(value) : "",
              });
              delete errors.qty;
              setErrors({ ...errors });
            }}
            hint={errors.qty}
            className={`${workflow === "REGISTER_51" ? "hidden" : "block"}`}
          />
        </div>
        <div className="w-full h-full py-5 flex justify-center items-center">
          <div className="relative w-full flex justify-center">
            <Button
              loading={submitLoading}
              onClick={() => (editData.barcode ? onEdit() : onCreate())}
              text={"Submit"}
            />
            {showSuccess && (
              <ToolTip
                leftIcon={<SuccessIcon color="#132044" />}
                message={
                  editData.barcode
                    ? "Successfully Updated"
                    : "Successfully Scanned"
                }
              />
            )}
          </div>
        </div>
      </div>
      {openModal && (
        <ScanComponent
          setBarcode={(value) => {
            delete errors.barcode;
            delete errors.item_id;
            setErrors({ ...errors });
            setBarcode(value);
            setBarcodeBlurred(true);
          }}
          handleClose={() => setOpenModal(false)}
        />
      )}
      {openDynamSanner && (
        <DynamScanner
          setIsActive={setIsActive}
          isActive={isActive}
          openDynamScanner={openDynamSanner}
          setBarcode={(value) => {
            setBarcode(value);
            setBarcodeBlurred(true);
          }}
          setOpenDynamScanner={setOpenDynamScanner}
        />
      )}
      {!!itemsList.length && openItemSelect && (
        <ItemsSelect
          data={itemsList}
          workflow={workflow}
          clear={() => {
            setBarcode("");
          }}
          select={(item) => setSelectedItem(item)}
          handleCancel={() => {
            setItemsList([]);
            setOpenItemSelect(false);
          }}
        />
      )}
    </div>
  );
};

export default ScanPage;
