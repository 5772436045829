import React from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

type ModalPropType = {
  handleCancel: () => void;
  children: React.ReactNode;
  long?: boolean;
  width?: string;
};

const Modal = ({
  handleCancel = () => {},
  children,
  long = false,
  width,
}: ModalPropType) => {
  return createPortal(
    <>
      <div onClick={handleCancel}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0 }}
          className="w-screen h-screen fixed top-0 left-0 z-[48] bg-pot-darkblue"
        ></motion.div>
        <div className="w-screen h-screen fixed top-0 left-0 z-[49] backdrop-blur-[1px]"></div>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="pointer-events-none grid px-2 py-2 place-items-center w-screen z-[50] h-screen fixed top-0 left-0"
      >
        {/* <div className="bg-white">
          heloefowiehfowiefowie
        </div> */}
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          // initial={{ scaleY: 0 }}
          // animate={{ scaleY: "100%" }}
          // exit={{ scaleY: 0 }}
          // transition={{ delay: 0.2, duration: 0.2 }}
          className={`select-none z-[50] pointer-events-auto ${
            long ? "max-w-[80vw]" : " w-full max-w-2xl"
          } ${width} h-fit flex flex-col p-3 bg-white rounded-lg shadow-lg`}
        >
          {children}
        </div>
      </div>
    </>,
    document.getElementById("modal")!
  );
};

export default Modal;
