import React from "react";
import { IconProp } from "./ArrowIcons";

const InfoIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      stroke={color || "inherit"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.3"
      viewBox="0 0 24 24"
    >
      <path d="M12 11.5v5"></path>
      <path d="m12 7.511.01-.011"></path>
      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"></path>
    </svg>
  );
};

export default InfoIcon;
