import React from "react";
import EditIcon from "../assets/EditIcon";
import { showConvertedDate } from "../utils/helperFunctions";
import IconButton from "./IconButton";
import Typography from "./Typography";

const Log = ({
  data,
  onEdit,
}: {
  data: any;
  onEdit: (value: string) => void;
}) => {

  return (
    <div className="w-full flex justify-between rounded-md border bg-white px-4 py-2 gap-x-10">
      <div className="self-stretch flex flex-col justify-between w-full">
        <Typography text={data.li_item_name} semibold size="text-[13px]" />
        <Typography
          text={data.storage_location}
          size="text-[11px]"
          className="text-pot-black"
        />
        <Typography
          text={showConvertedDate(data.scan_time)}
          size="text-[11px]"
          className="text-pot-black"
        />
      </div>
      <div className="self-stretch flex flex-col justify-between w-fit ">
        <IconButton
          onClick={() => onEdit(data)}
          type="primary_outline"
          center={false}
        >
          <EditIcon />
        </IconButton>
        <Typography
          text={data.qty}
          semibold
          size="text-[15px]"
          className="text-pot-darkmaroon text-right my-2"
        />
        <Typography
          text={data.qty_kind.toLowerCase() + "s"}
          size="text-[11px]"
          className="text-pot-black text-right"
        />
      </div>
    </div>
  );
};

export default Log;
