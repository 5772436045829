import axios from "axios";
import { getFromLocalStorage } from "../utils/localStorage";
import { Dispatch, SetStateAction } from "react";
import { toastIt } from "../utils/common";

// export const BASE_URL = "https://hw-api.pixelotechapp.dev/"; // NEW STAGE URL
export const BASE_URL = "https://hw-backend.pixelotechapp.dev/"; // STAGE URL
// export const BASE_URL = "https://hw-backend-dev.pixelotechapp.dev/"; // DEV URL

export const normalAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const authenticatedAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleErrorResponse = (
  error: any,
  setServerErrorMessage: any,
  setUnAuthorisedRedirect: any
) => {
  if (error.response?.status === 401 || error.response?.status === 498) {
    localStorage.removeItem("token");
    localStorage.removeItem("user_type");
    window.location.href = "/login";
  } else if (error.response?.status === 403) {
    setUnAuthorisedRedirect(true);
  } else {
    if (![400, 401, 422, 404, 403, 426, 498].includes(error.response?.status)) {
      if (error.response.data.error) {
        if (error.response.status === 500) {
          return error.response;
        } else {
          toastIt(error.response.data.error);
          return error.response;
        }
      }
      setServerErrorMessage(
        "Unknown error: please try again later and contact tech support"
      );
    }
  }
  return error.response;
};

export const registerIntercepts = (
  setLoading: Dispatch<SetStateAction<boolean>>,
  setServerErrorMessage: Dispatch<SetStateAction<string>>,
  setUnAuthorisedRedirect: Dispatch<SetStateAction<boolean>>
) => {
  authenticatedAxiosInstance.interceptors.response.use(
    (response: any) => response,
    (error: any) =>
      handleErrorResponse(error, setServerErrorMessage, setUnAuthorisedRedirect)
  );
  authenticatedAxiosInstance.interceptors.request.use(
    (config: any) => {
      config.headers["Authorization"] = `${getFromLocalStorage("token")}`;
      return config;
    },
    (error: any) => Promise.reject(error)
  );
  normalAxiosInstance.interceptors.response.use(
    (response: any) => response,
    (error: any) => error.response
  );
  setLoading(false);
};
