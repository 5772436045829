import React, { Dispatch, SetStateAction } from "react";

type SectionSelectorBarType = {
  selectedPage: 0 | 1;
  setSelectedPage: Dispatch<SetStateAction<0 | 1>>;
  names: string[]
};

const SectionSelectorBar = ({
  selectedPage,
  setSelectedPage,
  names=[]
}: SectionSelectorBarType) => {
  return (
    <div className="bg-pot-base z-[35] sticky text-sm top-0 w-full flex md:hidden pr-3">
      <button
        onClick={() => setSelectedPage(0)}
        className={`py-2 whitespace-nowrap px-4 border-b ${
          selectedPage === 0 && "border-pot-black"
        }`}
      >
        {names[0]}
      </button>
      <button
        onClick={() => setSelectedPage(1)}
        className={`py-2 whitespace-nowrap px-4 border-b ${
          selectedPage === 1 && "border-pot-black"
        }`}
      >
        {names[1]}
      </button>
      <div className="w-full border-b "></div>
    </div>
  );
};

export default SectionSelectorBar;
