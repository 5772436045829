import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from "react";
import CrossIcon from "../../assets/CrossIcon";
import PlusIcon from "../../assets/PlusIcon";
import TrashIcon from "../../assets/TrashIcon";
import Button from "../../components/Button";
import EditableField from "../../components/EditableField";
import IconButton from "../../components/IconButton";
import InputField from "../../components/InputField";
import Modal from "../../components/Modal";
import Typography from "../../components/Typography";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import { useRecoilValue } from "recoil";
import { AppInfoAtom, SelectedSessionAtom } from "../../atom";
import { authenticatedAxiosInstance } from "../../api/axios";
import {
  eachStringToDropDownObj,
  removeDuplicates,
  toCatList,
  toDeptListObj,
} from "../../utils/helperFunctions";
import { categoryReducer } from "../../utils/stateFunctions";
import { SelectableDropdownValueType } from "../../components/Dropdown";
import { selCatActionTypeEnum } from "../../types/Category.type";
import CheckboxDropdown from "../../components/CheckboxDropdown";
import { joiner } from "../../utils/common";
import useCategoryOrder from "../../hooks/useCategoryOrder";
import EditIcon from "../../assets/EditIcon";

function mapToObjects(
  array: string[]
): { cat_name: string; dept_name: string; sub_dept_name: string }[] {
  return array.map((entry) => {
    const [cat_name, dept_name, sub_dept_name] = entry.split("$$$$");
    return {
      cat_name,
      dept_name,
      sub_dept_name,
    };
  });
}

function convertArrayToCategories(inputArray: any): {
  convertedArray: string[];
  uniqueCatNames: string[];
} {
  const convertedArray: string[] = [];
  const uniqueCatNames: string[] = [];

  inputArray.forEach((item: any) => {
    const { cat_name, dept_name, sub_dept_name } = item;
    const categoryString = `${cat_name}$$$$${dept_name}$$$$${sub_dept_name}`;
    convertedArray.push(categoryString);

    if (!uniqueCatNames.includes(cat_name)) {
      uniqueCatNames.push(cat_name);
    }
  });

  return { convertedArray, uniqueCatNames };
}

type storageLocationType = {
  locations: any[];
  loading: boolean;
  add: (payload: any) => void;
  remove: (payload: string) => void;
  change: (index: number, payload: any) => void;
  error: any;
  showableCatList: any;
  setShowableCatList: any;
  changeLocal: any;
  setError: Dispatch<SetStateAction<any>>;
};

const StorageLocationForm = ({
  locations,
  add,
  remove,
  change,
  error,
  setError,
  loading,
  showableCatList,
  setShowableCatList,
  changeLocal,
}: storageLocationType) => {
  const [state, dispatch] = useReducer(categoryReducer, {
    disabled: 2,
    cat: { label: "", value: "" },
  });

  const [newLocName, setNewLocName] = useState("");
  const [openModal, setOpenModal] = useState<any>();
  const [openStorageLocationModal, setStorageLocationModal] = useState(false);
  const [storageArea, setStorageArea] = useState({ label: "", value: "" });
  const [allOptionsData, setAllOptionsData] = useState<any>([]);
  const [orderList, setOrderList] = useState<string[]>([]);
  const [deptOptions, setDeptOptions] = useState<SelectableDropdownValueType[]>(
    []
  );
  const [subDeptsOptions, setSubDeptsOptions] = useState<
    SelectableDropdownValueType[]
  >([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<any>("");
  const [localEditIndex, setLocalEditIndex] = useState<any>("");
  const [editName, setEditName] = useState("");
  const showableDeptList = useCategoryOrder({
    order: orderList,
    list: showableCatList,
  });

  const session = useRecoilValue(SelectedSessionAtom) as any;
  const { storage_location_areas } = useRecoilValue(AppInfoAtom) as any;
  const [removalName, setRemovalName] = useState<any>("");

  const storageAreaOptions = storage_location_areas.map(
    (each: any, index: Number) => ({
      label: each,
      value: each.split(" ").join("_"),
    })
  );
  const getCategories = async () => {
    try {
      const { data: response } = await authenticatedAxiosInstance.get(
        `/cat-dept`
      );
      if (response.success) {
        const data = response.data;
        const catList = toCatList(data);
        const catDropdownList = eachStringToDropDownObj(catList);
        const deptListObj = toDeptListObj(data, catList);
        setAllOptionsData([catDropdownList, deptListObj, data]);
      }
    } catch (error) {
      console.log(error, "error in api call new");
    }
  };

  const addToList = () => {
    let { cat, dept, subDepts } = state;
    cat = cat.value;
    dept = dept.value;
    const settable = subDepts.map(
      (each: any) => `${cat}${joiner}${dept}${joiner}${each.value}`
    );
    setShowableCatList(Array.from(new Set([...showableCatList, ...settable])));
    const newOrderList = orderList.filter((each) => each !== cat);
    setOrderList([cat, ...newOrderList]);
    dispatch({ type: selCatActionTypeEnum.clear });
  };

  const destroy = (value: string, cat: string) => {
    const newCatList = showableCatList.filter((item: any) => item !== value);
    const hasCat = newCatList.find((each: any) => {
      return each.split(joiner)[0] === cat;
    });
    setShowableCatList(newCatList);
    if (!hasCat) {
      const filteredOrderList = orderList.filter((item) => item !== cat);
      setOrderList(filteredOrderList);
    } else {
      setOrderList([...orderList]);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (error.name) {
      setError({});
    }
  }, [newLocName]);

  useEffect(() => {
    // To Sort the Department Options
    if (state.disabled === 1 && allOptionsData[1]) {
      const data = allOptionsData?.[1]?.[state.cat?.value] as any[];
      data.sort((a, b) => a.label.localeCompare(b.label));
      setDeptOptions(data);
    }
    // To Sort the Sub-Department Options
    if (state.disabled === 0 && allOptionsData[2]) {
      const data = allOptionsData[2] as any[];
      const subDeptObjs = data.filter(
        (each) =>
          each.cat_name === state.cat?.value &&
          each.dept_name === state.dept?.value
      );
      const subDeptVals = removeDuplicates(
        subDeptObjs.map((each) => each.sub_dept_name)
      );
      subDeptVals.sort((a, b) => a.localeCompare(b));
      setSubDeptsOptions(eachStringToDropDownObj(subDeptVals));
    }
  }, [state]);

  useEffect(() => {
    if (editId) {
      const findEditingLocationById = locations.filter((location) => {
        return location.id === editId;
      });
      const { name, area, categories } = findEditingLocationById[0];
      const { convertedArray, uniqueCatNames } =
        convertArrayToCategories(categories);
      setNewLocName(name);
      setStorageArea({ label: area, value: area });
      setOrderList(uniqueCatNames);
      setShowableCatList(convertedArray);
      return;
    }
    if (!editId && editName) {
      const index = locations.findIndex((location) => {
        return location.name === editName;
      });
      const { name, area, categories } = locations[index];
      const { convertedArray, uniqueCatNames } =
        convertArrayToCategories(categories);
      setNewLocName(name);
      setStorageArea({ label: area, value: area });
      setOrderList(uniqueCatNames);
      setShowableCatList(convertedArray);
      setLocalEditIndex(index);
    }
  }, [editId, editName]);

  const onSaveHandler = () => {
    const categories = mapToObjects(showableCatList);
    let payload = {
      name: newLocName,
      area: storageArea.value,
      categories: categories,
    };
    add(payload);
    setShowableCatList([]);
    setOrderList([]);
    setNewLocName("");
    setStorageArea({ label: "", value: "" });
    setStorageLocationModal(false);
    setEditId("");
    setEditName("");
    setIsEditing(false);
    setLocalEditIndex("");
  };

  const onEditHandler = () => {
    const categories = mapToObjects(showableCatList);
    let payload = {
      name: newLocName,
      area: storageArea.value,
      categories: categories,
    };

    if (editId) {
      change(editId, payload);
    }

    if (localEditIndex === 0 || localEditIndex) {
      changeLocal({ newData: payload, index: localEditIndex });
    }

    setShowableCatList([]);
    setOrderList([]);
    setNewLocName("");
    setStorageArea({ label: "", value: "" });
    setStorageLocationModal(false);
    setLocalEditIndex("");
    setEditId("");
    setEditName("");
    setIsEditing(false);
  };

  return (
    <div className="w-[90%] p-5 h-full overflow-auto text-sm">
      {/* old functionality for add new locations */}
      {/* <div className="flex items-end gap-x-4">
        <InputField
          value={newLocName}
          placeholder="Type storage location name"
          onChange={(val) => setNewLocName(val)}
          label="Add Storage Location"
          hint={error.name}
        />
        <div className={`h-fit ${error?.name && 'mb-5'}`}>
          <Button
            loading={loading}
            onClick={() => {
              setNewLocName('');
              add(newLocName);
            }}
            leftIcon={<PlusIcon />}
            small
            text="Add"
          />
        </div>
      </div> */}
      {/* old code */}

      {/* new ui changes --- Ashfaque */}
      <div className="flex items-center justify-between">
        <Typography
          text="Manage storage Locations"
          className="py-4 text-sm xl:text-base text-dark-brown"
          semibold
        />
        <div className={`h-fit ${error?.name && "mb-5"}`}>
          <Button
            loading={loading}
            onClick={() => {
              setNewLocName("");
              setStorageLocationModal(true);
            }}
            leftIcon={<PlusIcon />}
            small
            text="Add location"
          />
        </div>
      </div>
      {/* new ui changes */}

      {/* old code */}
      {/* <Typography
        text="Manage storage Locations"
        className="py-4 text-sm xl:text-[15px]"
        semibold
      /> */}
      {/* old code */}

      {/* old code for locations listing */}
      {/* {locations.map((location, id) => (
        <div className="flex justify-between">
          <div className="w-[80%]">
            <EditableField
              text={location.name}
              setText={(value) => change(id, value)}
            />
          </div>
          <button
            onClick={() => setOpenModal(location)}
            className="text-pot-black hover:text-red-600"
          >
            <TrashIcon />
          </button>
        </div>
      ))} */}
      {/* old code for locations listing */}

      {/* new code for locations listing -- Ashfaque */}
      {locations.length > 0 ? (
        <div className="mt-[38px] flex flex-col gap-4">
          {locations.map((location, id) => {
            return (
              <div key={id} className="flex justify-between">
                <div className="w-[60%] flex gap-3 items-center">
                  <p className="text-dark-brown text-xs leading-[18px]">
                    {location?.name}
                  </p>
                  <div className="px-2 py-0.5 text-xs text-black rounded-md bg-light-blue">
                    <p>{location?.area}</p>
                  </div>
                  <div className="px-2 py-0.5 text-xs text-black rounded-md bg-light-pink">
                    <p>{location?.categories?.length} Categories</p>
                  </div>
                </div>

                <div className="flex items-center gap-5">
                  <button
                    className=""
                    onClick={() => {
                      setEditName(location?.name);
                      setEditId(location?.id);
                      setStorageLocationModal(true);
                      setIsEditing(true);
                    }}
                  >
                    <EditIcon color="#132044" />
                  </button>
                  <button
                    className="text-pot-darkblue hover:text-red-400"
                    onClick={() => {
                      setRemovalName(location?.name);
                      setOpenModal(true);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={`w-full h-[36vh] font-semibold text-lg text-pot-black/50 flex justify-center items-center`}
        >
          No Storage Locations
        </div>
      )}

      {/* old code -- delete modal */}
      {/* {openModal && (
        <Modal handleCancel={() => setOpenModal(null)}>
          <div className="py-6 text-sm px-14">
            <p className="font-semibold">Delete Storage Location</p>
            <p>
              Are you sure you want to delete this storage location from this
              store?
            </p>
            <div className="flex justify-center w-full mt-4 text-xs gap-x-3">
              <Button
                text="Delete"
                leftIcon={<TrashIcon />}
                onClick={() => {
                  remove(openModal.name);
                  setOpenModal(null);
                }}
              />
              <Button
                type="primary_outline"
                text="Cancel"
                leftIcon={<CrossIcon />}
                onClick={() => setOpenModal(null)}
              />
            </div>
          </div>
        </Modal>
      )} */}
      {/* old code -- delete modal  */}

      {/* new delete modal --- Ashfaque */}
      {openModal && (
        <Modal handleCancel={() => setOpenModal(null)}>
          <div className="py-6 text-sm px-14">
            <p className="font-semibold">Delete Storage Location</p>
            <p>
              Are you sure you want to delete this storage location from this
              store?
            </p>
            <div className="flex justify-center w-full mt-4 text-xs gap-x-3">
              <Button
                text="Delete"
                leftIcon={<TrashIcon />}
                onClick={() => {
                  remove(removalName);
                  setOpenModal(null);
                }}
              />
              <Button
                type="primary_outline"
                text="Cancel"
                leftIcon={<CrossIcon />}
                onClick={() => setOpenModal(null)}
              />
            </div>
          </div>
        </Modal>
      )}
      {/* new delete modal --- Ashfaque */}

      {/* new ui changes --- modal for storage location --- Ashfaque */}
      {openStorageLocationModal && (
        <Modal
          handleCancel={() => {
            setStorageLocationModal(false);
            setIsEditing(false);
            setEditId("");
            setEditName("");
            setLocalEditIndex("");
            setShowableCatList([]);
            setOrderList([]);
            setNewLocName("");
            setStorageArea({ label: "", value: "" });
            setStorageLocationModal(false);
          }}
          long
        >
          <div className="pb-2 pt-7 px-7 lg:w-[835px] min-h-[80vh] max-h-[400px] overflow-auto no-scrollbar">
            <div>
              <Typography
                text={
                  isEditing ? "Edit Storage Location" : "Add Storage Location"
                }
                bold
                size="text-[24px]"
              />
            </div>

            <div className="mt-[33px] mb-[33px] flex gap-[33px] items-center">
              <InputField
                value={newLocName}
                placeholder="Storage Location name"
                onChange={(val) => setNewLocName(val)}
                label="Name"
                hint={error.name}
              />
              <SingleSelectDropdown
                selectedValue={storageArea}
                onSelect={(value) => {
                  setStorageArea(value || { label: "", value: "" });
                }}
                boxText="Select Storage Area"
                label="Area"
                optionList={storageAreaOptions}
                disabled={!newLocName}
              />
            </div>

            <div className="flex justify-between gap-[25px] items-end">
              <div className="w-[174px]">
                <SingleSelectDropdown
                  selectedValue={state.cat}
                  onSelect={(value?: SelectableDropdownValueType) =>
                    dispatch({
                      type: selCatActionTypeEnum.setCat,
                      payload: value || { label: "", value: "" },
                    })
                  }
                  label="Category"
                  boxText="Select category"
                  optionList={allOptionsData?.[0]}
                  disabled={!newLocName}
                />
              </div>
              <div className="w-[202px]">
                <SingleSelectDropdown
                  selectedValue={state.dept}
                  onSelect={(value?: SelectableDropdownValueType) => {
                    dispatch({
                      type: selCatActionTypeEnum.setDept,
                      payload: value || { label: "", value: "" },
                    });
                  }}
                  label="Department"
                  boxText="Select a department"
                  optionList={deptOptions}
                  disabled={state?.disabled === 2}
                />
              </div>
              <div className="w-[203px]">
                <CheckboxDropdown
                  selectedValues={state.subDepts}
                  onSelect={(value = [] as SelectableDropdownValueType[]) => {
                    dispatch({
                      type: selCatActionTypeEnum.setSubDepts,
                      payload: value,
                    });
                  }}
                  label="Sub-department"
                  boxText="Select a sub-department"
                  optionList={subDeptsOptions}
                  disabled={(state.disabled && state?.disabled >= 1) || false}
                  selectedBoxTextType="number_only"
                />
              </div>
              <div className="h-fit w-[99px]">
                <Button
                  type="secondary"
                  onClick={addToList}
                  disabled={
                    state.disabled ? true : state?.subDepts?.length === 0
                  }
                  leftIcon={<PlusIcon />}
                  small
                  text="Add"
                />
              </div>
            </div>

            {/* categories */}
            {orderList.length > 0 &&
              orderList.map((cat, catId) => (
                <div className="flex w-full mt-[33px] text-sm">
                  <div className="w-[50%] font-semibold text-dark-brown">
                    {cat}
                  </div>
                  <div className="flex flex-col w-full gap-y-3">
                    {showableDeptList[catId] &&
                      Object.keys(showableDeptList[catId]).map(
                        (dept, depId) => (
                          <div
                            className="flex justify-between w-full"
                            key={depId}
                          >
                            <p className="w-[80%] text-dark-brown">{dept}</p>
                            <div className="flex flex-col w-full gap-y-1">
                              {(
                                Object.values(showableDeptList[catId]) as any[]
                              )[depId].map((subDep: string) => (
                                <div className="flex justify-between w-full text-dark-brown">
                                  {subDep}
                                  <button
                                    onClick={() =>
                                      destroy(
                                        `${cat}${joiner}${dept}${joiner}${subDep}`,
                                        cat
                                      )
                                    }
                                    className="text-pot-darkblue hover:text-red-400"
                                  >
                                    <TrashIcon />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              ))}
          </div>

          {/* line */}
          <div className="h-[1px] xl:w-[835px] bg-[#E1E1E1] mt-[33px]"></div>

          {/* button container */}
          <div className="mt-5 flex justify-end gap-[33px]">
            <div className="h-fit w-[135px]">
              <Button
                type="tertiary"
                onClick={() => {
                  setStorageLocationModal(false);
                  setIsEditing(false);
                  dispatch({
                    type: selCatActionTypeEnum.clear, // Added this statement to clear all the selections
                  });
                  dispatch({
                    type: selCatActionTypeEnum.setCat, // Added this statement to clear all the selections
                    payload: { label: "", value: "" },
                  });
                  setEditId("");
                  setEditName("");
                  setLocalEditIndex("");
                  setShowableCatList([]);
                  setOrderList([]);
                  setNewLocName("");
                  setStorageArea({ label: "", value: "" });
                  setStorageLocationModal(false);
                }}
                small
                text="Cancel"
                fullWidth
              />
            </div>
            <div className="h-fit w-[135px]">
              {isEditing ? (
                <Button
                  type="primary"
                  onClick={onEditHandler}
                  small
                  text="Edit"
                  fullWidth
                  disabled={orderList.length < 1}
                />
              ) : (
                <Button
                  type="primary"
                  onClick={onSaveHandler}
                  small
                  text="Save"
                  fullWidth
                  disabled={orderList.length < 1}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default StorageLocationForm;
