import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import Logo from "../../assets/png/homewines_logo.png";
import Typography from "../Typography";
import InputField from "../InputField";
import { ClosedEyesIcon, OpenEyesIcon } from "../../assets/EyesIcons";
import Checkbox from "../Checkbox";
import Button from "../Button";
import { loginAPI } from "../../api/login";
import { toastIt } from "../../utils/common";
import { setToLocalStorage } from "../../utils/localStorage";
import { useRecoilValue } from "recoil";
import { AccountInfoAtom } from "../../atom";
import { toast } from "react-toastify";
import useEnterSubmit from "../../hooks/useEnterSubmit";

const ReAuthenticateModal = ({ handleCancel }: any) => {
  const { email } = useRecoilValue(AccountInfoAtom) as any;
  const [formData, setFormData] = useState<any>();
  const [errors, setErrors] = useState<any>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setValue("email", email);
  }, [email]);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = formData;
    const { data: response } = await loginAPI(payload);
    if (response.success) {
      const currentDateTime = new Date();
      const dateTimeString = currentDateTime.toISOString();
      localStorage.removeItem("token");
      localStorage.removeItem("lastLoggedIn");
      setToLocalStorage("token", response.data.token);
      setToLocalStorage("lastLoggedIn", dateTimeString);
      toast.success("User authenticated successfully!");
      handleCancel();
    } else {
      const errorObj = toastIt(response.error, true);
      setErrors(errorObj);
    }
    setLoading(false);
  };

  const setValue = (key: string, value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const hasFilledAllFields = () => {
    if (formData?.email && formData?.password) return true;
    return false;
  };

  useEnterSubmit(hasFilledAllFields, handleSubmit, [
    formData?.email,
    formData?.password,
  ]);

  return (
    <Modal handleCancel={handleCancel} width="w-[460px]">
      <section className="flex flex-col items-center relative">
        <img
          src={Logo}
          className="w-44 h-fit object-contain absolute -top-10"
          alt="Home Wines Logo"
        />
        <Typography text="Secured Action" className="mt-10" bold xxl />
        <Typography text="Re-authenticate to verify your identity" semibold />
        <div className="flex flex-col gap-4 w-[80%] mt-4">
          <InputField
            label="Email"
            value={formData?.email}
            onChange={(text) => {
              setValue("email", text);
              delete errors?.email;
              setErrors({ ...errors });
            }}
            placeholder="johndoe@example.com"
            hint={errors?.email}
          />
          <InputField
            label="Password"
            inputType={showPassword ? "text" : "password"}
            value={formData?.password}
            onChange={(text) => {
              setValue("password", text);
              delete errors?.password;
              setErrors({ ...errors });
            }}
            placeholder={showPassword ? "password" : "⋅⋅⋅⋅⋅⋅⋅⋅"}
            rightIcon={showPassword ? <ClosedEyesIcon /> : <OpenEyesIcon />}
            rightIconClickHandler={() => setShowPassword((prev) => !prev)}
            hint={errors?.password}
          />
          <div className="w-full flex flex-col lg:flex-row items-center gap-y-2 justify-between text-xs">
            <Checkbox
              small
              checked={rememberMe}
              onClick={() => setRememberMe((prev) => !prev)}
              label="Remember me"
            />
            <a href="#" className="text-pot-darkmaroon underline text-center">
              Forgot your password?
            </a>
          </div>
          <div className="flex justify-end gap-4 mt-10 mb-6">
            <Button loading={loading} onClick={handleSubmit} text="Sign in" />
            <Button
              onClick={handleCancel}
              text="Cancel"
              type="primary_outline"
            />
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ReAuthenticateModal;
