import React from "react";
import { IconProp } from "./ArrowIcons";

const ScanIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      stroke="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1 11.6667H4.2V13.6667H2.1C0.942 13.6667 0 12.62 0 11.3333V9.00001H1.8V11.3333C1.8 11.5173 1.9344 11.6667 2.1 11.6667ZM12.6 11.3333C12.6 11.5173 12.4656 11.6667 12.3 11.6667H10.2V13.6667H12.3C13.458 13.6667 14.4 12.62 14.4 11.3333V9.00001H12.6V11.3333ZM12.3 0.333344H10.2V2.33334H12.3C12.4656 2.33334 12.6 2.48268 12.6 2.66668V5.00001H14.4V2.66668C14.4 1.38001 13.458 0.333344 12.3 0.333344ZM1.8 2.66668C1.8 2.48268 1.9344 2.33334 2.1 2.33334H4.2V0.333344H2.1C0.942 0.333344 0 1.38001 0 2.66668V5.00001H1.8V2.66668ZM3 10.3333H5.4V3.66668H3V10.3333ZM7.8 10.3333H9.6V3.66668H7.8V10.3333ZM7.2 3.66668H6V10.3333H7.2V3.66668ZM11.4 3.66668H10.2V10.3333H11.4V3.66668Z"
        fill={color || "inherit"}
      />
    </svg>
  );
};

export default ScanIcon;
