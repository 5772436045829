import React from "react";
import { IconProp } from "./ArrowIcons";

const UsersIcon = ({ color }: IconProp) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3334 13V11.6667C11.3334 10.9594 11.0524 10.2811 10.5523 9.78105C10.0522 9.28095 9.37393 9 8.66669 9H3.33335C2.62611 9 1.94783 9.28095 1.44774 9.78105C0.947639 10.2811 0.666687 10.9594 0.666687 11.6667V13"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99998 6.33333C7.47274 6.33333 8.66665 5.13943 8.66665 3.66667C8.66665 2.19391 7.47274 1 5.99998 1C4.52722 1 3.33331 2.19391 3.33331 3.66667C3.33331 5.13943 4.52722 6.33333 5.99998 6.33333Z"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3333 13V11.6667C15.3329 11.0758 15.1362 10.5019 14.7742 10.0349C14.4122 9.56791 13.9054 9.23438 13.3333 9.08667"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 1.08667C11.2403 1.23354 11.7487 1.56714 12.1118 2.03488C12.4748 2.50262 12.6719 3.07789 12.6719 3.67C12.6719 4.26212 12.4748 4.83739 12.1118 5.30513C11.7487 5.77287 11.2403 6.10647 10.6667 6.25334"
        stroke={color || "inherit"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsersIcon;
