import { authenticatedAxiosInstance as axios } from "./axios";

const create = (storeId: string, payload: any) =>
  axios.post(`/store/${storeId}/live-inventory`, payload);
const editGet = (storeId: string, sessionId: string) =>
  axios.get(`/store/${storeId}/live-inventory/${sessionId}`);
const editPut = (storeId: string, sessionId: string, payload: any) =>
  axios.put(`/store/${storeId}/live-inventory/${sessionId}`, payload);
const finish = (storeId: string, sessionId: string) =>
  axios.get(`/store/${storeId}/live-inventory/${sessionId}/finish`);
const getCategories = (storeId: string, importId: any) =>
  axios.get(`/store/${storeId}/pos-data/${importId}/cat-dept`);
const list = (
  storeId: string,
  filters: "IN_PROGRESS" | "ADJUSTMENT" | "COMPLETE"
) => axios.get(`/store/${storeId}/live-inventory`, { params: { filters } });
const getImportId = (store_id: string) =>
  axios.post(`/store/${store_id}/pos-data/upload`, {});
const uncountedGet = (storeId: string, sessionId: string, state: any) =>
  axios.get(`/store/${storeId}/live-inventory/${sessionId}/uncounted_state`, {
    params: state,
  });

export const SessionApi = {
  create,
  editGet,
  editPut,
  finish,
  getCategories,
  list,
  getImportId,
  uncountedGet,
};
