import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { InventoryApi } from "../../api/inventory";
import { SelectedSessionAtom } from "../../atom";
import { toastIt } from "../../utils/common";

type ItemFromBarcodetype = {
  barcode: string;
  setBarcode: Dispatch<SetStateAction<string>>;
  openItemSelect: boolean;
  setOpenItemSelect: Dispatch<SetStateAction<boolean>>;
  barcodeBlurred: boolean;
  setBarcodeBlurred: Dispatch<SetStateAction<boolean>>;
  itemsList: any[];
  selectedItem: any;
  setSelectedItem: Dispatch<SetStateAction<any>>;
  setItemsList: Dispatch<SetStateAction<any[]>>;
  isEdit: boolean;
  setErrors: Dispatch<SetStateAction<any>>;
};

const useFetchItemFromBarcode = ({
  isEdit,
  barcode,
  setBarcode,
  openItemSelect,
  setOpenItemSelect,
  barcodeBlurred,
  selectedItem,
  setSelectedItem,
  setBarcodeBlurred,
  itemsList,
  setItemsList,
  setErrors,
}: ItemFromBarcodetype) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const session = useRecoilValue(SelectedSessionAtom) as any;

  const fetch = async () => {
    const params = { barcode }
    const { data: response } = await InventoryApi.findItems(
      session.id,
      { params }
    );
    if(!response.success) {
      const errorObj = toastIt(response.error, true);
      setErrors(errorObj);
      setBarcode("")
      setFetchLoading(false)
      setBarcodeBlurred(false)
      return
    }
    const showableData = response.data;
    console.log(response.data);
    if (showableData.length) {
      if(showableData.length === 1){
        setSelectedItem(showableData[0])
        setFetchLoading(false)
        setBarcodeBlurred(false)
        return;
      }
      setItemsList(showableData);
      setOpenItemSelect(true);
      setBarcodeBlurred(false);
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    console.log("Heree", barcodeBlurred)
    console.log(itemsList)
    if(fetchLoading || itemsList?.length) {
      return
    }
    if (selectedItem){
      // setBarcodeBlurred(false)
       return;
      }
    if (barcodeBlurred && barcode) {
      setFetchLoading(true);
      fetch();
    }
  }, [barcodeBlurred]);

  useEffect(() => {
    if (isEdit) return;
    setSelectedItem(null);
    // if(!barcode || window.innerWidth <= 768) return;
    // if( window.innerWidth > 768 && barcodeBlurred ) return;
    // const id = setTimeout(() => {
    //   setFetchLoading(true);
    //   fetch();
    // }, 800);
    // return () => clearTimeout(id);
  }, [barcode]);

  return { fetchLoading };
};

export default useFetchItemFromBarcode;
