import { Dispatch, SetStateAction } from 'react';
import UsersIcon from '../../assets/UsersIcon';
import SearchIcon from '../../assets/SearchIcon';
import Button from '../Button';
import InputField from '../InputField';
import LinkDiv from '../LinkDiv';
import Typography from '../Typography';

type TableHeaderTwoPropType = {
    search?: string;
    setSearch?: Dispatch<SetStateAction<string>>;
    heading: string;
    buttonTo?: string;
    hideButton?: boolean
    buttonText: string;
    withDebounce?: boolean
  };

const TableHeaderTwo = ({
    search = "",
    setSearch = () => {},
    heading,
    buttonText,
    buttonTo,
    hideButton=false,
    withDebounce = false
  }: TableHeaderTwoPropType) => {

    console.log(search)
    return (
      <section className="text-sm">
        <div className="w-full flex justify-between items-end mb-[2vh]">
        <Typography
          text={heading}
          bold
          xxl
          m={{ t: "mt-[4vh]" }}
        />
        </div>
        <div className="flex justify-between">
          <div className="w-1/3">
            <InputField
              placeholder="Search"
              leftIcon={<SearchIcon color="#4F4F4F" />}
              value={search}
              onChange={(text) => setSearch(text)}
              withDebounce={withDebounce}
            />
          </div>
          {!hideButton && (<LinkDiv to={buttonTo} >
            <Button text={buttonText} leftIcon={<UsersIcon />} />
          </LinkDiv>)}
        </div>
      </section>
    );
  };

export default TableHeaderTwo