import { toast } from "react-toastify";
import { capitalize } from "./helperFunctions";

export const joiner = "$$$$";

export const toastIt = (errors: any, shouldNotShowFieldErrors = false) => {
  const fieldErr = {} as { [char: string]: string };
  const showable = Object.keys(errors.messages).map((each: any) => {
    if (each === "NON_FIELD_ERRORS") {
      return errors.messages[each].join(", ");
    } else {
      if (shouldNotShowFieldErrors) {
        fieldErr[each] = errors.messages[each];
        return;
      }
      return (
        capitalize(each) +
        ": " +
        (typeof errors.messages[each] === "string"
          ? errors.messages[each]
          : errors.messages[each].join(", "))
      );
    }
  });
  showable.forEach((error) => toast.error(error));

  if (shouldNotShowFieldErrors) {
    return fieldErr;
  } else {
    return;
  }
};
